import { keepPreviousData, useQuery } from '@tanstack/react-query'

import UtilsService from 'shared/services/utils.service'

export const useGetLanguages = () => {
	return useQuery({
		queryFn: () => UtilsService.getLanguages(),
		queryKey: ['get languages'],
		select: ({ data }) => data,
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // Данные будут считаться свежими в течение 5 минут
		retry: 5
	})
}

export const useGetCurrencies = (isOptions?: boolean) => {
	return useQuery({
		queryFn: () => UtilsService.getCurrencies(),
		queryKey: ['get currencies'],
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // Данные будут считаться свежими в течение 5 минут
		select: ({ data }) => data,
		retry: 5
	})
}

export const useGetCountries = () => {
	return useQuery({
		queryFn: () => UtilsService.getCountries(),
		queryKey: ['get counties'],
		select: ({ data }) => data,
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // Данные будут считаться свежими в течение 5 минут
		retry: 5
	})
}

export const useGetExchange = () => {
	return useQuery({
		queryFn: () => UtilsService.getCurrenciesExchange(),
		queryKey: ['get exchange'],
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // Данные будут считаться свежими в течение 5 минут
		select: ({ data }) => data,
		retry: 5
	})
}

export const useGetDelivery = () => {
	return useQuery({
		queryFn: () => UtilsService.getDeliveryServices(),
		queryKey: ['get delivery'],
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // Данные будут считаться свежими в течение 5 минут
		select: ({ data }) => data,
		retry: 5
	})
}

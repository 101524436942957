import { toFormData } from 'shared/utils'
import { IDistributorRequest, IFeedbackFilter, IHelp } from './types/help.types'
import { axiosInstance } from 'shared/api'
import { getHelpUrl } from 'shared/api/config'

const HelpService = {
	async addNewDistributer(data: IDistributorRequest) {
		const response = await axiosInstance.post<string>(
			getHelpUrl('/distributor/request/'),
			data
		)

		return response
	},

	async getFqa() {
		const response = await axiosInstance.get<IHelp[]>(getHelpUrl('/faq/'))
		return response
	},

	async sendFeedback(data: IFeedbackFilter) {
		const response = await axiosInstance.post<string>(
			getHelpUrl(`/feedback/`),
			toFormData(data)
		)
		return response
	}
}

export default HelpService

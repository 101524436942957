import { useMutation, useQuery } from '@tanstack/react-query'
import HelpService from 'shared/services/help.service'

import {
	IDistributorRequest,
	IFeedbackFilter
} from 'shared/services/types/help.types'

export const useHelpAction = () => {
	const getFqa = useQuery({
		queryFn: () => HelpService.getFqa(),
		queryKey: ['getFqa'],
		select: ({ data }) => data,
		enabled: false
	})

	const addNewDistributer = useMutation({
		mutationFn: (data: IDistributorRequest) =>
			HelpService.addNewDistributer(data),
		mutationKey: ['addNewDistributer']
	})

	const sendFeedback = useMutation({
		mutationFn: (data: IFeedbackFilter) => HelpService.sendFeedback(data),
		mutationKey: ['addNewDistributer']
	})

	return {
		addNewDistributer,
		sendFeedback,
		getFqa
	}
}

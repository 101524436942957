import React, { useRef, useEffect, useState, FC } from 'react'
import 'app/styles/modal.scss'

interface IProps {
	isOpen?: boolean
	onClose: () => void
}
const FreezeWarning: FC<IProps> = ({ isOpen, onClose }) => {
	return (
		<>
			<div className={`modal modal-warning ${isOpen ? 'show' : 'hide'}`}>
				<div className='modal__window'>
					<div className='modal__header'>
						<h3>Источник www.mysoftpro.ru</h3>
					</div>
					<div className='modal__text'>
						<p>
							Мы заморозили источник на 24 часа, если передумаете, разморозить
							можно в один клик
						</p>
						<button type='button' className='btn' onClick={onClose}>
							Ок
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default FreezeWarning

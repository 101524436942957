import {
	useInfiniteQuery,
	useQuery,
	useQueryClient
} from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import PartnerService from 'shared/services/partner.service'
import { IPartnerFilter } from 'shared/services/types/partner.types'

export const usePartnersList = (data?: IPartnerFilter) => {
	const queryClient = useQueryClient()
	const [page, setPage] = useState(1)
	const [dataList, setDataList] = useState<any | null>(null)

	const requestList = useQuery({
		queryFn: () => PartnerService.getRequestPartners(),
		queryKey: ['request partners list'],
		select: ({ data }) => data,
		retry: 0
	})

	const getPartnersList = useInfiniteQuery({
		queryKey: ['getPartnersList', data],
		initialPageParam: 1,
		queryFn: ({ pageParam = 1 }) =>
			PartnerService.getPartnersList({ ...data!, page: pageParam }),
		getNextPageParam: (lastPage, allPages) => allPages.length + 1,
		retry: 0,
		select: data => {
			const res = data.pages.map(item => ({
				...item.data
			}))

			return { ...data, pages: res }
		}
	})

	useEffect(() => {
		const { data, isLoading } = getPartnersList

		const result = data?.pages.reduce((acc, page) => {
			//@ts-ignore

			return {
				...page,
				//@ts-ignore
				results: [...(acc?.results || []), ...(page?.results || [])]
			}
		}, {} as any)

		if (!isLoading) setDataList(result)
	}, [getPartnersList.data?.pages, getPartnersList.isLoading])

	return {
		requestList,
		getPartnersList: { ...getPartnersList, data: dataList }
	}
}

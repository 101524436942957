import React from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export const NotFoundPreview = () => {
	return (
		<>
			<Helmet>
				<meta name='prerender-status-code' content='404' />
			</Helmet>
			<div className={styles.wrapper}>
				<div className={styles.block}>
					<div className={styles.main}>404</div>
					<div className={styles.second}>Страница не найдена</div>
					<button>
						<Link to={'/'}>Главная</Link>
					</button>
				</div>
			</div>
		</>
	)
}

import { useQuery } from '@tanstack/react-query'
import CompanyService from 'shared/services/company.service'

export const useCompanyTypes = () => {
	const { isPending, isError, isSuccess, data, error } = useQuery({
		queryFn: () => CompanyService.getTypesCompany(),
		queryKey: ['get types company'],
		select: ({ data }) => data
	})

	return { isPending, isError, isSuccess, companyTypesData: data, error }
}

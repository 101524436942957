import React, { FC, PropsWithChildren, useState } from 'react'

interface IProps extends PropsWithChildren {
	title: string
}

export const Accordion: FC<IProps> = ({ children, title }) => {
	const [isOpen, setIsOpen] = useState(false)
	return (
		<div className={`suppliers-filter-item ${isOpen ? 'open' : ''}`}>
			<div
				className='suppliers-filter-item__header'
				onClick={() => setIsOpen(!isOpen)}
			>
				{title}
				<i>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='20'
						height='20'
						viewBox='0 0 20 20'
						fill='none'
					>
						<path
							d='M15.8307 7.5L9.9974 12.5L4.16406 7.5'
							stroke='#838383'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</i>
			</div>
			<div className='suppliers-filter-item__list'>{children}</div>
		</div>
	)
}

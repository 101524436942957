import { useQuery } from '@tanstack/react-query'
import SubscriptionService from 'shared/services/subscription.service'

export const useGetTariffs = () => {
	const { isPending, isError, isSuccess, data, error } = useQuery({
		queryFn: () => SubscriptionService.getSubscriptionTariff(),
		queryKey: ['sub tariff'],
		select: ({ data }) => data
	})

	return { isPending, isError, isSuccess, data }
}

export const FileIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9997 36.6663C29.2044 36.6663 36.6663 29.2044 36.6663 19.9997C36.6663 10.7949 29.2044 3.33301 19.9997 3.33301C10.7949 3.33301 3.33301 10.7949 3.33301 19.9997C3.33301 29.2044 10.7949 36.6663 19.9997 36.6663ZM21.2497 14.9997C21.2497 14.3093 20.69 13.7497 19.9997 13.7497C19.3093 13.7497 18.7497 14.3093 18.7497 14.9997L18.7497 18.7497H14.9997C14.3093 18.7497 13.7497 19.3094 13.7497 19.9997C13.7497 20.6901 14.3093 21.2497 14.9997 21.2497H18.7497V24.9997C18.7497 25.69 19.3093 26.2497 19.9997 26.2497C20.69 26.2497 21.2497 25.69 21.2497 24.9997L21.2497 21.2497H24.9997C25.69 21.2497 26.2497 20.6901 26.2497 19.9997C26.2497 19.3094 25.69 18.7497 24.9997 18.7497H21.2497V14.9997Z"
        fill="#8A7DE7"
      />
    </svg>
  );
};

export const SendIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2946 19.5877L25.4402 13.151C27.3145 7.52802 28.2516 4.71653 26.7676 3.23244C25.2835 1.74836 22.472 2.68552 16.849 4.55984L10.4123 6.7054C5.87404 8.21816 3.6049 8.97455 2.96007 10.0837C2.34664 11.1389 2.34664 12.4421 2.96007 13.4973C3.6049 14.6065 5.87404 15.3629 10.4123 16.8756C10.9748 17.0631 11.6075 16.9292 12.0287 16.512L18.9107 9.69369C19.2979 9.3101 19.9227 9.31301 20.3063 9.70019C20.6899 10.0874 20.687 10.7122 20.2998 11.0958L13.5289 17.804C13.0647 18.2639 12.9177 18.9678 13.1244 19.5877C14.6371 24.1259 15.3935 26.3951 16.5027 27.0399C17.5579 27.6534 18.8611 27.6534 19.9163 27.0399C21.0254 26.3951 21.7818 24.1259 23.2946 19.5877Z"
        fill="#4E41AB"
      />
    </svg>
  );
};

export const ReadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
    >
      <path
        d="M2.7141 10.9019C2.47128 10.6375 2.06009 10.62 1.79569 10.8628C1.53129 11.1057 1.51379 11.5168 1.75661 11.7812L2.7141 10.9019ZM5.1093 14.471L4.63056 14.9107C4.75366 15.0447 4.92731 15.121 5.1093 15.121C5.29129 15.121 5.46495 15.0447 5.58805 14.9107L5.1093 14.471ZM12.7729 7.08712C13.0157 6.82272 12.9982 6.41154 12.7338 6.16872C12.4694 5.9259 12.0582 5.94339 11.8154 6.2078L12.7729 7.08712ZM8.22299 10.1196C7.98017 10.384 7.99767 10.7952 8.26207 11.038C8.52648 11.2808 8.93766 11.2633 9.18048 10.9989L8.22299 10.1196ZM7.38426 12.9548C7.62708 12.6904 7.60959 12.2792 7.34518 12.0364C7.08078 11.7935 6.66959 11.811 6.42677 12.0754L7.38426 12.9548ZM1.75661 11.7812L4.63056 14.9107L5.58805 14.0313L2.7141 10.9019L1.75661 11.7812ZM5.58805 14.9107L7.38426 12.9548L6.42677 12.0754L4.63056 14.0313L5.58805 14.9107ZM9.18048 10.9989L10.9767 9.04301L10.0192 8.16368L8.22299 10.1196L9.18048 10.9989ZM10.9767 9.04301L12.7729 7.08712L11.8154 6.2078L10.0192 8.16368L10.9767 9.04301Z"
        fill="#979797"
      />
      <path
        d="M16.7649 6.64746L12.5071 10.5592M7.82373 13.982L8.24925 14.471L10.3782 12.5151"
        stroke="#979797"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

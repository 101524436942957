import { FC, useEffect, useState } from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'
import { StatisticsBlock } from 'features/statistics/ui/statistic-block'
import { getCorrectDate } from 'shared/utils/getCorrectDate'
import { useGetStatistics } from 'features/statistics/api/useGetStatistics'
import { usePartnersActions } from 'features/partners/api/usePartnersActions'
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts'
import { transformDate } from 'shared/utils/transformDate'

interface IProps {
	sourceList: any[]
}

interface IContact {
	date: any
	pv: any
}

const StatisticsDiagram: FC<IProps> = () => {
	const [filter, setFilter] = useState({ start_date: null, end_date: null })
	const [contactView, setContactView] = useState<IContact[]>([])

	const { getPartnerInfo } = usePartnersActions()
	const [startDate, setStartDate] = useState<Date | null>(null)
	const [endDate, setEndDate] = useState<Date | null>(null)

	const { getViewPartner, getViewProductPartner, getCountProductPartner } =
		//@ts-ignore
		useGetStatistics(getPartnerInfo?.data?.id, filter)

	useEffect(() => {
		setFilter({
			//@ts-ignore
			start_date: getCorrectDate(startDate),
			//@ts-ignore
			end_date: getCorrectDate(endDate, true)
		})
	}, [startDate, endDate])

	useEffect(() => {
		if (getViewPartner.data) {
			const result = getViewPartner.data.map(item => ({
				//@ts-ignore
				date: item.date,
				//@ts-ignore
				pv: item.count
			}))

			setContactView(result)
		}
	}, [getViewPartner.data])

	return (
		<div className='statistics-diagram'>
			<div className='statistics-diagram__title'>
				<h2>Статистика по источникам</h2>
				<div className='inputs-date'>
					<div className='input-date'>
						<DatePicker
							showIcon
							selected={startDate}
							placeholderText='дд.мм.гг'
							onChange={date => setStartDate(date)}
							locale={ru}
							dateFormat='dd.MM.yyyy'
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='20'
									height='20'
									viewBox='0 0 20 20'
									fill='none'
								>
									<path
										d='M11.667 18.3335H8.33366C5.19096 18.3335 3.61961 18.3335 2.6433 17.3572C1.66699 16.3809 1.66699 14.8095 1.66699 11.6668V10.0002C1.66699 6.85747 1.66699 5.28612 2.6433 4.30981C3.61961 3.3335 5.19096 3.3335 8.33366 3.3335H11.667C14.8097 3.3335 16.381 3.3335 17.3573 4.30981C18.3337 5.28612 18.3337 6.85747 18.3337 10.0002V11.6668C18.3337 14.8095 18.3337 16.3809 17.3573 17.3572C16.813 17.9015 16.0838 18.1424 15.0003 18.2489'
										stroke='#333333'
										strokeWidth='1.2'
										strokeLinecap='round'
									/>
									<path
										d='M5.83301 3.3335V2.0835'
										stroke='#333333'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
									<path
										d='M14.167 3.3335V2.0835'
										stroke='#333333'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
									<path
										d='M17.917 7.5H13.8545H8.95866M1.66699 7.5H4.89616'
										stroke='#333333'
										strokeWidth='1.2'
										strokeLinecap='round'
									/>
									<path
										d='M14.9997 14.1667C14.9997 14.6269 14.6266 15 14.1663 15C13.7061 15 13.333 14.6269 13.333 14.1667C13.333 13.7064 13.7061 13.3333 14.1663 13.3333C14.6266 13.3333 14.9997 13.7064 14.9997 14.1667Z'
										fill='#333333'
									/>
									<path
										d='M14.9997 10.8333C14.9997 11.2936 14.6266 11.6667 14.1663 11.6667C13.7061 11.6667 13.333 11.2936 13.333 10.8333C13.333 10.3731 13.7061 10 14.1663 10C14.6266 10 14.9997 10.3731 14.9997 10.8333Z'
										fill='#333333'
									/>
									<path
										d='M10.8337 14.1667C10.8337 14.6269 10.4606 15 10.0003 15C9.54009 15 9.16699 14.6269 9.16699 14.1667C9.16699 13.7064 9.54009 13.3333 10.0003 13.3333C10.4606 13.3333 10.8337 13.7064 10.8337 14.1667Z'
										fill='#333333'
									/>
									<path
										d='M10.8337 10.8333C10.8337 11.2936 10.4606 11.6667 10.0003 11.6667C9.54009 11.6667 9.16699 11.2936 9.16699 10.8333C9.16699 10.3731 9.54009 10 10.0003 10C10.4606 10 10.8337 10.3731 10.8337 10.8333Z'
										fill='#333333'
									/>
									<path
										d='M6.66667 14.1667C6.66667 14.6269 6.29357 15 5.83333 15C5.3731 15 5 14.6269 5 14.1667C5 13.7064 5.3731 13.3333 5.83333 13.3333C6.29357 13.3333 6.66667 13.7064 6.66667 14.1667Z'
										fill='#333333'
									/>
									<path
										d='M6.66667 10.8333C6.66667 11.2936 6.29357 11.6667 5.83333 11.6667C5.3731 11.6667 5 11.2936 5 10.8333C5 10.3731 5.3731 10 5.83333 10C6.29357 10 6.66667 10.3731 6.66667 10.8333Z'
										fill='#333333'
									/>
								</svg>
							}
						/>
					</div>
					<div className='input-date'>
						<DatePicker
							showIcon
							selected={endDate}
							onChange={date => setEndDate(date)}
							locale={ru}
							placeholderText='дд.мм.гг'
							dateFormat='dd.MM.yyyy'
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='20'
									height='20'
									viewBox='0 0 20 20'
									fill='none'
								>
									<path
										d='M11.667 18.3335H8.33366C5.19096 18.3335 3.61961 18.3335 2.6433 17.3572C1.66699 16.3809 1.66699 14.8095 1.66699 11.6668V10.0002C1.66699 6.85747 1.66699 5.28612 2.6433 4.30981C3.61961 3.3335 5.19096 3.3335 8.33366 3.3335H11.667C14.8097 3.3335 16.381 3.3335 17.3573 4.30981C18.3337 5.28612 18.3337 6.85747 18.3337 10.0002V11.6668C18.3337 14.8095 18.3337 16.3809 17.3573 17.3572C16.813 17.9015 16.0838 18.1424 15.0003 18.2489'
										stroke='#333333'
										strokeWidth='1.2'
										strokeLinecap='round'
									/>
									<path
										d='M5.83301 3.3335V2.0835'
										stroke='#333333'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
									<path
										d='M14.167 3.3335V2.0835'
										stroke='#333333'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
									<path
										d='M17.917 7.5H13.8545H8.95866M1.66699 7.5H4.89616'
										stroke='#333333'
										strokeWidth='1.2'
										strokeLinecap='round'
									/>
									<path
										d='M14.9997 14.1667C14.9997 14.6269 14.6266 15 14.1663 15C13.7061 15 13.333 14.6269 13.333 14.1667C13.333 13.7064 13.7061 13.3333 14.1663 13.3333C14.6266 13.3333 14.9997 13.7064 14.9997 14.1667Z'
										fill='#333333'
									/>
									<path
										d='M14.9997 10.8333C14.9997 11.2936 14.6266 11.6667 14.1663 11.6667C13.7061 11.6667 13.333 11.2936 13.333 10.8333C13.333 10.3731 13.7061 10 14.1663 10C14.6266 10 14.9997 10.3731 14.9997 10.8333Z'
										fill='#333333'
									/>
									<path
										d='M10.8337 14.1667C10.8337 14.6269 10.4606 15 10.0003 15C9.54009 15 9.16699 14.6269 9.16699 14.1667C9.16699 13.7064 9.54009 13.3333 10.0003 13.3333C10.4606 13.3333 10.8337 13.7064 10.8337 14.1667Z'
										fill='#333333'
									/>
									<path
										d='M10.8337 10.8333C10.8337 11.2936 10.4606 11.6667 10.0003 11.6667C9.54009 11.6667 9.16699 11.2936 9.16699 10.8333C9.16699 10.3731 9.54009 10 10.0003 10C10.4606 10 10.8337 10.3731 10.8337 10.8333Z'
										fill='#333333'
									/>
									<path
										d='M6.66667 14.1667C6.66667 14.6269 6.29357 15 5.83333 15C5.3731 15 5 14.6269 5 14.1667C5 13.7064 5.3731 13.3333 5.83333 13.3333C6.29357 13.3333 6.66667 13.7064 6.66667 14.1667Z'
										fill='#333333'
									/>
									<path
										d='M6.66667 10.8333C6.66667 11.2936 6.29357 11.6667 5.83333 11.6667C5.3731 11.6667 5 11.2936 5 10.8333C5 10.3731 5.3731 10 5.83333 10C6.29357 10 6.66667 10.3731 6.66667 10.8333Z'
										fill='#333333'
									/>
								</svg>
							}
						/>
					</div>
				</div>
			</div>
			{getViewProductPartner.data?.map((item, index) => (
				<StatisticsBlock
					key={item.source.id}
					data={item}
					//@ts-ignore
					counts={getCountProductPartner.data?.[index]}
				/>
			))}

			<div className='statistics-diagram-block contact-views-diagram'>
				<p className='statistics-diagram-block__source'>Просмотры контактов:</p>

				<ResponsiveContainer width='100%' height={418}>
					<LineChart
						width={500}
						height={418}
						data={contactView}
						margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
					>
						<Tooltip
							labelFormatter={(value, payload) => {
								return transformDate(payload?.[0]?.payload?.date)
							}}
						/>

						<Line
							type='monotone'
							name='Просмотры'
							dataKey='pv'
							strokeWidth='3px'
							stroke='#FAA916'
							activeDot={{ r: 8 }}
						/>
					</LineChart>
				</ResponsiveContainer>

				<div className='statistics-diagram-block__date'>
					{!!contactView.length && (
						<>
							<p>{transformDate(contactView[0].date)}</p>
							<p>{transformDate(contactView.at(-1)?.date)}</p>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default StatisticsDiagram

import { useGetCountries, useGetLanguages } from 'features/utils/api'
import { FC } from 'react'
import { IPartnerResponse } from 'shared/services/types/partner.types'

interface IProps {
	data?: IPartnerResponse
}

const options = [
	{ value: 'yes', label: 'С таможней' },
	{ value: 'no', label: 'Без таможни' },
	{ value: 'all', label: 'С таможней / Без таможни' }
]

export const PartnerInfo: FC<IProps> = ({ data }) => {
	const { data: languages } = useGetLanguages()

	const status = {
		SUCCESS: 'Действующий',
		FAILED: 'Отклоненный',
		PROGRESSING: 'Ожидает'
	}

	const spoken_language = languages?.filter(item =>
		data?.company.correspond_languages.includes(item.id)
	)
	const speak_language = languages?.filter(item =>
		data?.company.speak_languages.includes(item.id)
	)

	if (!data) return null
	return (
		<div className='partner-questionnaire'>
			<div className='container'>
				<div className='partner-questionnaire__row'>
					<div className='partner-questionnaire__list'>
						<div className='partner-questionnaire-item'>
							<p>
								{/* @ts-ignore */}
								<strong>Статус юр. лица:</strong> {status[data.status]}
							</p>
						</div>
						<div className='partner-questionnaire-item'>
							<p>
								<strong>Максимальная наценка:</strong> {data.surcharge}%
							</p>
							<div className='partner-questionnaire-item__sup'>
								<p>
									Это комиссия партнера за выкуп, в нее не входит оплата
									доставки
								</p>
							</div>
						</div>
						<div className='partner-questionnaire-item'>
							<p>
								<strong>Общение</strong>
							</p>
							<div className='partner-questionnaire-item__sup'>
								<ul>
									<li>
										<strong>Переписка:</strong>
										{spoken_language?.map(item => item.name).join(' / ')}
									</li>
									<li>
										<strong>Разговор:</strong>
										{speak_language?.map(item => item.name).join(' / ')}
									</li>
								</ul>
							</div>
						</div>
						<div className='partner-questionnaire-item'>
							<p>
								<strong>Доставляет товары:</strong>
								{
									options.find(item => item.value === data.product_documents)
										?.label
								}
							</p>
						</div>
					</div>

					{/* <div className='partner-questionnaire__list'>
						<div className='partner-questionnaire-item documentation'>
							<p>
								<strong>Документы</strong>
							</p>
							<div className='partner-questionnaire-item__sup'>
								<ul>
									<li>
										<strong>Образец договора на поставку (Местный):</strong>{' '}
										Есть
									</li>
									<li>
										<strong>Образец договора на поставку (В "страну"):</strong>{' '}
										Есть
									</li>
									<li>
										<strong>Валютный контракт, Образец:</strong> Есть
									</li>
									<li>
										<strong>Образец валютного счета:</strong> Есть
									</li>
									<li>
										<strong>Образцы документов для таможни:</strong>
										{data.documents_info ? 'Есть' : 'Нет'}
									</li>
								</ul>
							</div>
						</div>
						<div className='partner-questionnaire-item'>
							<p>
								<strong>Сервисный центр:</strong>{' '}
								{data.service_centers.join(', ')}
							</p>
						</div>
					</div> */}

					<div className='partner-questionnaire-item competencies'>
						<p>
							<strong>Компетенции</strong>
						</p>

						<div className='partner-questionnaire-item__sup-row'>
							<div className='partner-questionnaire-item__sup'>
								<p>
									<strong>Бренды:</strong>
								</p>
								<table>
									{data.vendors.map(item => (
										<tr key={item.vendor}>
											<td>
												<strong>{item.vendor}</strong>
											</td>
											<td>{item.status}</td>
										</tr>
									))}
								</table>
							</div>

							{/* <div className='partner-questionnaire-item__sup'>
								<p>
									<strong>Служба доставки:</strong>
								</p>
								<table>
									{data.delivery_services.map(item => (
										<tr key={item.delivery_service}>
											<td>
												<strong>{item.delivery_service}</strong>
											</td>
											<td>{item.status}</td>
										</tr>
									))}
								</table>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

import { Layout } from 'shared/ui/layout'
import ProfileNav from 'widgets/user-profile/navigation'

const AccountPage = () => {
	return (
		<Layout>
			<ProfileNav />
		</Layout>
	)
}

export default AccountPage

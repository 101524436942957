import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect } from 'react'
import CommonContext from 'services/AuthContext'
import UserService from 'shared/services/user.service'
import { tokenParse } from 'shared/utils'

export const useGetUser = (id?: number) => {
	const userId = tokenParse().user_id || id

	const { isPending, isError, isSuccess, data } = useQuery({
		queryFn: () => UserService.getUser(userId),
		queryKey: ['get user', userId],
		enabled: !!userId,
		select: ({ data }) => data
	})

	return { isPending, isError, isSuccess, userData: data }
}

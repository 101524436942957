import { Layout } from 'shared/ui/layout'
import { Outlet } from 'react-router'
import { NotFoundPreview } from 'widgets/not-found'

const NotFound = () => {
	return (
		<Layout>
			<Outlet />
			<NotFoundPreview />
		</Layout>
	)
}

export default NotFound

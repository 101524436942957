import React, { useRef, useEffect, useState, FC } from 'react'
import 'app/styles/modal.scss'
import { useAlertMessage, useOutsideClick } from 'shared/hooks'
import { FormWrapper } from 'shared/ui/form/form-wrapper'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { Input } from 'shared/ui/form/input'
import { useHelpAction } from 'features/help/api/useHelp'
import { THelper } from 'shared/services/types/help.types'
import { useGetUser } from 'features/profile/api/useGetUser'
import { getMaskForPhone } from 'features/profile/ui/constants'
import { EMAIL_REGEX, PHONE_REGEX } from 'shared/regex'
import { addHttpsUrl } from 'shared/utils'

interface IProps {
	isOpen?: boolean
	onClose?: () => void
}

const LandingPrice: FC<IProps> = ({ isOpen, onClose }) => {
	const methods = useForm()

	const { sendFeedback } = useHelpAction()
	const { addAlertMessage } = useAlertMessage()

	const { ref } = useOutsideClick(onClose)

	const onSubmit: SubmitHandler<THelper.FeedbackRequest> = async data => {
		try {
			const { data: response } = await sendFeedback.mutateAsync({
				...data,
				...(data.site_url ? { site_url: addHttpsUrl(data.site_url) } : {}),
				theme: 'Помощь в регистрации'
			})

			onClose?.()
			addAlertMessage(response, 'success')
		} catch (error: any) {
			addAlertMessage(error, 'error')
		}
	}

	useEffect(() => {
		if (!isOpen) {
			methods.reset({})
		}
	}, [isOpen])

	return (
		<>
			<div className={`modal ${isOpen ? 'show' : 'hide'}`}>
				<div className='modal__window' ref={ref}>
					<div className='modal__header'>
						<p>
							Оставьте нам свои контакты <br />и мы обязательно свяжемся с вами!
						</p>
						<button className='modal__close' onClick={onClose}>
							<svg
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</button>
					</div>
					<div className='modal__form'>
						<FormWrapper methods={methods} onSubmit={onSubmit}>
							<Input
								control={methods.control}
								name='fio'
								label='ФИО:'
								required
								error={methods.formState.errors}
								rules={{ required: 'Обязательное поле для заполнения' }}
							/>
							<Input
								control={methods.control}
								name='phone_number'
								label='Телефон:'
								type='tel'
								error={methods.formState.errors}
								rules={{
									pattern: {
										message: 'Введите корректный номер телефона',
										value: PHONE_REGEX
									}
								}}
							/>
							<Input
								control={methods.control}
								name='email'
								label='Электронная почта'
								required
								error={methods.formState.errors}
								rules={{
									required: 'Обязательное поле для заполнения',
									pattern: {
										value: EMAIL_REGEX,
										message: 'Укажите валидный email'
									}
								}}
							/>
							<Input
								control={methods.control}
								name='site_url'
								label='Адрес сайта'
								error={methods.formState.errors}
							/>
							<Input
								control={methods.control}
								name='message'
								required
								label='Сообщение'
								textArea
								error={methods.formState.errors}
								rules={{ required: 'Обязательное поле для заполнения' }}
							/>

							<button type='submit' className='btn'>
								Отправить
							</button>
						</FormWrapper>
					</div>
				</div>
			</div>
		</>
	)
}

export default LandingPrice

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import PartnerService from 'shared/services/partner.service'
import {
	IPartnerData,
	IPartnerFile,
	IRequestToPartnerComment,
	IRequestToPartnerParams,
	IRequestToPartnerUpdate,
	TPartnerUpdate
} from 'shared/services/types/partner.types'

export const usePartnersActions = (id?: number, enabled?: boolean) => {
	const queryClient = useQueryClient()

	const getPartnerInfo = useQuery({
		queryFn: () => PartnerService.getInfo(id),
		queryKey: ['partner info', id],
		retry: 0,
		enabled: !enabled,
		select: ({ data }) => data
	})

	const becomePartner = useMutation({
		mutationFn: (data: IPartnerData) => PartnerService.becomePartner(data),
		mutationKey: ['become partner'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`partner info`] })
		}
	})

	const updatePartner = useMutation({
		mutationFn: (data: TPartnerUpdate) => PartnerService.updatePartPartner(data),
		mutationKey: ['update partner'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`partner info`] })
		}
	})

	const addFilePartner = useMutation({
		mutationFn: (data: IPartnerFile) => PartnerService.addFile(data),
		mutationKey: ['add file partner']
	})

	const addLogo = useMutation({
		mutationFn: (data: File) => PartnerService.addLogo(data),
		mutationKey: ['add logo']
	})

	const sendRequestPartner = useMutation({
		mutationFn: (data: IRequestToPartnerParams) =>
			PartnerService.sendRequestPartners(data),
		mutationKey: ['add request partner'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`partner info`] })
		}
	})

	const addCommentRequest = useMutation({
		mutationFn: (data: IRequestToPartnerComment) =>
			PartnerService.addCommentRequest(data),
		mutationKey: ['add comment partner'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`request partners list`] })
		}
	})

	const updateInfoRequest = useMutation({
		mutationFn: (data: IRequestToPartnerUpdate) =>
			PartnerService.updateInfoRequest(id!, data),
		mutationKey: ['updateInfoRequest'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`request partners list`] })
		}
	})

	const deleteInfoRequest = useMutation({
		mutationFn: (id: number) => PartnerService.deleteInfoRequest(id),
		mutationKey: ['deleteInfoRequest'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`request partners list`] })
		}
	})

	return {
		getPartnerInfo,
		becomePartner,
		addFilePartner,
		updatePartner,
		addLogo,
		sendRequestPartner,
		addCommentRequest,
		updateInfoRequest,
		deleteInfoRequest
	}
}

import { useState, useEffect, FC } from 'react'
import './ProfilePartnersTerms.scss'
import 'assets/input.scss'

import moment from 'moment-timezone'
import { usePartnersActions } from 'features/partners/api/usePartnersActions'
import { useAlertMessage } from 'shared/hooks'
import {
	useGetCountries,
	useGetCurrencies,
	useGetLanguages
} from 'features/utils/api'
import { ReactSelect } from 'shared/ui/form/react-select'
import { Input } from 'shared/ui/form/input'
import { UseFormReturn } from 'react-hook-form'
import { MyOptionType } from 'shared/ui/form/types'

export const optionsNdsSelect = [
	{ value: 'yes', label: 'Работаем с НДС' },
	{ value: 'no', label: 'Не работаем с НДС' }
]

const options = [
	{ value: 'yes', label: 'С таможней' },
	{ value: 'no', label: 'Без таможни' },
	{ value: 'all', label: 'С таможней / Без таможни' }
]

interface IProps {
	defaultData: any
	methods: UseFormReturn<any, any, undefined>
}

const ProfilePartnersTerms: FC<IProps> = ({ defaultData, methods }) => {
	const {
		watch,
		control,
		formState: { errors }
	} = methods

	const { addAlertMessage } = useAlertMessage()
	const { updatePartner, getPartnerInfo } = usePartnersActions()
	const { data: countries } = useGetCountries()
	const { data: currencies } = useGetCurrencies()
	const { data: languages } = useGetLanguages()

	const [currenciesData, setCurrenciesData] = useState<MyOptionType[]>([])
	const [languagesData, setLanguagesData] = useState<MyOptionType[]>([])

	useEffect(() => {
		const { data } = getPartnerInfo
		if (data) {
			const workdays = data?.workdays.reduce((acc, item, index) => {
				return `${acc}${item.day}/${item.time} ${
					index === data.workdays.length - 1 ? '' : ','
				}`
			}, '')?.replace(' ','')

			const nds = optionsNdsSelect.find(item => item.value === data?.nds)
			const product_documents = options.find(
				item => item.value === data?.product_documents
			)
			const spoken_language = languagesData?.find(
				item => item.value === data?.spoken_language
			)

			const currency = currenciesData?.find(
				item => item.label === data?.currency
			)

			methods.reset({
				...methods.getValues(),
				product_delivery: data.product_delivery,
				surcharge: data.surcharge,
				email: data.email,
				phone_number: data.phone_number,
				answer_time: data.answer_time,
				site_url: data.site_url,
				description: data.description,
				workdays: workdays,
				nds: nds,
				product_documents: product_documents
				// spoken_language: spoken_language,
				// currency: currency
			})
		}
	}, [getPartnerInfo.data, languagesData])

	useEffect(() => {
		if (countries && currencies && languages) {
			const currenciesResponseData = currencies?.map(i => {
				const foundFlag = countries.filter(k => k.id === i.country_id)
				return {
					value: i.country_id,
					label: i.code,
					flag: foundFlag[0].flag
				}
			})
			setCurrenciesData(currenciesResponseData)

			const languagesResponseData = languages?.map(i => {
				const foundFlag = countries.filter(k => k.id === Number(i.country_id))
				return {
					value: i.id,
					label: i.name,
					flag: foundFlag[0].flag
				}
			})
			setLanguagesData(languagesResponseData)
		}
	}, [countries, currencies, languages])

	const infoSubmit = async () => {
		const { data } = getPartnerInfo

		methods.trigger()
		const companyData = data?.company || {}

		setTimeout(() => {
			const value = methods.getValues()
			const error = methods.formState.errors

			const workdays = value?.workdays
				.split(',')
				.reduce((acc: any[], item: string) => {
					const [day, time] = item.split('/')
					return [...acc, { day, time }]
				}, [])

			const dataObject = {
				surcharge: value?.surcharge,
				email: value?.email,
				nds: value?.nds?.value,
				phone_number: value?.phone_number,
				time_zone: moment.tz.guess(),
				site_url: value?.site_url,
				answer_time: value?.answer_time,
				product_delivery: value?.product_delivery,
				product_documents: value?.product_documents?.value,
				workdays,

				description: value?.description
				// spoken_language: value?.spoken_language?.value,
				// currency: value?.currency?.label
			}

			const isError = Object.keys(dataObject).find(item => {
				return error[item]
			})

			if (isError) return null

			updatePartner.mutateAsync(dataObject).catch(err => {
				addAlertMessage(err, 'error')
				console.log(error)
			})
		}, 100)
	}

	useEffect(() => {
		const { isPending, isSuccess } = updatePartner
		if (!isPending && isSuccess) {
			addAlertMessage('Сохранено', 'success')
		}
	}, [updatePartner.isPending, updatePartner.isSuccess])

	return (
		<>
			<ReactSelect
				control={control}
				name='nds'
				label='НДС'
				placeholder=''
				error={errors}
				rules={{ required: 'Обязательное поле для заполнения' }}
				required
				options={optionsNdsSelect}
			/>
			<div className='inputs-row'>
				<Input
					control={control}
					name='surcharge'
					label='Наценка до'
					placeholder='Наценка до'
					info='Партнерский статус предпологает возможность выкупа через партнера товара у определенного дистрибьютора.                                                                                                                                     Укажите максимальную наценку на товар, которую вы хотели бы получить с клиента, но помните клиент может с Вами поторговаться, не отказывайте ему в этом удовольствии.                           Сильно занизив % вы привлечете больше клиентов, но рискуете прибылью. Если сильно завысить % клиент может не обратится к Вам. Рекомендация KIRA - не ставить ниже 3% и не больше 10%.'
					required
					error={errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
				/>

				<ReactSelect
					control={control}
					name='product_documents'
					label='Документы на товар'
					placeholder=''
					error={errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
					required
					options={options}
					info='Некоторые пользователи могут находится за границей и им потрубется ряд документов для прохождения таможни, в том числе такие данные как ГТД. Если вы сможете предоставить все нужные данные и подготовить документы укажите "С таможней"'
				/>
			</div>
			{/* <div className='inputs-row'>
				<ReactSelect
					control={control}
					name='spoken_language'
					label='Разговорный язык'
					placeholder=''
					error={errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
					required
					options={languagesData}
				/>
				<ReactSelect
					control={control}
					name='currency'
					label='Ваша валюта'
					placeholder=''
					error={errors}
					// rules={{ required: 'Обязательное поле для заполнения' }}
					// required
					options={currenciesData}
				/>
			</div> */}
			<Input
				control={control}
				name='product_delivery'
				label='Доставка товара:'
				placeholder='Доставка товара:'
				required
				error={errors}
				info='Укажите регион доставки. К примеру если вы можете доставить товар только по г. Москве, так и укажите. Если вы можете отправлять товар по всей России или за рубеж, напишите об этом.'
				rules={{ required: 'Обязательное поле для заполнения' }}
			/>

			<Input
				control={control}
				name='email'
				label='Email:'
				placeholder='Email:'
				required
				info='Укажите электронную почту'
				error={errors}
				rules={{ required: 'Обязательное поле для заполнения' }}
			/>

			<div className='inputs-row'>
				<Input
					control={control}
					name='phone_number'
					label='Номер телефона'
					required
					placeholder='Номер телефона'
					error={errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
				/>

				<Input
					control={control}
					name='site_url'
					label='Адрес сайта:'
					required
					placeholder='Адрес сайта:'
					error={errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
				/>
			</div>

			<div className='inputs-row'>
				<Input
					control={control}
					name='workdays'
					label='Рабочие дни и часы'
					placeholder='Рабочие дни и часы'
					info='В формате: Пн. - Пт./10.00 - 18.00 (Используйте "/" в качестве разделителя)'
					error={errors}
					required
					rules={{ required: 'Обязательное поле для заполнения' }}
				/>

				<Input
					control={control}
					name='answer_time'
					label='Время ответа на запрос:'
					required
					placeholder='Время ответа на запрос:'
					info='Как быстро вы отвечаете на запросы, укажите время в часах или минутах. К примеру: 1 час или 30 мин.'
					error={errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
				/>
			</div>

			<Input
				control={control}
				className='textarea-block'
				textArea
				name='description'
				label='Текстовое поле для инструкции'
				placeholder='Текстовое поле для инструкции'
				info='Расскажите немного о своей компании, как пользователю оформить заказ в максимально короткие сроки, какие документы подготовить для сделки. Так же вы можете указать дополнительную информацию которая поможет пользователю принять решение о сотрудничестве
				'
				error={errors}
			/>
			{defaultData && (
				<div className='block-save'>
					<button className='btn' type='button' onClick={infoSubmit}>
						Сохранить
					</button>
					{/* {!updatePartner.isPending && updatePartner.isSuccess && (
					<div className='date-save'>Сохранено:{currentDate()}</div>
				)} */}
				</div>
			)}
		</>
	)
}

export default ProfilePartnersTerms

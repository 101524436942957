export const FirstNumber = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M12.5413 5.98024C15.1766 4.4558 18.2363 3.58331 21.4997 3.58331C31.3948 3.58331 39.4163 11.6049 39.4163 21.5C39.4163 31.3951 31.3948 39.4166 21.4997 39.4166C11.6046 39.4166 3.58301 31.3951 3.58301 21.5C3.58301 18.2366 4.4555 15.1769 5.97994 12.5416"
        stroke="#4E41AB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M20.8267 31V13.58L21.6587 14.464H16.5367V12.8H22.6987V31H20.8267Z"
        fill="#4E41AB"
      />
    </svg>
  );
};

export const SecondNumber = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M12.5413 5.98026C15.1766 4.45582 18.2363 3.58333 21.4997 3.58333C31.3948 3.58333 39.4163 11.6049 39.4163 21.5C39.4163 31.3951 31.3948 39.4167 21.4997 39.4167C11.6046 39.4167 3.58301 31.3951 3.58301 21.5C3.58301 18.2366 4.4555 15.177 5.97994 12.5417"
        stroke="#4E41AB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M15.1253 31V29.674L22.6913 22.264C23.3847 21.588 23.9047 20.9987 24.2513 20.496C24.598 19.976 24.832 19.4907 24.9533 19.04C25.0747 18.5893 25.1353 18.1647 25.1353 17.766C25.1353 16.7087 24.7713 15.8767 24.0433 15.27C23.3327 14.6633 22.2753 14.36 20.8713 14.36C19.7967 14.36 18.8433 14.5247 18.0113 14.854C17.1967 15.1833 16.4947 15.6947 15.9053 16.388L14.5793 15.244C15.29 14.412 16.2 13.7707 17.3093 13.32C18.4187 12.8693 19.658 12.644 21.0273 12.644C22.258 12.644 23.324 12.8433 24.2253 13.242C25.1267 13.6233 25.82 14.1867 26.3053 14.932C26.808 15.6773 27.0593 16.5527 27.0593 17.558C27.0593 18.1473 26.9727 18.728 26.7993 19.3C26.6433 19.872 26.3487 20.4787 25.9153 21.12C25.4993 21.744 24.884 22.4547 24.0693 23.252L17.1273 30.064L16.6073 29.336H27.8913V31H15.1253Z"
        fill="#4E41AB"
      />
    </svg>
  );
};

export const ThirdNumber = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M12.5413 5.9803C15.1766 4.45586 18.2363 3.58337 21.4997 3.58337C31.3948 3.58337 39.4163 11.6049 39.4163 21.5C39.4163 31.3951 31.3948 39.4167 21.4997 39.4167C11.6046 39.4167 3.58301 31.3951 3.58301 21.5C3.58301 18.2366 4.4555 15.177 5.97994 12.5417"
        stroke="#4E41AB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M20.9481 31.156C19.6308 31.156 18.3828 30.948 17.2041 30.532C16.0428 30.0987 15.1068 29.5267 14.3961 28.816L15.3061 27.334C15.8954 27.9407 16.6841 28.4433 17.6721 28.842C18.6774 29.2407 19.7694 29.44 20.9481 29.44C22.4561 29.44 23.6088 29.1107 24.4061 28.452C25.2208 27.7933 25.6281 26.9093 25.6281 25.8C25.6281 24.6907 25.2294 23.8067 24.4321 23.148C23.6521 22.4893 22.4128 22.16 20.7141 22.16H19.4141V20.782L25.0301 13.736L25.2901 14.464H15.1501V12.8H26.8501V14.126L21.2341 21.172L20.3241 20.574H21.0261C23.2101 20.574 24.8394 21.0593 25.9141 22.03C27.0061 23.0007 27.5521 24.2487 27.5521 25.774C27.5521 26.7967 27.3094 27.7153 26.8241 28.53C26.3388 29.3447 25.6021 29.986 24.6141 30.454C23.6434 30.922 22.4214 31.156 20.9481 31.156Z"
        fill="#4E41AB"
      />
    </svg>
  );
};

export const FourNumber = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <path d="M11.6654 5.5632C14.1168 4.14512 16.963 3.3335 19.9987 3.3335C29.2034 3.3335 36.6654 10.7954 36.6654 20.0002C36.6654 29.2049 29.2034 36.6668 19.9987 36.6668C10.794 36.6668 3.33203 29.2049 3.33203 20.0002C3.33203 16.9644 4.14365 14.1183 5.56173 11.6668" stroke="#4E41AB" strokeWidth="2" strokeLinecap="round"/>
      <path d="M13.317 23.768V22.572L21.85 11.9H23.69L15.226 22.572L14.329 22.296H27.278V23.768H13.317ZM22.402 28V23.768L22.448 22.296V18.57H24.058V28H22.402Z" fill="#4E41AB"/>
    </svg>
  );
};
export const UserIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14.5"
        cy="10.875"
        r="3.625"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M21.7126 24.1667C21.5203 20.6728 20.4506 18.125 14.4999 18.125C8.54915 18.125 7.47941 20.6728 7.28711 24.1667"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.45866 4.03304C10.236 3.00493 12.2994 2.4165 14.5003 2.4165C21.1738 2.4165 26.5837 7.8264 26.5837 14.4998C26.5837 21.1733 21.1738 26.5832 14.5003 26.5832C7.82688 26.5832 2.41699 21.1733 2.41699 14.4998C2.41699 12.2989 3.00542 10.2355 4.03353 8.45817"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const TariffsIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 12.0835H12.0833"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5805 15.104C26.5805 15.0105 26.5833 14.4603 26.5805 14.4208C26.5378 13.8157 26.0188 13.3338 25.3672 13.2941C25.3247 13.2915 25.2743 13.2915 25.1736 13.2915H22.0288C19.8728 13.2915 18.125 14.9145 18.125 16.9165C18.125 18.9185 19.8728 20.5415 22.0288 20.5415H25.1736C25.2743 20.5415 25.3247 20.5415 25.3672 20.5389C26.0188 20.4993 26.5378 20.0173 26.5805 19.4122C26.5833 19.3727 26.5833 18.8225 26.5833 18.729"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <ellipse
        cx="21.7503"
        cy="16.9168"
        rx="1.20833"
        ry="1.20833"
        fill="#333333"
      />
      <path
        d="M12.0837 26.5833H15.7087C20.2656 26.5833 22.544 26.5833 23.9597 25.1677C24.9362 24.1911 25.2391 22.804 25.3331 20.5417M25.3331 13.2917C25.2391 11.0293 24.9362 9.64219 23.9597 8.66565C22.544 7.25 20.2656 7.25 15.7087 7.25H12.0837C7.52675 7.25 5.24829 7.25 3.83264 8.66565C2.41699 10.0813 2.41699 12.3598 2.41699 16.9167C2.41699 21.4736 2.41699 23.752 3.83264 25.1677C4.6219 25.9569 5.67936 26.3062 7.25033 26.4607"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.25 7.25L11.7637 4.25712C13.0348 3.41429 14.7568 3.41429 16.028 4.25712L20.5417 7.25"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const EmployeesIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.8753"
        cy="7.25002"
        r="4.83333"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M18.125 10.875C20.127 10.875 21.75 9.25203 21.75 7.25C21.75 5.24797 20.127 3.625 18.125 3.625"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.11638 24.8727C8.24874 25.1942 9.52508 25.375 10.8753 25.375C15.5467 25.375 19.3337 23.211 19.3337 20.5416C19.3337 17.8723 15.5467 15.7083 10.8753 15.7083C6.20392 15.7083 2.41699 17.8723 2.41699 20.5416C2.41699 20.9589 2.50951 21.3638 2.68347 21.75"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21.75 16.9167C23.8697 17.3815 25.375 18.5587 25.375 19.9375C25.375 21.1813 24.1501 22.261 22.3542 22.8018"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const PasswordIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4559 6.0415C26.1711 7.19915 26.5837 8.56213 26.5837 10.0211C26.5837 14.221 23.165 17.6256 18.9479 17.6256C18.1784 17.6256 16.4263 17.4488 15.574 16.7414L14.5085 17.8025C13.6206 18.6867 14.3309 18.6867 14.686 19.3941C14.686 19.3941 15.5739 20.6321 14.686 21.87C14.1533 22.5775 12.6617 23.5678 10.9569 21.87L10.6018 22.2237C10.6018 22.2237 11.6672 23.4617 10.7794 24.6997C10.2466 25.4071 8.82602 26.1145 7.58299 24.8765C7.5238 24.9355 7.19233 25.2656 6.33996 26.1145C5.4876 26.9633 4.44585 26.4682 4.03152 26.1145L2.96607 25.0534C1.97164 24.063 2.55172 22.9901 2.96607 22.5775L12.2 13.3812C12.2 13.3812 11.3121 11.9664 11.3121 10.0211C11.3121 5.82118 14.7308 2.4165 18.9479 2.4165C19.9373 2.4165 20.8827 2.6039 21.7503 2.945"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6115 10.0214C21.6115 11.4865 20.4189 12.6742 18.9478 12.6742C17.4767 12.6742 16.2842 11.4865 16.2842 10.0214C16.2842 8.55633 17.4767 7.36865 18.9478 7.36865C20.4189 7.36865 21.6115 8.55633 21.6115 10.0214Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const HelpIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2344 10.7241C12.2344 9.47285 13.2487 8.4585 14.5 8.4585C15.7513 8.4585 16.7656 9.47285 16.7656 10.7241C16.7656 11.5548 16.3186 12.281 15.652 12.6754C15.0776 13.0152 14.5 13.5307 14.5 14.1981V15.7085"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="14.5003" cy="19.3333" r="1.20833" fill="#333333" />
      <path
        d="M8.45866 4.03304C10.236 3.00493 12.2994 2.4165 14.5003 2.4165C21.1738 2.4165 26.5837 7.8264 26.5837 14.4998C26.5837 21.1733 21.1738 26.5832 14.5003 26.5832C7.82688 26.5832 2.41699 21.1733 2.41699 14.4998C2.41699 12.2989 3.00542 10.2355 4.03353 8.45817"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const ChatIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9167 8.54323C15.4948 7.72074 13.8441 7.25 12.0833 7.25C6.74458 7.25 2.41667 11.5779 2.41667 16.9167C2.41667 18.463 2.77976 19.9246 3.42534 21.2207C3.59691 21.5652 3.65401 21.9589 3.55455 22.3306L2.97879 24.4824C2.72886 25.4166 3.58345 26.2711 4.51757 26.0212L6.66941 25.4455C7.04113 25.346 7.43483 25.4031 7.77928 25.5747C9.07542 26.2202 10.537 26.5833 12.0833 26.5833C17.4221 26.5833 21.75 22.2554 21.75 16.9167C21.75 15.1559 21.2793 13.5052 20.4568 12.0833"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21.7503 17.5229C21.8306 17.4894 21.9103 17.4546 21.9892 17.4185C22.4268 17.2187 22.9177 17.1439 23.3823 17.2683L23.9575 17.4222C25.1252 17.7346 26.1934 16.6664 25.881 15.4987L25.7271 14.9235C25.6028 14.4588 25.6775 13.9679 25.8774 13.5304C26.3309 12.5375 26.5837 11.4336 26.5837 10.2707C26.5837 8.73115 26.1407 7.29509 25.3753 6.08297M11.4795 7.24452C12.6644 4.40903 15.4643 2.4165 18.7295 2.4165C20.2866 2.4165 21.7379 2.86962 22.9587 3.6512"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.87623 16.9165H7.88711M12.0942 16.9165H12.1051M16.3125 16.9165H16.3234"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PartnerIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1743 10.5361C22.5374 12.9001 20.719 14.0821 18.802 14.6777C15.9999 15.5483 12.9996 15.5483 10.1975 14.6777C8.28053 14.0821 6.46208 12.9001 2.8252 10.5361"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.66699 13.2915V15.7082"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.333 13.2915V15.7082"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.0811 4.83317C11.5787 3.42523 12.9214 2.4165 14.4998 2.4165C16.0781 2.4165 17.4209 3.42523 17.9185 4.83317"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M26.5837 16.9167C26.5837 12.3598 26.5837 10.0813 25.168 8.66565C24.2613 7.75897 23.0007 7.43299 21.0143 7.31579C19.8992 7.25 18.5553 7.25 16.917 7.25H12.0837C10.4453 7.25 9.10148 7.25 7.98638 7.31579C5.99993 7.43299 4.73932 7.75897 3.83264 8.66565C2.41699 10.0813 2.41699 12.3598 2.41699 16.9167C2.41699 21.4736 2.41699 23.752 3.83264 25.1677C5.24829 26.5833 7.52675 26.5833 12.0837 26.5833H16.917C21.4739 26.5833 23.7524 26.5833 25.168 25.1677C25.9573 24.3784 26.3065 23.321 26.461 21.75"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};


export const SourcesStatisticsIcon = () => {
  return (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5 2.41671L14.5 12.0834M14.5 12.0834L18.125 8.45837M14.5 12.0834L10.875 8.45837" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.41699 15.7084H6.23564C7.32939 15.7084 7.87626 15.7084 8.35697 15.9295C8.83768 16.1506 9.19358 16.5658 9.90538 17.3962L10.6369 18.2497C11.3487 19.0801 11.7046 19.4954 12.1853 19.7164C12.6661 19.9375 13.2129 19.9375 14.3067 19.9375H14.694C15.7877 19.9375 16.3346 19.9375 16.8153 19.7164C17.296 19.4954 17.6519 19.0801 18.3637 18.2497L19.0953 17.3962C19.8071 16.5658 20.163 16.1506 20.6437 15.9295C21.1244 15.7084 21.6713 15.7084 22.765 15.7084H26.5837" stroke="#333333" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M26.5837 14.5001C26.5837 20.1962 26.5837 23.0443 24.8141 24.8139C23.0445 26.5834 20.1965 26.5834 14.5003 26.5834C8.80419 26.5834 5.95612 26.5834 4.18656 24.8139C2.41699 23.0443 2.41699 20.1962 2.41699 14.5001C2.41699 8.80397 2.41699 5.9559 4.18656 4.18634C5.17313 3.19976 6.49495 2.76322 8.45866 2.57007M20.542 2.57007C22.5057 2.76322 23.8275 3.19976 24.8141 4.18634C25.9907 5.36294 26.385 7.01625 26.5171 9.66667" stroke="#333333" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
  );
};

export const RequestSuppliersIcon = () => {
  return (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.9675 9.71435L22.5275 9.15436C23.4554 8.22654 24.9596 8.22654 25.8875 9.15436C26.8153 10.0822 26.8153 11.5865 25.8875 12.5143L25.3275 13.0743M21.9675 9.71435C21.9675 9.71435 22.0375 10.9043 23.0875 11.9543C24.1375 13.0043 25.3275 13.0743 25.3275 13.0743M21.9675 9.71435L16.8193 14.8626C16.4706 15.2113 16.2962 15.3857 16.1463 15.5779C15.9694 15.8047 15.8178 16.05 15.694 16.3097C15.5892 16.5297 15.5112 16.7637 15.3552 17.2315L14.856 18.7293L14.6944 19.2139M25.3275 13.0743L20.1792 18.2225C19.8305 18.5712 19.6562 18.7456 19.4639 18.8955C19.2372 19.0724 18.9918 19.2241 18.7322 19.3478C18.5121 19.4527 18.2782 19.5306 17.8103 19.6866L16.3125 20.1859L15.8279 20.3474M15.8279 20.3474L15.3433 20.5089C15.1131 20.5857 14.8593 20.5258 14.6877 20.3542C14.5161 20.1826 14.4562 19.9287 14.5329 19.6985L14.6944 19.2139M15.8279 20.3474L14.6944 19.2139" stroke="#333333" strokeWidth="1.5"/>
    <path d="M9.66699 15.7085H12.6878" stroke="#333333" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M9.66699 10.875H17.5212" stroke="#333333" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M9.66699 20.5415H11.4795" stroke="#333333" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M3.625 16.9165V12.0832C3.625 7.52626 3.625 5.24781 5.04065 3.83216C6.4563 2.4165 8.73476 2.4165 13.2917 2.4165H15.7083C20.2652 2.4165 22.5437 2.4165 23.9593 3.83216M25.375 16.9165C25.375 21.4734 25.375 23.7519 23.9593 25.1675M5.04065 25.1675C6.4563 26.5832 8.73476 26.5832 13.2917 26.5832H15.7083C20.2652 26.5832 22.5437 26.5832 23.9593 25.1675M23.9593 25.1675C25.0989 24.028 25.3212 22.3293 25.3645 19.3332" stroke="#333333" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
  );
};
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import 'assets/landing.scss'
import SuppliersImg from 'assets/suppliers-img.png'
import SuppliersServicesImg1 from 'assets/suppliers-services-img1.png'
import SuppliersServicesImg2 from 'assets/suppliers-services-img2.png'
import SuppliersServicesImg3 from 'assets/suppliers-services-img3.png'
import SuppliersServicesImg4 from 'assets/suppliers-services-img4.png'
import SuppliersAbout from 'assets/suppliers-about.png'
import blob1 from 'assets/suppliers-blob1.svg'
import blob2 from 'assets/suppliers-blob2.svg'
import blob3 from 'assets/suppliers-blob3.svg'
import LandingPrice from 'entities/buyers/landing-price'
import CommonContext from 'services/AuthContext'

const ForSuppliers = () => {
	const { authState } = useContext(CommonContext)

	const [isModalVisible, setModalVisible] = useState(false)

	const openModal = () => {
		setModalVisible(true)
	}

	const closeModal = () => {
		setModalVisible(false)
	}

	return (
		<>
			<section className='landing-banner'>
				<div className='container'>
					<div className='landing-banner__block'>
						<div className='landing-banner__info'>
							<h1>
								<span>Расширяйте</span>
								возможности бизнеса
							</h1>
							<p>
								KIRA собрал комьюнити людей, заинтересованных <br />
								в покупке и перепродаже товаров. <br />
								Интеграторы, участники тендерных закупок, магазины, селеры и
								другие участники рынка.
							</p>
							<button type='button' onClick={openModal}>
								Подключиться бесплатно
							</button>
						</div>
						<div className='landing-banner__img'>
							<img src={SuppliersImg} alt='Поставщикам' />
						</div>
					</div>
				</div>
			</section>

			<section className='landing-section2'>
				<div className='container'>
					<div className='landing-title'>
						<h2>Не нарушаем концепцию дистрибуции</h2>
					</div>
					<div className='landing-section2__list'>
						<div className='landing-section2-item'>
							<div className='landing-section2-item__icon'>
								<span>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='46'
										height='46'
										viewBox='0 0 46 46'
										fill='none'
									>
										<path
											d='M12 19.5V15.75C12 15.1112 12.0521 14.4848 12.1521 13.875M34 19.5V15.75C34 9.5368 29.0751 4.5 23 4.5C19.7146 4.5 16.7656 5.97306 14.75 8.30862'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
										<path
											d='M21.2 41.5H15.8C10.7088 41.5 8.16325 41.5 6.58162 39.8891C5 38.2782 5 35.6854 5 30.5C5 25.3146 5 22.7218 6.58162 21.1109C8.16325 19.5 10.7088 19.5 15.8 19.5H30.2C35.2912 19.5 37.8368 19.5 39.4184 21.1109C41 22.7218 41 25.3146 41 30.5C41 35.6854 41 38.2782 39.4184 39.8891C37.8368 41.5 35.2912 41.5 30.2 41.5H28.4'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
									</svg>
								</span>
							</div>
							<div className='landing-section2-item__title'>
								1. Доступ к информации
							</div>
							<div className='landing-section2-item__text'>
								Получают только зарегистрированные пользователи, прошедшие
								верификацию на сайте.
							</div>
						</div>
						<div className='landing-section2-item'>
							<div className='landing-section2-item__icon'>
								<span>
									<svg
										width='46'
										height='46'
										viewBox='0 0 46 46'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M18.208 23.7665L20.9461 26.8332L27.7913 19.1665'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M5.75 19.9654C5.75 13.8367 5.75 10.7724 6.47357 9.74145C7.19714 8.71053 10.0785 7.72425 15.8411 5.75168L16.939 5.37587C19.9429 4.34762 21.4448 3.8335 23 3.8335C24.5552 3.8335 26.0571 4.34762 29.061 5.37587L30.1589 5.75168C35.9215 7.72425 38.8029 8.71053 39.5264 9.74145C40.25 10.7724 40.25 13.8367 40.25 19.9654C40.25 20.8912 40.25 21.895 40.25 22.9836C40.25 27.7848 38.6462 31.488 36.4167 34.3164M6.11961 26.8335C7.76299 35.0721 14.5215 39.3165 18.9722 41.2608C20.355 41.8648 21.0464 42.1668 23 42.1668C24.9536 42.1668 25.645 41.8648 27.0278 41.2608C28.1359 40.7767 29.387 40.1501 30.6667 39.3547'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
									</svg>
								</span>
							</div>
							<div className='landing-section2-item__title'>
								2. Никакой рекламы
							</div>
							<div className='landing-section2-item__text'>
								Мы не продвигаем товары и не проводим демонстрацию в интернете.
								Цены и остатки видны только верифицированным пользователям.
							</div>
						</div>
						<div className='landing-section2-item'>
							<div className='landing-section2-item__icon'>
								<span>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='46'
										height='46'
										viewBox='0 0 46 46'
										fill='none'
									>
										<circle
											cx='17.2497'
											cy='11.5002'
											r='7.66667'
											stroke='white'
											strokeWidth='2.5'
										/>
										<path
											d='M28.75 17.25C31.9256 17.25 34.5 14.6756 34.5 11.5C34.5 8.32436 31.9256 5.75 28.75 5.75'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
										<path
											d='M11.2872 39.4531C13.0834 39.9631 15.1079 40.2498 17.2497 40.2498C24.6595 40.2498 30.6663 36.8174 30.6663 32.5832C30.6663 28.349 24.6595 24.9165 17.2497 24.9165C9.83985 24.9165 3.83301 28.349 3.83301 32.5832C3.83301 33.245 3.97976 33.8872 4.25569 34.4998'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
										<path
											d='M34.5 26.8335C37.8623 27.5708 40.25 29.4381 40.25 31.6252C40.25 33.5981 38.307 35.3107 35.4583 36.1685'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
									</svg>
								</span>
							</div>
							<div className='landing-section2-item__title'>3. Комьюнити</div>
							<div className='landing-section2-item__text'>
								Комьюнити нашего сервиса, компании и лица заинтересованные в
								перепродаже товаров и услуг.
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='landing-section3'>
				<div className='container'>
					<div className='landing-title'>
						<h2>Сервис который решает ряд ключевых проблем</h2>
					</div>
					<div className='landing-section3__list'>
						<div className='landing-section3-item'>
							<div className='landing-section3-item__img'>
								<img
									src={SuppliersServicesImg1}
									alt='Поиск новых поставщиков и партнеров'
								/>
							</div>
							<div className='landing-section3-item__text'>
								Поиск новых поставщиков и партнеров для расширения бизнеса
							</div>
						</div>
						<div className='landing-section3-item'>
							<div className='landing-section3-item__img'>
								<img src={SuppliersServicesImg2} alt='Актуальная информация' />
							</div>
							<div className='landing-section3-item__text'>
								Актуальная информация для пользователей работающих онлайн
							</div>
						</div>
						<div className='landing-section3-item'>
							<div className='landing-section3-item__img'>
								<img
									src={SuppliersServicesImg3}
									alt='Возможность провести одноразовую сделку'
								/>
							</div>
							<div className='landing-section3-item__text'>
								Возможность провести одноразовую сделку с партнером не
								регистрируясь у дистрибьютора
							</div>
						</div>
						<div className='landing-section3-item'>
							<div className='landing-section3-item__img'>
								<img
									src={SuppliersServicesImg4}
									alt='Увеличивается скорость принятия решений'
								/>
							</div>
							<div className='landing-section3-item__text'>
								Увеличивается скорость принятия решений - не нужно искать по
								сайтам поставщиков
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='landing-section4'>
				<div className='container'>
					<div className='landing-section4__row'>
						<div className='landing-section4__info'>
							<h2>Современное облачное решение</h2>
							<p>
								Поисковая система KIRA — это современное решение для узкого
								круга пользователей, имеющих ряд одинаковых задач в бизнесе:
								Поиск поставщиков, поиск товаров, сбор данных, анализ цен, поиск
								партнеров для расширения бизнеса. Мы создали удобный и понятный
								инструмент для всех лиц, работающих в сфере торговли.
							</p>
							<p>
								KIRA – закрытая b2b площадка для международной торговли, мы не
								транслируем цены онлайн и не проводим рекламных компаний, наши
								пользователи это компании заинтересованные в перепродаже товара.
								Такая система позволяет размещаться прайс-лист компаниям,
								которые по каким-то причинам не могут разглашать цены на товары.
							</p>
							<button type='button' className='btn' onClick={openModal}>
								Добавить прайс-лист на сервис
							</button>
						</div>
						<div className='landing-section4__img'>
							<img src={SuppliersAbout} alt='Современное облачное решение' />
						</div>
					</div>
				</div>
			</section>
			<section className='banner-support landing-support'>
				<div className='container'>
					<div className='banner-support__block'>
						<div className='blob blob1'>
							<img src={blob1} />
						</div>
						<div className='blob blob2'>
							<img src={blob2} />
						</div>
						<div className='blob blob3'>
							<img src={blob3} />
						</div>
						<h2>
							Начните получать новых клиентов <br /> прямо сейчас
						</h2>
						<div className='banner-support__btn'>
							<Link to={authState ? '/account/profile' : '/register/'}>
								Зарегистрироваться
							</Link>
						</div>
					</div>
				</div>
			</section>
			<LandingPrice isOpen={isModalVisible} onClose={closeModal} />
		</>
	)
}

export default ForSuppliers

import React, { useRef, useEffect, useState, FC } from 'react'
import 'app/styles/modal.scss'

interface IProps {
	isOpen: boolean
	onClose: () => void
	onDelete: () => void
	isHistory?: boolean
}

const StatisticsWarning: FC<IProps> = ({
	isOpen,
	onClose,
	onDelete,
	isHistory
}) => {
	return (
		<>
			<div className={`modal modal-warning ${isOpen ? 'show' : 'hide'}`}>
				<div className='modal__window'>
					<div className='modal__header'>
						<h3>Предупреждение</h3>
					</div>
					<div className='modal__text'>
						<p>
							{isHistory
								? 'Нажав удалить, вы потеряете прайс-лист.'
								: 'Нажав удалить, вы потеряете источник и привязанный к нему прайс-лист.'}
						</p>
					</div>
					<div className='modal__btns'>
						<button type='button' className='btn' onClick={onClose}>
							Отменить
						</button>
						<button type='button' className='btn' onClick={onDelete}>
							Удалить
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default StatisticsWarning

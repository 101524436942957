import { axiosInstance } from 'shared/api'
import { getChatUrl } from 'shared/api/config'
import {
	IDialogResponse,
	IUserDialogs,
	IDialogRequest,
	IAttachFileMessage
} from './types/chat.types'
import { toFormData } from 'shared/utils'

const ChatService = {
	async getAllDialogs() {
		const response = await axiosInstance.get<IUserDialogs[]>(
			getChatUrl('/dialogs')
		)

		return response
	},

	async getMessages(data: IDialogRequest) {
		const response = await axiosInstance.get<IDialogResponse>(
			getChatUrl('/dialogs/messages'),
			{ params: data }
		)
		return response
	},

	async getAttachFile(data: IAttachFileMessage) {
		const { message_id, ...dataRest } = data

		const response = await axiosInstance.put<{ file: string }>(
			getChatUrl(`/file/`),
			toFormData(dataRest),
			{
				params: { message_id }
			}
		)

		return response
	}
}

export default ChatService

import { ChangeEvent, useEffect, useState } from 'react'
import './Suppliers.scss'
import { SearchIcon, SortIcon } from '../../assets/SearchBarIcons'
import { useGetCountries } from 'features/utils/api'
import { CardSuppliers } from 'entities/suppliers/card-suppliers'
import { useCompanyTypes } from 'features/company/api/useCompanyTypes'
import { FilterDropdown } from 'entities/suppliers/filter-dropdown'
import { useGetManufactures } from 'features/suppliers/api/useGetManufactures'
import { DownloadMore } from 'shared/ui/form/download-more'
import { Accordion } from 'shared/ui/accordion'
import { usePartnersList } from 'features/partners/api/usePartnersList'
import { CurrencyBlock } from 'widgets/currency-block'
import { IChainStoreList } from 'shared/services/types/supplier.types'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import Slider from '@mui/material/Slider'

const defaultFilter = {
	page_size: 50
}

const Suppliers = () => {
	const [filter, setFilter] = useState<any>(defaultFilter)
	const [searchValue, setSearchValue] = useState('')
	const [search, setSearch] = useState('')

	const { companyTypesData } = useCompanyTypes()

	const { data } = useGetCountries()
	const { getPartnersList } = usePartnersList(filter)
	const [ownFilter, setOwnFilter] = useState<any>({
		rating: undefined
	})

	const [manufacturesList, setManufacturesList] = useState<IChainStoreList>()

	const { data: manufacturesListAll } = useGetManufactures()

	useEffect(() => {
		if (manufacturesListAll) setManufacturesList(manufacturesListAll)
	}, [manufacturesListAll])

	const onSearchManufactures = (e: ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value
		setSearch(val)
		if (manufacturesListAll) {
			const result = manufacturesListAll?.results?.filter(item =>
				item.name.toLowerCase().includes(val.toLowerCase())
			)
			//@ts-ignore
			setManufacturesList(prev => ({ ...prev, results: result }))
		}
	}

	const onChangeOwnFilter = (name: string, item: any) => {
		if (typeof item === 'number') {
			setOwnFilter((prev: any) => {
				return {
					...prev,
					[name]: prev[name]?.includes(item)
						? prev[name].filter((v: any) => v !== item)
						: [...(prev[name] || []), item]
				}
			})
		} else if (Array.isArray(item)) {
			setOwnFilter((prev: any) => {
				return {
					...prev,
					[name]: item
				}
			})
		} else {
			setOwnFilter((prev: any) => {
				return {
					...prev,
					[name]: prev[name]?.includes(item)
						? prev[name].filter((v: any) => v.id !== item.id)
						: [...(prev[name] || []), item]
				}
			})
		}
	}
	const resetFilter = () => {
		setOwnFilter({
			country_id: [],
			companyType: [],
			manufacturer: [],
			rating: undefined
		})
		setSearchValue('')
	}

	const onSearchSuppliers = () => {
		const country = ownFilter?.country_id?.map((item: any) => item.id)
		const companyType = ownFilter?.companyType?.map((item: any) => item.key_name)
		const manufacturers = ownFilter?.manufacturer?.map((item: any) => item.id)
		const rating = ownFilter?.rating

		const min_rating = rating?.length ? Math.min(...rating) : undefined

		const max_rating = rating?.length > 1 ? Math.max(...rating) : undefined

		const data = {
			country: country?.length ? country : undefined,
			company_type: companyType?.length ? companyType : undefined,
			manufacturer: manufacturers?.length ? manufacturers : undefined,
			search: searchValue || undefined,

			min_rating: min_rating,
			max_rating: max_rating
		}
		setFilter((prev: any) => ({ ...prev, ...data }))
	}

	useEffect(() => {
		onSearchSuppliers()
	}, [ownFilter])

	const [viewToggle, setViewToggle] = useState('line')

	const [openSidebar, setOpenSidebar] = useState(false)

	const [fullManufacturers, setFullManufacturers] = useState(false)

	const toggleSidebar = () => {
		setOpenSidebar(!openSidebar)
	}

	const toggleViewListLine = () => {
		setViewToggle('line')
	}

	const toggleViewListGrid = () => {
		setViewToggle('grid')
	}

	return (
		<div className='suppliers'>
			<div className='container'>
				<CurrencyBlock />
				<div className={`product-searchbar`}>
					<form
						onSubmit={e => {
							e.preventDefault()
						}}
					>
						<div className='product-searchbar-block'>
							<input
								type='text'
								value={searchValue}
								placeholder='Введите название'
								required
								onChange={e => setSearchValue(e.target.value)}
							/>
						</div>
						<div className='product-searchbar-buttons'>
							<button
								className='product-searchbar-button'
								onClick={onSearchSuppliers}
							>
								<SearchIcon />
								Поиск
							</button>
							<button
								type='button'
								className='product-searchbar-button button-filter-mob'
								onClick={toggleSidebar}
							>
								<SortIcon />
							</button>
						</div>
					</form>
				</div>
				<div className='suppliers-list-view'>
					<div
						className={`view-toggle  ${
							viewToggle === 'line' ? 'line' : 'grid'
						}`}
					>
						<button
							type='button'
							className={`${viewToggle === 'line' ? 'active' : ''}`}
							onClick={toggleViewListLine}
						>
							<svg
								width='23'
								height='23'
								viewBox='0 0 23 23'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g opacity='0.4'>
									<path
										d='M2.62891 4.27168C2.62891 3.18745 2.62891 2.64533 3.97622 2.30851C5.32352 1.97168 7.49199 1.97168 11.8289 1.97168C16.1658 1.97168 18.3343 1.97168 19.6816 2.30851C21.0289 2.64533 21.0289 3.18745 21.0289 4.27168C21.0289 5.35591 21.0289 5.89803 19.6816 6.23485C18.3343 6.57168 16.1658 6.57168 11.8289 6.57168C7.49199 6.57168 5.32352 6.57168 3.97622 6.23485C2.62891 5.89803 2.62891 5.35591 2.62891 4.27168Z'
										fill='#1E2321'
									/>
									<path
										d='M2.62891 11.5002C2.62891 10.416 2.62891 9.87385 3.97622 9.53702C5.32352 9.2002 7.49198 9.2002 11.8289 9.2002C16.1658 9.2002 18.3343 9.2002 19.6816 9.53702C21.0289 9.87385 21.0289 10.416 21.0289 11.5002C21.0289 12.5844 21.0289 13.1265 19.6816 13.4634C18.3343 13.8002 16.1658 13.8002 11.8289 13.8002C7.49198 13.8002 5.32352 13.8002 3.97622 13.4634C2.62891 13.1265 2.62891 12.5844 2.62891 11.5002Z'
										fill='#1E2321'
									/>
									<path
										d='M2.62891 18.7287C2.62891 17.6445 2.62891 17.1024 3.97622 16.7655C5.32352 16.4287 7.49198 16.4287 11.8289 16.4287C16.1658 16.4287 18.3343 16.4287 19.6816 16.7655C21.0289 17.1024 21.0289 17.6445 21.0289 18.7287C21.0289 19.8129 21.0289 20.3551 19.6816 20.6919C18.3343 21.0287 16.1658 21.0287 11.8289 21.0287C7.49198 21.0287 5.32352 21.0287 3.97622 20.6919C2.62891 20.3551 2.62891 19.8129 2.62891 18.7287Z'
										fill='#1E2321'
									/>
								</g>
							</svg>
						</button>

						<button
							type='button'
							className={`${viewToggle === 'grid' ? 'active' : ''}`}
							onClick={toggleViewListGrid}
						>
							<svg
								width='23'
								height='23'
								viewBox='0 0 23 23'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g opacity='0.4'>
									<path
										d='M1.91602 6.22949C1.91602 4.19656 1.91602 3.18009 2.54757 2.54854C3.17912 1.91699 4.19558 1.91699 6.22852 1.91699C8.26145 1.91699 9.27791 1.91699 9.90946 2.54854C10.541 3.18009 10.541 4.19656 10.541 6.22949C10.541 8.26242 10.541 9.27889 9.90946 9.91044C9.27791 10.542 8.26145 10.542 6.22852 10.542C4.19558 10.542 3.17912 10.542 2.54757 9.91044C1.91602 9.27889 1.91602 8.26242 1.91602 6.22949Z'
										fill='#1E2321'
									/>
									<path
										d='M12.459 16.7705C12.459 14.7376 12.459 13.7211 13.0905 13.0896C13.7221 12.458 14.7386 12.458 16.7715 12.458C18.8044 12.458 19.8209 12.458 20.4524 13.0896C21.084 13.7211 21.084 14.7376 21.084 16.7705C21.084 18.8034 21.084 19.8199 20.4524 20.4515C19.8209 21.083 18.8044 21.083 16.7715 21.083C14.7386 21.083 13.7221 21.083 13.0905 20.4515C12.459 19.8199 12.459 18.8034 12.459 16.7705Z'
										fill='#1E2321'
									/>
									<path
										d='M1.91602 16.7705C1.91602 14.7376 1.91602 13.7211 2.54757 13.0896C3.17912 12.458 4.19558 12.458 6.22852 12.458C8.26145 12.458 9.27791 12.458 9.90946 13.0896C10.541 13.7211 10.541 14.7376 10.541 16.7705C10.541 18.8034 10.541 19.8199 9.90946 20.4515C9.27791 21.083 8.26145 21.083 6.22852 21.083C4.19558 21.083 3.17912 21.083 2.54757 20.4515C1.91602 19.8199 1.91602 18.8034 1.91602 16.7705Z'
										fill='#1E2321'
									/>
									<path
										d='M12.459 6.22949C12.459 4.19656 12.459 3.18009 13.0905 2.54854C13.7221 1.91699 14.7386 1.91699 16.7715 1.91699C18.8044 1.91699 19.8209 1.91699 20.4524 2.54854C21.084 3.18009 21.084 4.19656 21.084 6.22949C21.084 8.26242 21.084 9.27889 20.4524 9.91044C19.8209 10.542 18.8044 10.542 16.7715 10.542C14.7386 10.542 13.7221 10.542 13.0905 9.91044C12.459 9.27889 12.459 8.26242 12.459 6.22949Z'
										fill='#1E2321'
									/>
								</g>
							</svg>
						</button>
					</div>
				</div>
				<div className='suppliers__row'>
					<div
						onClick={toggleSidebar}
						className={`overlay ${openSidebar ? 'open' : ''}`}
					></div>
					<div className={`suppliers-filter ${openSidebar ? 'open' : ''}`}>
						<div className='suppliers-filter__header'>
							Фильтр
							<svg
								onClick={toggleSidebar}
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
									stroke='white'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
									stroke='white'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</div>

						<div className='suppliers-filter__body'>
							<Accordion title='Тип компании'>
								{companyTypesData?.map(item => (
									<label key={item.id}>
										<input
											onClick={() => onChangeOwnFilter('companyType', item)}
											checked={ownFilter?.companyType?.includes(item)}
											type='checkbox'
											name='company_type'
										/>
										<span>{item.name}</span>
									</label>
								))}
							</Accordion>

							<Accordion title='По странам'>
								<FilterDropdown
									countries={data || []}
									value={ownFilter?.country_id}
									onSelect={data => onChangeOwnFilter('country_id', data)}
								/>
							</Accordion>

							{/* //TODO временно убран */}
							{/* <Accordion title='Производитель'>
								<div className='suppliers-filter-item__search'>
									<input
										placeholder='Поиск'
										value={search}
										onChange={onSearchManufactures}
									/>
									<button type='submit'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='19'
											height='19'
											viewBox='0 0 19 19'
											fill='none'
										>
											<path
												d='M16.625 16.625L11.875 11.875M2.375 7.91667C2.375 8.64441 2.51834 9.36503 2.79683 10.0374C3.07533 10.7097 3.48353 11.3206 3.99812 11.8352C4.51271 12.3498 5.12362 12.758 5.79596 13.0365C6.46831 13.315 7.18892 13.4583 7.91667 13.4583C8.64441 13.4583 9.36503 13.315 10.0374 13.0365C10.7097 12.758 11.3206 12.3498 11.8352 11.8352C12.3498 11.3206 12.758 10.7097 13.0365 10.0374C13.315 9.36503 13.4583 8.64441 13.4583 7.91667C13.4583 7.18892 13.315 6.46831 13.0365 5.79596C12.758 5.12362 12.3498 4.51271 11.8352 3.99812C11.3206 3.48353 10.7097 3.07533 10.0374 2.79683C9.36503 2.51834 8.64441 2.375 7.91667 2.375C7.18892 2.375 6.46831 2.51834 5.79596 2.79683C5.12362 3.07533 4.51271 3.48353 3.99812 3.99812C3.48353 4.51271 3.07533 5.12362 2.79683 5.79596C2.51834 6.46831 2.375 7.18892 2.375 7.91667Z'
												stroke='#6A6A6B'
												strokeWidth='1.1'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</button>
								</div>
								<div
									className={`filter-manufacturers-list ${
										fullManufacturers ? 'full' : ''
									}`}
								>
									{manufacturesList?.results?.map(item => (
										<label key={item.id}>
											<input
												type='checkbox'
												name='manufacturer'
												onClick={() => onChangeOwnFilter('manufacturer', item)}
												checked={ownFilter?.manufacturer?.includes(item)}
											/>
											<span>{item.name}</span>
										</label>
									))}
									{!!manufacturesList?.results?.length &&
										manufacturesList?.results?.length > 10 && (
											<button
												className='manufacturers-more'
												onClick={() => setFullManufacturers(!fullManufacturers)}
											>
												{fullManufacturers ? 'Свернуть' : 'Показать все'}
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='12'
													height='12'
													viewBox='0 0 12 12'
													fill='none'
												>
													<path
														d='M9.5 4.5L6 7.5L2.5 4.5'
														stroke='#8A7DE7'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</button>
										)}
								</div>
							</Accordion> */}

							<Accordion title='Рейтинг'>
								<div className={'slider-rating'}>
									<Slider
										aria-label='Always visible'
										defaultValue={[0, 5]}
										min={0}
										className='slider'
										value={ownFilter.rating}
										getAriaLabel={() => 'Minimum distance'}
										step={1}
										max={5}
										onChange={(e: any) => {
											const value = e.target?.value

											onChangeOwnFilter('rating', value)
										}}
										marks={[
											{
												value: 0,
												label: (
													<div className='mark-item'>
														<span>0</span>{' '}
														<svg
															width='25'
															height='23'
															viewBox='0 0 25 23'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
															preserveAspectRatio='none'
														>
															<path
																d='M12.5 3.68429L14.5832 8.35773L14.9354 9.14793L15.7958 9.23874L20.8842 9.77579L17.0833 13.2012L16.4406 13.7804L16.6201 14.6267L17.6817 19.6321L13.2494 17.0756L12.5 16.6434L11.7506 17.0756L7.31825 19.6321L8.3799 14.6267L8.55941 13.7804L7.91672 13.2012L4.11576 9.77579L9.2042 9.23874L10.0646 9.14793L10.4168 8.35773L12.5 3.68429Z'
																fill={''}
																stroke='#F1C644'
																strokeWidth='3'
															/>
														</svg>
													</div>
												)
											},
											{
												value: 5,
												label: (
													<div className='mark-item'>
														<span>5</span>{' '}
														<svg
															width='25'
															height='23'
															viewBox='0 0 25 23'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
															preserveAspectRatio='none'
														>
															<path
																d='M12.5 3.68429L14.5832 8.35773L14.9354 9.14793L15.7958 9.23874L20.8842 9.77579L17.0833 13.2012L16.4406 13.7804L16.6201 14.6267L17.6817 19.6321L13.2494 17.0756L12.5 16.6434L11.7506 17.0756L7.31825 19.6321L8.3799 14.6267L8.55941 13.7804L7.91672 13.2012L4.11576 9.77579L9.2042 9.23874L10.0646 9.14793L10.4168 8.35773L12.5 3.68429Z'
																fill={'#F1C644'}
																stroke='#F1C644'
																strokeWidth='3'
															/>
														</svg>
													</div>
												)
											}
										]}
										valueLabelDisplay='auto'
									/>
									{/* <Slider
										range
										min={0}
										max={5}
										value={ownFilter.rating}
										marks={{
											0: {
												label: (
													<div className='mark-item'>
														<span>0</span>{' '}
														<svg
															width='25'
															height='23'
															viewBox='0 0 25 23'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
															preserveAspectRatio='none'
														>
															<path
																d='M12.5 3.68429L14.5832 8.35773L14.9354 9.14793L15.7958 9.23874L20.8842 9.77579L17.0833 13.2012L16.4406 13.7804L16.6201 14.6267L17.6817 19.6321L13.2494 17.0756L12.5 16.6434L11.7506 17.0756L7.31825 19.6321L8.3799 14.6267L8.55941 13.7804L7.91672 13.2012L4.11576 9.77579L9.2042 9.23874L10.0646 9.14793L10.4168 8.35773L12.5 3.68429Z'
																fill={''}
																stroke='#F1C644'
																strokeWidth='3'
															/>
														</svg>
													</div>
												)
											},
											5: {
												label: (
													<div className={'mark-item'}>
														<span>5</span>
														<svg
															width='25'
															height='23'
															viewBox='0 0 25 23'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
															preserveAspectRatio='none'
														>
															<path
																d='M12.5 3.68429L14.5832 8.35773L14.9354 9.14793L15.7958 9.23874L20.8842 9.77579L17.0833 13.2012L16.4406 13.7804L16.6201 14.6267L17.6817 19.6321L13.2494 17.0756L12.5 16.6434L11.7506 17.0756L7.31825 19.6321L8.3799 14.6267L8.55941 13.7804L7.91672 13.2012L4.11576 9.77579L9.2042 9.23874L10.0646 9.14793L10.4168 8.35773L12.5 3.68429Z'
																fill={'#F1C644'}
																stroke='#F1C644'
																strokeWidth='3'
															/>
														</svg>
													</div>
												)
											}
										}}
										defaultValue={[0, 5]}
										trackStyle={{ background: '#8a7de7' }}
										handleStyle={{
											border: '2px solid #8a7de7',
											boxShadow: '0 0 5px #4e41ab'
										}}
										handleRender={(node, handleProps) => {
											return (
												<Tooltip
													overlay={+handleProps.value}
													placement='bottom'
												>
													{node}
												</Tooltip>
											)
										}}
										onChange={e => {
											onChangeOwnFilter('rating', e)
										}}
									/> */}
								</div>
							</Accordion>

							<button className='btn rest-filter' onClick={resetFilter}>
								Очистить
							</button>
						</div>
					</div>

					<div className='suppliers-list-block'>
						<div
							className={`${
								viewToggle === 'line'
									? 'suppliers-list-line'
									: 'suppliers-list-grid'
							}`}
						>
							{getPartnersList?.data?.results?.map((item: any) => (
								<CardSuppliers key={item} data={item} countries={data} />
							))}
						</div>
						{getPartnersList?.data?.results?.length !==
							getPartnersList?.data?.count && (
							<DownloadMore onClick={() => getPartnersList.fetchNextPage()} />
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Suppliers

import React, { FC } from 'react'
import './styles.scss'
import clsx from 'clsx'
interface IProps {
	isAbsolute?: boolean
	isFixed?: boolean
	isMini?: boolean
}

export const Loader: FC<IProps> = ({ isAbsolute, isFixed, isMini }) => {
	return (
		<>
			<div
				className={clsx(
					'loader-wrapper',
					isFixed && 'fixed',
					isAbsolute && 'absolute',
					isMini && 'isMini'
				)}
			>
				<span className={clsx('loader')}></span>
			</div>
			{isFixed && <div className={'blur'} />}
		</>
	)
}

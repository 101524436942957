import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import './ProductControlPage.scss'
import { Link } from 'react-router-dom'
import ProjectsAction from '../../features/projects/ui/projects-action'
import { useEffect } from 'react'
import { CreateProjectPopup } from '../../features/profile/ui/popup-profile'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'
import Select from 'react-select'
import { useValueCurrency } from 'shared/hooks'
import { format } from 'date-fns'
import { useGetTariff } from 'features/tariff/api/useGetTariff'
import { getCorrectDate } from 'shared/utils/getCorrectDate'
import { useGetProject } from 'features/projects/api/useGetProjects'
import { Loader } from 'shared/ui/loader'
import { useUsersCompany } from 'features/company/api/useUserCompany'

const tariffsBlur = [undefined, 5, 7]

export const ProjectsControlPage = () => {
	const [startDate, setStartDate] = useState<Date | null>(null)
	const [endDate, setEndDate] = useState<Date | null>(null)
	const { companyUsersData } = useUsersCompany()

	const [createProject, setCreateProject] = useState(false)

	const [projectSort, setProjectSort] = useState<string>()
	const [projectDate, setProjectDate] = useState<string>()

	const [filter, setFilter] = useState<any>()

	const { tariffName, isPending, data } = useGetTariff()
	const { getCurrencyCounty, currency } = useValueCurrency()

	const { data: dataProject, isLoading } = useGetProject(filter)

	const handleProjectSort = (selectedOption: any) => {
		setProjectSort(selectedOption.value.toString())
	}

	const handleProjectDate = (selectedOption: any) => {
		setProjectDate(selectedOption.value.toLocaleString())
		setStartDate(null)
		setEndDate(null)
	}

	useEffect(() => {
		if (!endDate && !startDate) return

		if (!endDate) {
			setProjectDate(`start_date=${getCorrectDate(startDate)}`)
		} else if (!startDate) {
			setProjectDate(`end_date=${getCorrectDate(endDate, true)}`)
		} else {
			setProjectDate(
				`start_date=${getCorrectDate(startDate)}&end_date=${getCorrectDate(
					endDate,
					true
				)}`
			)
		}
	}, [startDate, endDate])

	const today = new Date()
	const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
	const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)

	const startOfLastMonth = new Date(
		lastMonth.getFullYear(),
		lastMonth.getMonth(),
		1
	)
	const endOfLastMonth = new Date(
		lastMonth.getFullYear(),
		lastMonth.getMonth() + 1,
		0
	)
	const ninetyDaysAgo = new Date(today)
	ninetyDaysAgo.setDate(today.getDate() - 90)

	const lastYear = new Date(today)
	lastYear.setFullYear(today.getFullYear() - 1)

	const options = [
		{ value: `start_date=${getCorrectDate(new Date())}`, label: 'Сегодня' },
		{
			value: `start_date=${getCorrectDate(
				startOfMonth
			)}&end_date=${getCorrectDate(new Date(), true)}`,
			label: 'В этом месяце'
		},
		{
			value: `start_date=${getCorrectDate(
				startOfLastMonth
			)}&end_date=${getCorrectDate(endOfLastMonth, true)}`,
			label: 'За прошлый месяц'
		},
		{
			value: `start_date=${getCorrectDate(
				ninetyDaysAgo
			)}&end_date=${getCorrectDate(new Date(), true)}`,
			label: 'За 90 дней'
		},
		{
			value: `start_date=${getCorrectDate(lastYear)}&end_date=${getCorrectDate(
				new Date(),
				true
			)}`,
			label: 'За 365 дней'
		}
	]

	const optionsSort = [
		{ value: ``, label: 'Все проекты' },
		{ value: `users=users`, label: 'Проекты сотрудников' },
		{
			value: `users=owner`,
			label: 'Свои проекты'
		},
		{
			value: `ordering=name`,
			label: 'По названию'
		},
		{
			value: `ordering=created_at`,
			label: 'По времени создания (раньше)'
		},
		{
			value: `ordering=-created_at`,
			label: 'По времени создания (позже)'
		}
	]

	const openCreateProject = () => {
		setCreateProject(true)
	}

	const navigate = useNavigate()
	const goBack = () => {
		navigate(-1)
	}

	useEffect(() => {
		const users = projectSort?.includes('users')
			? projectSort?.split('=')[1]
			: null

		const ordering = projectSort?.includes('ordering')
			? projectSort?.split('=')[1]
			: null

		const startDate = projectDate?.includes('start_date')
			? projectDate?.split('start_date=')[1]?.split('&end_date=')[0]
			: null

		const endDate = projectDate?.includes('end_date')
			? projectDate?.split('end_date=')[1]
			: null

		if (projectDate === undefined && projectSort === undefined) return
		setFilter({ users, ordering, start_date: startDate, end_date: endDate })
	}, [projectDate, projectSort])

	return (
		<div className='projectcontrolpage'>
			<div className='container'>
				<div className='projectcontrolpage-header'>
					<div className='projectcontrolpage-header-title'>
						<h1>Управление проектами</h1>
						<button onClick={goBack}>Назад</button>
					</div>
				</div>
				<div className='projectcontrolpage-data'>
					<Select
						value={options.find(option => option.value === projectDate) || null}
						onChange={handleProjectDate}
						options={options}
						placeholder='Указать период'
						isSearchable={false}
						classNamePrefix={'settings-select'}
						components={{ DropdownIndicator: CustomIndicator }}
					/>
				</div>
				<div className='projectcontrolpage-date-selection'>
					<div className='inputs-date'>
						<div className='input-date'>
							<DatePicker
								showIcon
								selected={startDate}
								onChange={date => setStartDate(date)}
								locale={ru}
								dateFormat='dd.MM.yyyy'
								placeholderText='От'
								icon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='20'
										viewBox='0 0 20 20'
										fill='none'
									>
										<path
											d='M11.667 18.3335H8.33366C5.19096 18.3335 3.61961 18.3335 2.6433 17.3572C1.66699 16.3809 1.66699 14.8095 1.66699 11.6668V10.0002C1.66699 6.85747 1.66699 5.28612 2.6433 4.30981C3.61961 3.3335 5.19096 3.3335 8.33366 3.3335H11.667C14.8097 3.3335 16.381 3.3335 17.3573 4.30981C18.3337 5.28612 18.3337 6.85747 18.3337 10.0002V11.6668C18.3337 14.8095 18.3337 16.3809 17.3573 17.3572C16.813 17.9015 16.0838 18.1424 15.0003 18.2489'
											stroke='#333333'
											strokeWidth='1.2'
											strokeLinecap='round'
										/>
										<path
											d='M5.83301 3.3335V2.0835'
											stroke='#333333'
											strokeWidth='1.5'
											strokeLinecap='round'
										/>
										<path
											d='M14.167 3.3335V2.0835'
											stroke='#333333'
											strokeWidth='1.5'
											strokeLinecap='round'
										/>
										<path
											d='M17.917 7.5H13.8545H8.95866M1.66699 7.5H4.89616'
											stroke='#333333'
											strokeWidth='1.2'
											strokeLinecap='round'
										/>
										<path
											d='M14.9997 14.1667C14.9997 14.6269 14.6266 15 14.1663 15C13.7061 15 13.333 14.6269 13.333 14.1667C13.333 13.7064 13.7061 13.3333 14.1663 13.3333C14.6266 13.3333 14.9997 13.7064 14.9997 14.1667Z'
											fill='#333333'
										/>
										<path
											d='M14.9997 10.8333C14.9997 11.2936 14.6266 11.6667 14.1663 11.6667C13.7061 11.6667 13.333 11.2936 13.333 10.8333C13.333 10.3731 13.7061 10 14.1663 10C14.6266 10 14.9997 10.3731 14.9997 10.8333Z'
											fill='#333333'
										/>
										<path
											d='M10.8337 14.1667C10.8337 14.6269 10.4606 15 10.0003 15C9.54009 15 9.16699 14.6269 9.16699 14.1667C9.16699 13.7064 9.54009 13.3333 10.0003 13.3333C10.4606 13.3333 10.8337 13.7064 10.8337 14.1667Z'
											fill='#333333'
										/>
										<path
											d='M10.8337 10.8333C10.8337 11.2936 10.4606 11.6667 10.0003 11.6667C9.54009 11.6667 9.16699 11.2936 9.16699 10.8333C9.16699 10.3731 9.54009 10 10.0003 10C10.4606 10 10.8337 10.3731 10.8337 10.8333Z'
											fill='#333333'
										/>
										<path
											d='M6.66667 14.1667C6.66667 14.6269 6.29357 15 5.83333 15C5.3731 15 5 14.6269 5 14.1667C5 13.7064 5.3731 13.3333 5.83333 13.3333C6.29357 13.3333 6.66667 13.7064 6.66667 14.1667Z'
											fill='#333333'
										/>
										<path
											d='M6.66667 10.8333C6.66667 11.2936 6.29357 11.6667 5.83333 11.6667C5.3731 11.6667 5 11.2936 5 10.8333C5 10.3731 5.3731 10 5.83333 10C6.29357 10 6.66667 10.3731 6.66667 10.8333Z'
											fill='#333333'
										/>
									</svg>
								}
							/>
						</div>
						<div className='input-date'>
							<DatePicker
								showIcon
								selected={endDate}
								onChange={date => setEndDate(date)}
								locale={ru}
								dateFormat='dd.MM.yyyy'
								placeholderText='До'
								icon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='20'
										viewBox='0 0 20 20'
										fill='none'
									>
										<path
											d='M11.667 18.3335H8.33366C5.19096 18.3335 3.61961 18.3335 2.6433 17.3572C1.66699 16.3809 1.66699 14.8095 1.66699 11.6668V10.0002C1.66699 6.85747 1.66699 5.28612 2.6433 4.30981C3.61961 3.3335 5.19096 3.3335 8.33366 3.3335H11.667C14.8097 3.3335 16.381 3.3335 17.3573 4.30981C18.3337 5.28612 18.3337 6.85747 18.3337 10.0002V11.6668C18.3337 14.8095 18.3337 16.3809 17.3573 17.3572C16.813 17.9015 16.0838 18.1424 15.0003 18.2489'
											stroke='#333333'
											strokeWidth='1.2'
											strokeLinecap='round'
										/>
										<path
											d='M5.83301 3.3335V2.0835'
											stroke='#333333'
											strokeWidth='1.5'
											strokeLinecap='round'
										/>
										<path
											d='M14.167 3.3335V2.0835'
											stroke='#333333'
											strokeWidth='1.5'
											strokeLinecap='round'
										/>
										<path
											d='M17.917 7.5H13.8545H8.95866M1.66699 7.5H4.89616'
											stroke='#333333'
											strokeWidth='1.2'
											strokeLinecap='round'
										/>
										<path
											d='M14.9997 14.1667C14.9997 14.6269 14.6266 15 14.1663 15C13.7061 15 13.333 14.6269 13.333 14.1667C13.333 13.7064 13.7061 13.3333 14.1663 13.3333C14.6266 13.3333 14.9997 13.7064 14.9997 14.1667Z'
											fill='#333333'
										/>
										<path
											d='M14.9997 10.8333C14.9997 11.2936 14.6266 11.6667 14.1663 11.6667C13.7061 11.6667 13.333 11.2936 13.333 10.8333C13.333 10.3731 13.7061 10 14.1663 10C14.6266 10 14.9997 10.3731 14.9997 10.8333Z'
											fill='#333333'
										/>
										<path
											d='M10.8337 14.1667C10.8337 14.6269 10.4606 15 10.0003 15C9.54009 15 9.16699 14.6269 9.16699 14.1667C9.16699 13.7064 9.54009 13.3333 10.0003 13.3333C10.4606 13.3333 10.8337 13.7064 10.8337 14.1667Z'
											fill='#333333'
										/>
										<path
											d='M10.8337 10.8333C10.8337 11.2936 10.4606 11.6667 10.0003 11.6667C9.54009 11.6667 9.16699 11.2936 9.16699 10.8333C9.16699 10.3731 9.54009 10 10.0003 10C10.4606 10 10.8337 10.3731 10.8337 10.8333Z'
											fill='#333333'
										/>
										<path
											d='M6.66667 14.1667C6.66667 14.6269 6.29357 15 5.83333 15C5.3731 15 5 14.6269 5 14.1667C5 13.7064 5.3731 13.3333 5.83333 13.3333C6.29357 13.3333 6.66667 13.7064 6.66667 14.1667Z'
											fill='#333333'
										/>
										<path
											d='M6.66667 10.8333C6.66667 11.2936 6.29357 11.6667 5.83333 11.6667C5.3731 11.6667 5 11.2936 5 10.8333C5 10.3731 5.3731 10 5.83333 10C6.29357 10 6.66667 10.3731 6.66667 10.8333Z'
											fill='#333333'
										/>
									</svg>
								}
							/>
						</div>
					</div>
					<button className='btn' onClick={openCreateProject}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='23'
							height='24'
							viewBox='0 0 23 24'
							fill='none'
						>
							<path
								d='M9.58594 13.9167H11.5026M11.5026 13.9167H13.4193M11.5026 13.9167V15.8333M11.5026 13.9167V12'
								stroke='white'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M21.0807 11.8062C21.0807 9.28348 21.0807 8.02213 20.3433 7.20226C20.2755 7.12684 20.2037 7.05507 20.1283 6.98724C19.3084 6.24984 18.0471 6.24984 15.5244 6.24984H15.1663C14.0607 6.24984 13.5079 6.24984 12.9927 6.103C12.7097 6.02233 12.437 5.90935 12.1798 5.76629C11.7117 5.50585 11.3209 5.11496 10.5391 4.33317L10.0117 3.80585C9.74971 3.54382 9.61869 3.4128 9.48101 3.29866C8.8874 2.80658 8.15919 2.50494 7.39148 2.43316C7.21342 2.4165 7.02814 2.4165 6.65757 2.4165C5.81181 2.4165 5.38893 2.4165 5.03668 2.48296C3.48603 2.77553 2.27309 3.98847 1.98052 5.53912C1.91406 5.89137 1.91406 6.31425 1.91406 7.16001M21.0724 15.8332C21.038 18.2094 20.8618 19.5566 19.958 20.4604C18.8352 21.5832 17.0282 21.5832 13.4141 21.5832H9.58073C5.96663 21.5832 4.15958 21.5832 3.03682 20.4604C1.91406 19.3377 1.91406 17.5306 1.91406 13.9165V11.0415'
								stroke='white'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
						</svg>
						Добавить папку
					</button>
				</div>
				<CreateProjectPopup
					isOpen={createProject}
					setIsOpen={setCreateProject}
				/>

				<div className='projectcontrolpage-projects'>
					<table>
						<thead>
							<tr className='projectcontrolpage-projects-header'>
								<td>
									<div className='projectcontrolpage-projects-header-sort'>
										<Select
											value={optionsSort.find(
												option => option.value === projectSort
											)}
											onChange={handleProjectSort}
											options={optionsSort}
											placeholder='Название'
											isSearchable={false}
											classNamePrefix={'settings-select'}
											components={{ DropdownIndicator: CustomIndicator }}
										/>
									</div>
								</td>
								<td>
									<p className='ammount-col'>Дата изменения:</p>
								</td>
								<td>
									<p className='ammount-col'>Количество товаров:</p>
								</td>
								<td>
									<p>Сумма ({currency})</p>
								</td>
								<td>
									<p style={{ textAlign: 'right', paddingRight: '20px' }}>
										Действие
									</p>
								</td>
							</tr>
						</thead>
						<tbody>
							{dataProject?.map(i => {
								return (
									<tr key={i.id} className='projectcontrolpage-projects-body'>
										<React.Fragment>
											<td>
												<div className={'projectcontrolpage-column'}>
													<Link to={`/project-page/${i.id}`}>
														<span
															className='projects-color'
															style={{ background: i.color }}
														></span>
														{i.name}
													</Link>
												</div>
											</td>
											<td>
												<p className='ammount-col'>
													{/* @ts-ignore */}
													{format(i.updated_at, 'dd.MM.yyy')}
												</p>
											</td>
											<td>
												<p className='ammount-col'>{i.total_products_count}</p>
											</td>

											<td>
												<p className='price-col'>
													{getCurrencyCounty(i.total_price.price)}
												</p>
											</td>
											<td>
												<div
													className='projectcontrolpage-projects-body-buttons'
													style={{
														display: 'flex',
														justifyContent: 'flex-end'
													}}
												>
													<ProjectsAction
														project_data={i}
														companyUsersData={companyUsersData}
													/>
												</div>
											</td>
										</React.Fragment>
									</tr>
								)
							})}
						</tbody>
					</table>

					{/* <div className='projectcontrolpage-projects-body'> */}

					{!!isLoading && <Loader isFixed />}
				</div>
				{(!tariffName || tariffsBlur.includes(data?.tariff?.id)) &&
					!isPending && (
						<div className='profile-overlay fixed'>
							<div className='profile-overlay-wrapper'>
								<div className='profile-overlay-content'>
									<p>
										Сейчас у вас нет подписки, для полного функционала,
										пожалуйста, приобретите подписку{' '}
										<Link to='/account/tariffs'>“Тарифы и оплата”</Link>
									</p>
								</div>
								<div className='profile-overlay-close'>
									<p>
										<button onClick={() => navigate(-1)}>
											<svg
												height='20'
												width='20'
												viewBox='0 0 20 20'
												aria-hidden='true'
												focusable='false'
												className='css-tj5bde-Svg'
											>
												<path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
											</svg>
										</button>
									</p>
								</div>
							</div>
						</div>
					)}
			</div>
		</div>
	)
}

const CustomIndicator = (props: any) => {
	return (
		<div className='settings-select__arrow' {...props.innerProps}>
			<svg
				width='14'
				height='8'
				viewBox='0 0 14 8'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M12.8327 1.5L6.99935 6.5L1.16602 1.5'
					stroke='#333333'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</div>
	)
}

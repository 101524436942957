import { AxiosError } from 'axios'
import { useAlert } from 'react-alert'

const strFile = (obj?: Record<string, any>) => {
	if (!obj || typeof obj === 'string') return null

	const data = Object.values(obj).reduce((acc, item) => {
		if (Array.isArray(item)) {
			return item.reduce((accum, v) => {
				if (typeof v === 'string') return `${accum}${v}\n`

				const res = Object.values(v)

				return `${accum}${res}\n`
			}, '')
		}
		return `${acc}${item.join('\n')}\n`
	}, '')

	return data
}
export function useAlertMessage() {
	const alert = useAlert()

	const addAlertMessage = (
		message: string | string[] | AxiosError<any>,
		type: 'success' | 'info' | 'error' = 'success'
	) => {
		const resultMessage = Array.isArray(message)
			? message.join('\n')
			: typeof message === 'string'
			? message
			: typeof message?.response?.data?.detail === 'string'
			? message?.response?.data?.detail
			: message?.response?.data?.file?.join('\n') ||
			  strFile(message?.response?.data || message) ||
			  (message?.message as string)

		alert[type](resultMessage)
	}

	return { addAlertMessage }
}

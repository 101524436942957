import { axiosClassic, axiosInstance } from 'shared/api'
import { getUserUrl } from 'shared/api/config'
import { IUserData, IUserUpdate } from './types/user.types'

const UserService = {
	async getUser(id: number) {
		const response = await axiosInstance.get<IUserData>(
			getUserUrl(`/profile/${id}/`)
		)
		return response
	},

	async updateUser(id: number, data: IUserUpdate) {
		const response = await axiosInstance.put<IUserData>(
			getUserUrl(`/profile/${id}/`),
			data
		)
		return response
	},

	async updatePartUser(id: number, data: IUserUpdate) {
		const response = await axiosInstance.patch<IUserUpdate>(
			getUserUrl(`/profile/${id}/`),
			data
		)
		return response
	},

	async deleteUser(id: number) {
		const response = await axiosInstance.delete<string>(
			getUserUrl(`/profile/${id}/`)
		)
		return response
	}
}

export default UserService

import { useMutation } from '@tanstack/react-query'
import { saveTokensStorage } from 'shared/services/auth/auth.helpers'
import AuthService from 'shared/services/auth/auth.service'
import { IDataLogin } from 'shared/services/types/auth.types'

export const useLogin = () => {
	const { isPending, isError, mutate, mutateAsync, isSuccess, data } =
		useMutation({
			mutationFn: (data: IDataLogin) => AuthService.login(data),
			mutationKey: ['login'],
			onSuccess: data => {
				saveTokensStorage(data.data)
			}
		})
	const loginUser = async (data: IDataLogin, callback?: () => void) => {
		mutateAsync(data)
			.then(res => {
				callback?.()
				return res
			})
			.catch(error => {
				return error
			})
	}
	return { isPending, isError, mutate, loginUser, isSuccess, data, mutateAsync }
}

import { FC, MutableRefObject, RefObject, useRef, useState } from 'react'
import './AddDistributorPopup.scss'
import { useHelpAction } from 'features/help/api/useHelp'
import { useAlertMessage } from 'shared/hooks'

interface IProps {
	isOpen: boolean
	setIsOpen: any
}

export const AddDistributorPopup: FC<IProps> = ({ isOpen, setIsOpen }) => {
	const { addNewDistributer } = useHelpAction()
	const { addAlertMessage } = useAlertMessage()
	const [response, setResponse] = useState<string | null>(null)

	const nameRef = useRef<HTMLInputElement>(null)
	const urlRef = useRef<HTMLInputElement>(null)
	const messageRef = useRef<HTMLTextAreaElement>(null)

	const handlePopupClose = () => {
		setIsOpen(false)
		setResponse(null)
	}

	const handleInputChange = (
		inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement> | null
	) => {
		if (!inputRef) return
		const inputValue = inputRef?.current?.value
		inputRef?.current?.classList.toggle('active', inputValue !== '')
	}

	const sendAddDistributor = async () => {
		const distributorData = {
			name: nameRef.current!.value!,
			site_url: urlRef.current!.value,
			message: messageRef.current!.value
		}
		try {
			const { data } = await addNewDistributer.mutateAsync(distributorData)
			setResponse(data)
		} catch (error: any) {
			addAlertMessage(error, 'error')
			setResponse(null)
		}
	}
	return (
		<>
			<div
				className={`modal add-distributor-modal ${isOpen ? 'show' : 'hide'}`}
			>
				<div className='modal__window'>
					<div className='modal__header'>
						<p>Запрос на добавление дистрибьютора</p>
						<button
							type='button'
							className='modal__close'
							onClick={handlePopupClose}
						>
							<svg
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</button>
					</div>
					{response ? (
						<p
							style={{ paddingLeft: '4%', color: '#8A7DE7', fontWeight: '500' }}
						>
							{response}
						</p>
					) : (
						<> </>
					)}

					<div className='modal__form'>
						<div className='input-block'>
							<input
								ref={nameRef}
								type='text'
								required
								onChange={() => handleInputChange(nameRef)}
							/>
							<div className='input-placeholder'>
								Название юридического лица<span>*</span>
							</div>
						</div>
						<div className='input-block'>
							<input
								ref={urlRef}
								type='text'
								required
								onChange={() => handleInputChange(urlRef)}
							/>
							<div className='input-placeholder'>
								Сайт<span>*</span>
							</div>
						</div>
						<div className='textarea-block'>
							<textarea
								ref={messageRef}
								onChange={() => handleInputChange(messageRef)}
							/>
							<div className='input-placeholder'>Сообщение</div>
						</div>
						<button type='button' className='btn' onClick={sendAddDistributor}>
							Отправить
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

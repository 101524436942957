import { useQuery, useQueryClient } from '@tanstack/react-query'
import SupplierService from 'shared/services/supplier.service'
import { ISupplierFilter } from 'shared/services/types/supplier.types'

export const useGetManufactures = (filter?: ISupplierFilter) => {
	return useQuery({
		queryFn: () => SupplierService.getManufacturers(filter),
		queryKey: ['get manufactures list', filter],
		select: ({ data }) => data
	})
}

export const useGetManufactureById = (id: number) => {
	const queryClient = useQueryClient()

	return useQuery({
		queryFn: () => SupplierService.getManufacturerById(id),
		queryKey: ['get distributor', id],
		select: ({ data }) => data,
		retry: 0
	})
}

import React, { FC, useState } from 'react'
import {
	EmployeesChangeIcon,
	EmployeesDeleteIcon,
	EmployeesUserIcon
} from '../../../../assets/EmployeesIcon'
import { ChangeUserPopup, DeleteUserPopup } from '../popup-profile'
import { tokenParse } from 'shared/utils'
import { IUserUpdate } from 'shared/services/types/user.types'

interface IProps {
	data: IUserUpdate
}

const EmployeeComponent: FC<IProps> = ({ data }) => {
	const { is_company_owner } = tokenParse()
	const [open, setOpen] = useState(false)
	const [open1, setOpen1] = useState(false)
	return (
		<>
			<div className='employees-list-user'>
				<div className='employees-list-user-info'>
					<EmployeesUserIcon />
					<div>
						<p>
							{data.last_name + ' ' + data.first_name}
							<span className='indicator online'></span>
						</p>
						<p>{data.email}</p>
					</div>
				</div>
				<div
					style={{ display: is_company_owner ? '' : 'none' }}
					className='employees-list-user-icons'
				>
					<button
						type='button'
						disabled={!is_company_owner}
						onClick={() => setOpen(!open)}
					>
						<EmployeesChangeIcon />
					</button>
					<button
						type='button'
						disabled={!is_company_owner}
						onClick={() => setOpen1(!open1)}
					>
						<EmployeesDeleteIcon />
					</button>
				</div>
			</div>
			<ChangeUserPopup
				isOpen={open}
				setIsOpen={setOpen}
				id={data.id}
				fio={`${data.last_name || ''} ${data.first_name || ''}`}
				email={data.email}
			/>
			<DeleteUserPopup isOpen={open1} setIsOpen={setOpen1} id={data.id} />
		</>
	)
}

export default EmployeeComponent

import React, { useEffect, useState } from 'react'
import './RequestSuppliers.scss'
import DeleteRequest from 'entities/suppliers/delete-request'
import DealTookPlace from 'features/suppliers/ui/deal-took-place'
import { usePartnersList } from 'features/partners/api/usePartnersList'
import { CardRequest } from 'features/suppliers/ui/card-request'
import clsx from 'clsx'
import { IRequestToPartner } from 'shared/services/types/partner.types'

const RequestSuppliers = () => {
	const [currentRequest, setCurrentRequest] = useState<IRequestToPartner>()
	const [archiveVisible, setArchiveVisible] = useState(true)
	const { requestList } = usePartnersList()
	const [archiveList, setArchiveList] = useState<IRequestToPartner[]>([])
	const [requestsList, setRequestsList] = useState<IRequestToPartner[]>([])
	const [isModalVisible, setModalVisible] = useState(false)
	const [isModalVisibleDealTookPlace, setModalVisibleDealTookPlace] =
		useState(false)

	const onToggleVisibleArchive = () => {
		setArchiveVisible(prev => !prev)
	}
	const openModal = (data: IRequestToPartner) => {
		setCurrentRequest(data)
		setModalVisible(true)
	}

	const closeModal = () => {
		setModalVisible(false)
	}

	const openModalDealTookPlace = (data: IRequestToPartner) => {
		setCurrentRequest(data)
		setModalVisibleDealTookPlace(true)
	}

	const closeModalDealTookPlace = () => {
		setModalVisibleDealTookPlace(false)
	}

	useEffect(() => {
		const archive = requestList.data?.filter(item => item.archived)
		const requestsList = requestList.data?.filter(item => !item.archived)
		setArchiveList(archive || [])
		setRequestsList(requestsList || [])
	}, [requestList.data])

	useEffect(() => {
		requestList.refetch()
	}, [])

	return (
		<div className='requests-suppliers'>
			{true && (
				<div className='profile-overlay'>
					<div className='profile-overlay-content'>
						<p>
							<>Раздел находится в разработке</>
						</p>
					</div>
				</div>
			)}
			<h1>Запросы в работе:</h1>
			<div className='requests-suppliers__list'>
				{!requestList.isPending && !requestList.data?.length && (
					<>Нет обращений</>
				)}
				{requestsList?.map(item => (
					<CardRequest
						key={item.id}
						data={item}
						onCloseRequest={openModal}
						onDealTookPlace={openModalDealTookPlace}
					/>
				))}
				{!!archiveList.length && (
					<div
						className={clsx(
							`request-supplier__archive-btn`,
							archiveVisible && 'active'
						)}
					>
						<button onClick={onToggleVisibleArchive}>
							Архив
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='18'
								viewBox='0 0 19 18'
								fill='none'
							>
								<path
									d='M14.75 8.75L9.5 13.25L4.25 8.75'
									stroke='#333333'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</button>
					</div>
				)}
				{archiveVisible &&
					archiveList?.map(item => (
						<CardRequest
							data={item}
							key={item.id}
							onCloseRequest={openModal}
							onDealTookPlace={openModalDealTookPlace}
						/>
					))}
			</div>
			<DeleteRequest
				isOpen={isModalVisible}
				onClose={closeModal}
				data={currentRequest!}
			/>
			<DealTookPlace
				data={currentRequest}
				isOpen={isModalVisibleDealTookPlace}
				onClose={closeModalDealTookPlace}
			/>
		</div>
	)
}

export default RequestSuppliers

import { useEffect, useState } from 'react'
import './ProfileInfo.scss'
import Dropdown from 'shared/ui/dropdown'
import 'assets/input.scss'
import 'assets/Numbers'
import { FirstNumber, SecondNumber, ThirdNumber } from 'assets/Numbers'
import ProfileInfoUser from '../user'
import ProfileInfoCompany from '../company'
import ProfileInfoAdditional from '../additional'
import { HelpIcon } from 'assets/ProfileLogos'
import HelpModal from '../../../../help/ui/help-modal'
import { useGetCompany } from 'features/company/api/useGetCompany'
import { ICompanyResponse } from 'shared/services/types/company.types'

const ProfileInfo = () => {
	const [companyInfo, setCompanyInfo] = useState<ICompanyResponse | null>(null)
	const { companyData } = useGetCompany()

	useEffect(() => {
		if (companyData) setCompanyInfo(companyData)
	}, [companyData])

	const [isModalVisible, setModalVisible] = useState(false)

	const openModal = () => {
		setModalVisible(true)
	}

	const closeModal = () => {
		setModalVisible(false)
	}

	return (
		<>
			<div className='profile-container'>
				<div className='warning-text'>
					<p>
						Пожалуйста, вводите достоверные данные, которые, при необходимости,
						сможете <br /> подтвердить документально
					</p>
					<p>
						<strong style={{ color: 'red' }}>*</strong> - обязательное поле для
						заполнения
					</p>
				</div>

				<Dropdown
					title='Личные данные'
					content={<ProfileInfoUser />}
					number={<FirstNumber />}
				/>
				<Dropdown
					title='Ваша компания'
					content={<ProfileInfoCompany companyInfo={companyInfo} />}
					number={<SecondNumber />}
				/>
				<Dropdown
					title='Дополнительные вопросы'
					content={<ProfileInfoAdditional additionalInfo={companyInfo} />}
					number={<ThirdNumber />}
				/>
				<div className='info-help' onClick={openModal}>
					<p>Нужна помощь в регистрации</p>
					<HelpIcon />
				</div>
			</div>
			<HelpModal isOpen={isModalVisible} onClose={closeModal} />
		</>
	)
}

export default ProfileInfo

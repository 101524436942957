import React, { useRef, useEffect, useState, FC } from 'react'
import 'app/styles/modal.scss'
import { useGetReviews } from 'features/reviews/api/useReviewsList'
import { useAlertMessage } from 'shared/hooks'

const reasonList = [
	{
		text: `Отзыв содержит нецензурную, бранную лексику`
	},
	{
		text: `Заказной, фиктивный`
	},
	{
		text: `Спам`
	},
	{
		text: `Отзыв не по теме`
	}
]

interface IProps {
	isOpen: boolean
	onClose: () => void
	data: any
}
const ComplaintModalForm: FC<IProps> = ({ isOpen, onClose, data }) => {
	const { addAlertMessage } = useAlertMessage()
	const { addReviewComplaint } = useGetReviews()
	const [text, setText] = useState('')
	const [selectReason, setSelectReason] = useState<{ text: string }>()
	const [reason, setReason] = useState('')

	const onChangeReason = (data: { text: string }) => {
		setReason(data.text)
		setSelectReason(data)
	}

	const onSubmit = async () => {
		try {
			await addReviewComplaint.mutateAsync({ reason, review_id: data?.id || 0 })
			onClose()
		} catch (error: any) {
			addAlertMessage(error, 'error')
		}
	}

	useEffect(() => {
		if (text) setReason(text)
	}, [text])

	return (
		<div className={`modal modal-complaint ${isOpen ? 'show' : 'hide'}`}>
			<div className='modal__window'>
				<div className='modal__header'>
					<h3>Почему вы хотите пожаловаться на отзыв?</h3>
					<button className='modal__close' onClick={onClose}>
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
						</svg>
					</button>
				</div>
				<div className='modal__form'>
					<div className='radio-block'>
						{reasonList.map(item => (
							<label key={item.text} onClick={() => onChangeReason(item)}>
								<input
									type='radio'
									checked={selectReason?.text === item.text}
									name='complaint'
									disabled={!!text}
								/>
								{item.text}
							</label>
						))}
					</div>
					<div className='textarea-block'>
						<p>Введите свой вариант</p>
						<textarea
							value={text}
							onChange={e => setText(e.target.value)}
						></textarea>
					</div>
					<button className='btn' onClick={onSubmit}>
						Сообщить
					</button>
				</div>
			</div>
		</div>
	)
}

export default ComplaintModalForm

import { toFormData } from 'shared/utils'
import {
	IPriceSource,
	TPriceSourceData,
	IPriceUpdate,
	IHistorySource
} from './types/price.types'
import { axiosInstance } from 'shared/api'
import { getPriceListUrl, getStatsUrl } from 'shared/api/config'

const PricesService = {
	async getPriceSource() {
		const response = await axiosInstance.get<IPriceSource[]>(
			getPriceListUrl(`/source/`)
		)
		return response
	},

	async addPriceSource(data: TPriceSourceData) {
		const response = await axiosInstance.post<IPriceSource>(
			getPriceListUrl(`/source/`),
			data
		)
		return response
	},

	async changePriceSource(id: number, data: TPriceSourceData) {
		const response = await axiosInstance.patch<IPriceSource>(
			getPriceListUrl(`/source/${id}/`),
			data
		)
		return response
	},
	async deletePriceSource(id: number) {
		const response = await axiosInstance.delete<IPriceSource>(
			getPriceListUrl(`/source/${id}/`)
		)
		return response
	},

	async uploadPriceSource(id: number, data: IPriceUpdate) {
		const response = await axiosInstance.post<IPriceSource>(
			getPriceListUrl(`/source/${id}/upload/`),
			toFormData(data)
		)
		return response
	},

	async getHistorySource(id: number) {
		const response = await axiosInstance.get<IHistorySource[]>(
			getPriceListUrl(`/source/${id}/history/`)
		)
		return response
	},

	async uploadHistorySource(id: number, history_id: number) {
		const response = await axiosInstance.delete<IPriceSource>(
			getPriceListUrl(`/source/${id}/history/${history_id}/`)
		)
		return response
	}
}

export default PricesService

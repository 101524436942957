import React, { useRef, useEffect, useState, FC, FormEvent } from 'react'
import 'app/styles/modal.scss'
import PasswordRecoveryCodeModal from '../password-recovery-code-modal'
import { useAuthActions } from 'features/auth/api/useAuthActions'
import { useAlertMessage, useQueryParams } from 'shared/hooks'
import { tokenParse } from 'shared/utils'
import Cookies from 'js-cookie'

interface IProps {
	isOpen: boolean
	onClose: () => void
}

const PasswordRecoveryModal: FC<IProps> = ({ isOpen, onClose }) => {
	const email = useRef<HTMLInputElement>(null)
	const { addAlertMessage } = useAlertMessage()
	const { emailRecovery } = useAuthActions()

	const handleInputChange = (inputRef: React.RefObject<HTMLInputElement>) => {
		const inputValue = inputRef?.current?.value
		inputRef?.current?.classList?.toggle('active', inputValue !== '')
	}

	const [isModalVisibleCode, setModalVisibleCode] = useState(false)

	const openModalCode = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const forgot_url = `${window.location.href}/` as string
		try {
			await emailRecovery.mutateAsync({
				to_email: email?.current?.value,
				forgot_url: forgot_url
			})
			addAlertMessage('На указанную почту было выслано письмо', 'info')
			onClose()
		} catch (error: any) {
			addAlertMessage(error, 'error')
		}
	}

	const closeModalCode = () => {
		setModalVisibleCode(false)
	}

	return (
		<>
			<div className={`modal ${isOpen ? 'show' : 'hide'}`}>
				<div className='modal__window'>
					<div className='modal__header'>
						<h3>Восстановление пароля</h3>
						<button className='modal__close' onClick={onClose}>
							<svg
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</button>
					</div>
					<form className='modal__form' onSubmit={openModalCode}>
						<div className='input-block'>
							<input
								ref={email}
								type='email'
								required
								onChange={() => handleInputChange(email)}
							/>
							<div className='input-placeholder'>
								Email<span>*</span>
							</div>
						</div>

						<button type='submit' className='btn'>
							Получить код
						</button>
					</form>
				</div>
			</div>
			<PasswordRecoveryCodeModal
				isOpen={isModalVisibleCode}
				onClose={closeModalCode}
			/>
		</>
	)
}

export default PasswordRecoveryModal

import { useMutation } from '@tanstack/react-query'
import AuthService from 'shared/services/auth/auth.service'
import { IRecoveryPassword } from 'shared/services/types/auth.types'

interface IProps {
	data: IRecoveryPassword
	uidb64: string
	token: string
}

export const useRecoveryPassword = () => {
	const { isPending, isError, mutate, mutateAsync, isSuccess, data } =
		useMutation({
			mutationFn: (data: IProps) =>
				AuthService.passwordRecovery(data.data, data.uidb64, data.token),
			mutationKey: ['recovery password']
		})
	const registerUser = async (data: IProps, callback?: () => void) => {
		mutateAsync(data)
			.then(res => {
				callback?.()
				return res
			})
			.catch(error => {
				return error
			})
	}
	return {
		isPending,
		isError,
		mutate,
		registerUser,
		isSuccess,
		data,
		passwordRecoveryAsync: mutateAsync
	}
}

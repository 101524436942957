import { FC, useState } from 'react'
import 'app/styles/modal.scss'
import ReviewsModal from '../../../reviews/ui/reviews-modal'
import { usePartnersActions } from 'features/partners/api/usePartnersActions'
import { useAlertMessage } from 'shared/hooks'
import { useCheckReview } from 'features/reviews/api/useCheckReview'
import { tokenParse } from 'shared/utils'

interface IProps {
	isOpen: boolean
	onClose: () => void
	data: any
}

const DealTookPlace: FC<IProps> = ({ isOpen, onClose, data }) => {
	const { user_id } = tokenParse()

	const { data: checkReview } = useCheckReview({
		supplier_id: data?.partner_info?.id,
		supplier_type: data?.partner_info?.supplier_type,
		user_id: user_id
	})
	const id = data?.id
	const [isModalVisible, setModalVisible] = useState(false)
	const { updateInfoRequest } = usePartnersActions(id)

	const { addAlertMessage } = useAlertMessage()

	const handlerToArchive = async () => {
		try {
			await updateInfoRequest.mutateAsync({ archived: false, id: data.id })
			addAlertMessage('Запрос успешно разархивирован', 'success')
			onClose()
		} catch (error: any) {
			addAlertMessage(error, 'error')
		}
	}

	const onCompletedChange = async (completed: boolean) => {
		try {
			await updateInfoRequest.mutateAsync({
				completed: completed,
				archived: completed,
				id: id
			})


			if (checkReview) return onClose()

			openModal()
		} catch (error: any) {
			addAlertMessage(error, 'error')
		}
	}

	const changeTrade = async (completed: boolean) => {
		if (data.archived) {
			handlerToArchive()
		} else {
			onCompletedChange(completed)
		}
	}

	const openModal = () => {
		setModalVisible(true)
		onClose()
	}

	const closeModal = () => {
		setModalVisible(false)
	}

	return (
		<>
			<div className={`modal modal-remove ${isOpen ? 'show' : 'hide'}`}>
				<div className='modal__window'>
					<div className='modal__header'>
						<h3>
							{data?.archived ? 'Разархивировать?' : 'Состоялась ли сделка?'}
						</h3>
						<button className='modal__close' onClick={onClose}>
							<svg
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</button>
					</div>
					<div className='modal__btns'>
						<button
							type='button'
							className='btn'
							onClick={() => (data?.archived ? onClose() : changeTrade(false))}
						>
							Нет
						</button>
						<button
							type='button'
							className='btn'
							onClick={() => changeTrade(true)}
						>
							Да
						</button>
					</div>
				</div>
			</div>

			<ReviewsModal isOpen={isModalVisible} onClose={closeModal} data={data} />
		</>
	)
}

export default DealTookPlace

import { useMutation, useQueryClient } from '@tanstack/react-query'
import AuthService from 'shared/services/auth/auth.service'
import {
	IDataRegister,
	IForgotEmail,
	IPasswordChange,
	IRecoveryPassword
} from 'shared/services/types/auth.types'
import { ICompanyData } from 'shared/services/types/company.types'

export const useAuthActions = () => {
	const queryClient = useQueryClient()

	const emailRecovery = useMutation({
		mutationFn: (data: IForgotEmail) => AuthService.emailRecovery(data),
		mutationKey: ['emailRecovery'],
		onSuccess: ({ data }) => {
			data
		}
	})

	const passwordChange = useMutation({
		mutationFn: (data: IPasswordChange) => AuthService.passwordChange(data),
		mutationKey: ['emailRecovery'],
		onSuccess: ({ data }) => {
			data
		}
	})

	const passwordRecovery = useMutation({
		mutationFn: (data: {
			data: IRecoveryPassword
			uidb64: string
			token: string
		}) => AuthService.passwordRecovery(data.data, data.uidb64, data.token),
		mutationKey: ['passwordRecovery']
	})

	const onRegister = useMutation({
		mutationFn: (data: IDataRegister) => AuthService.register(data),
		mutationKey: ['register']
	})
	return {
		emailRecovery,
		passwordChange,
		passwordRecovery,
		onRegister
	}
}

import React from 'react'
import { Layout } from 'shared/ui/layout'
import Buyers from 'widgets/buyers'

const BuyersPage = () => {
	return (
		<Layout>
			<Buyers />
		</Layout>
	)
}

export default BuyersPage

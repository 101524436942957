import './BannerSupport.scss'
import blob1 from 'assets/blob1.svg'
import blob2 from 'assets/blob2.svg'
import blob3 from 'assets/blob3.svg'
import { Link } from 'react-router-dom'

const BannerSupport = () => {
	return (
		<div className='banner-support'>
			<div className='banner-support__block'>
				<div className='blob blob1'>
					<img src={blob1} />
				</div>
				<div className='blob blob2'>
					<img src={blob2} />
				</div>
				<div className='blob blob3'>
					<img src={blob3} />
				</div>
				<h2>Мы всегда на связи</h2>
				<h3>Нужно больше статистики? </h3>
				<p>
					Напишите нам в поддержку какие результаты вы бы хотели видеть в
					кабинете{' '}
				</p>
				<h3>Хотите добавить еще больше источников?</h3>
				<p>Свяжитесь с поддержкой, что бы узнать условия</p>
				<div className='banner-support__btn'>
					<Link to='/account/chat'>Написать в поддержку </Link>
				</div>
			</div>
		</div>
	)
}

export default BannerSupport

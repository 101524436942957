import { Link } from 'react-router-dom'
import { Layout } from 'shared/ui/layout'

const Term = () => {
	const webSite = process.env.REACT_APP_WEB_SITE || 'https://kira.pw/docs'

	return (
		<Layout>
			<div className='container'>
				<h2
					className={
						'common-page__subheader content__title-main common-page__title '
					}
				>
					Пользовательское соглашение
				</h2>
				<div className='static-info'>
					<h5 className='common-page__subheader common-page__title'>
						{' '}
						1. Термины и определения:
					</h5>
					<p>
						1.1. ПО – результат интеллектуальной деятельности, программа/база
						данных для ЭВМ «KIRA». Правообладателем ПО является ООО «Кира» (ИНН
						7716997759 ). ПО предназначено для поиска потенциальных контрагентов
						по поисковым запросам Пользователя. Подробное описание функций ПО
						указано на Сайте
					</p>
					<p>
						1.2. Пользователь – физическое лицо, зарегистрированное на Сайте и
						получившее уникальное имя пользователя (логин) и пароль (далее по
						тексту — «Учетная запись») для входа на защищенные страницы Сайта
						(далее по тексту — «Регистрация»). Пользователем может являться:
						<br /> − физическое лицо — работник Заказчика;
						<br /> − физическое лицо-Заказчик, осуществляющее
						предпринимательскую деятельность без образования юридического лица;
						<br />− физическое лицо-Заказчик, оказывающее какие-либо услуги в
						области поиска контрагентов для других юридических лиц; Заказчиком
						является лицо, заключившее с ООО «Кира» соответствующий договор на
						использование ПО либо использующее ПО в рамках тестового периода.
						Настоящие Условия устанавливают права и обязанности как между ООО
						«Кира» и Пользователем, так и между ООО «Кира» и Заказчиком.
						Обязательства Пользователя, установленные настоящими Условиями,
						являются также обязательствами Заказчика перед ООО «Кира»,
						возникающими в связи с действиями Пользователей и собственными
						действиями Заказчика по использованию Сайта. Заказчик отвечает за
						действия Пользователя как за свои собственные действия. При этом,
						согласно настоящим Условиям, Пользователь не приобретает
						самостоятельных или каких-либо отдельных прав по отношению к ООО
						«Кира». Все права, предоставляемые ООО «Кира» согласно настоящим
						Условиям, возникают только непосредственно у Заказчика. Обязанности
						Заказчика, установленные настоящими Условиями, являются также
						обязанностями Пользователя.
					</p>
					<p>
						1.3 Сайт – Веб-сайт, принадлежащий ООО «Кира» и имеющий адрес в сети
						Интернет {webSite}.
					</p>
					<p>
						1.4. Тестовый доступ – доступ ко всем функциональным возможностям ПО
						на безвозмездной основе в течение 3 (трёх) дней после прохождения
						процедуры регистрации.
					</p>
					<p>
						1.5. Регистрация – заполнение и отправка любой из регистрационных
						форм на Сайте {webSite} в результате которой Заказчик получает
						Учетную запись для работы с Сайтом.
					</p>
					<h5 className='common-page__subheader common-page__title'>
						2. Обязательства Заказчика
					</h5>

					<p>
						2.1. Используя ПО/Сайт, Заказчик обязуется не совершать следующих
						действий:
					</p>
					<p>
						2.2. Любым способом посредством Сайта размещать, распространять,
						сохранять, загружать и/или уничтожать материалы (информацию) в
						нарушение законодательства РФ и международного законодательства.
					</p>
					<p>
						2.3. Размещать и/или передавать посредством Сайта информацию в виде
						текста, изображения, видео, звука или программного кода, которая
						может быть противозаконной, угрожающей, оскорбительной,
						клеветнической, заведомо ложной, грубой, непристойной, вредить
						другим посетителям Сайта, нарушать их права.
					</p>
					<p>
						2.4. Модифицировать исходный код ПО; внешний вид пользовательских
						интерфейсов ПО.
					</p>
					<p>
						2.5. Размещать заведомо недостоверную информацию о себе, своей
						компании, использовать при регистрации на Сайте вымышленные
						наименования юридических лиц (указывать наименования несуществующих
						юридических лиц) и вымышленные имена физических лиц,
						незарегистрированные в установленном порядке товарные знаки, а равно
						наименования юридических лиц, имена физических лиц и товарные знаки,
						права, на использование которых отсутствуют у Заказчика.
					</p>
					<p>
						2.6. Размещать и/или передавать, используя Сайт, контент (включая,
						но не ограничивая: информацию, материалы, файлы и т.п.) в случае,
						если Пользователь не имеет на это необходимого объема прав. Данное
						правило касается любого контента, содержащего какие-либо
						произведения, товарные знаки и знаки обслуживания, фирменные
						наименования, патентную и конфиденциальную информацию, информацию,
						составляющую чью-либо коммерческую тайну, иные охраняемые результаты
						интеллектуальной деятельности, и тому подобное.
					</p>
					<p>
						2.7. Уничтожать и/или изменять любые материалы на Сайте,
						правообладателем которых Заказчик не является.
					</p>
					<p>
						2.8. Использовать информацию о телефонах, почтовых адресах, адресах
						электронной почты для целей иных, нежели тематика Сайта.
					</p>
					<p>
						2.9. Предлагать Пользователям Сайта, данные которых размещены на
						Сайте, размещать их данные на ином сайте или сайтах, предоставляющих
						сервисы (услуги), аналогичные Сайту.
					</p>
					<p>
						2.10. Производить просмотр страниц Сайта, содержащих информацию о
						потенциальных контрагентах, по адресам таких страниц, сформированным
						Сайтом для других Заказчиков.
					</p>
					<p>
						2.11. Предоставлять (а равно передавать) гипертекстовые ссылки на
						страницы Сайта, другим Заказчикам или их Пользователям и\или третьим
						лицам.
					</p>
					<p>
						2.12. Заказчик, предоставляя ООО «Кира» информацию/материалы к
						размещению на Сайте, либо размещая информацию/материалы на сайте
						самостоятельно, в случае наличия такой технической возможности,
						гарантирует соблюдение им требований действующего законодательства
						РФ, включая Федеральный закон от 13.03.2006 N 38-ФЗ «О рекламе». В
						случае привлечения ООО «Кира» к ответственности за нарушение
						действующего законодательства РФ (включая Федеральный закон от
						13.03.2006 N 38-ФЗ «О рекламе») в связи с размещением Заказчиком
						информации/материалов на Сайте или размещением ООО «Кира»
						информации/материалов на Сайте, предоставленных ему Заказчиком,
						Заказчик возмещает ООО «Кира» все понесенные ею расходы, включая, но
						не ограничивая: штрафы, судебные расходы и прочее, в течение 10
						календарных дней с момента предъявления соответствующего требования
						ООО «Кира» в адрес Заказчика.
					</p>
					<p>
						2.13. Нарушать (пытаться нарушать) информационную безопасность
						Сайта, в том числе: <br /> - получать доступ к данным на Сайте, не
						предназначенным для данного Заказчика; <br /> - использовать Учетную
						запись для работы с Сайтом, не принадлежащем данному Заказчику;{' '}
						<br /> проверять уязвимость системы безопасности Сайта, нарушать
						процедуры регистрации и авторизации без разрешения Сайта; <br /> -{' '}
						создавать помехи в использовании Сайта другим пользователям, что
						включает в себя, в частности, распространение компьютерных вирусов,
						порчу данных, постоянную рассылку повторяющейся информации,
						пересылку электронной почты через сервер Сайта, одновременную
						отправку большого количества электронной почты с целью намеренно
						вывести сервер сайта из строя и тому подобные действия, выходящие за
						рамки нормального целевого использования Сайта, и способные повлечь
						сбои в его работе; <br /> - рассылать пользователям, и посетителям
						Сайта «спама», писем, содержащих информацию рекламного характера,
						иных материалов, на которые пользователи Сайта не давали своего
						согласия; <br /> - использовать или пытаться использовать любое
						программное обеспечение для работы с Сайтом или для поиска на Сайте,
						кроме встроенной в Сайт поисковой машины, программного обеспечения
						самого Сайта или традиционных и общедоступных браузеров (Microsoft
						Explorer, Netscape Navigator, Opera и других подобных). <br /> -
						использовать программные средства, имитирующие работу пользователя
						на Сайте; <br /> - использование анонимных прокси-серверов; <br /> -
						работать с Сайтом посредством IP-адресов, не принадлежащих
						Заказчику; <br /> - работать с Сайтом посредством «Удаленного
						Рабочего стола» (Remote Desktop Control); <br /> - использовать
						функций парсинга/программ парсинга.
					</p>
					<h5 className='common-page__subheader common-page__title'>
						3. Обязательства ООО «Кира».
					</h5>
					<p>
						3.1. В случае наличия сомнений относительно правомерности
						использования Пользователями (а равно самим Заказчиком) Сайта, ООО
						«Кира» вправе в любое время и без предварительного уведомления
						Заказчика по своему усмотрению удалить, блокировать или
						принудительно изменить Учетную запись таких Пользователей.
					</p>
					<p>
						3.2. В случае наличия сведений об использовании Учетной записи
						Пользователя другими пользователями Сайта или какими-либо третьими
						лицами, ООО «Кира» вправе в любое время и без предварительного
						уведомления Заказчика, других пользователей Сайта и третьих лиц, по
						своему усмотрению удалить, блокировать или принудительно изменить
						такую Учетную запись Пользователя, а также учетную информацию других
						пользователей Сайта (третьих лиц), на ЭВМ и прочих аппаратных
						средствах которых, происходило использование блокируемой Учетной
						записи Пользователя.
					</p>
					<p>
						3.3. ООО «Кира» не несет ответственности за неисполнение или
						ненадлежащее исполнение своих обязательств, а также возможный ущерб,
						возникший в результате: (а) неправомерных действий пользователей
						сети Интернет, направленных на нарушения информационной безопасности
						или нормального функционирования Сайта; (б) сбоев в работе Сайта,
						вызванных в том числе, ошибками в коде, компьютерными вирусами и
						иными посторонними фрагментами кода в программном обеспечении Сайта;
						(в) отсутствия (невозможности установления, прекращения и пр.)
						Интернет-соединений между сервером Пользователя (его работников) и
						сервером Сайта; (г) проведения государственными и муниципальными
						органами, а также иными организациями мероприятий в рамках Системы
						оперативно-розыскных мероприятий (СОРМ); (д) установления
						государственного регулирования (или регулирования иными
						организациями) хозяйственной деятельности коммерческих организаций в
						сети Интернет и/или установления указанными субъектами разовых
						ограничений, затрудняющих или делающих невозможным исполнение своих
						обязательств ООО «Кира»; (е) других случаев, связанных с действиями
						(бездействием) пользователей Интернета и/или других субъектов,
						направленными на ухудшение общей ситуации с использованием сети
						Интернет и/или компьютерного оборудования, существовавшей на момент
						заключения Договора.
					</p>
					<p>
						3.4. Настоящим Пользователь выражает свое согласие на получение от
						ООО «Кира» по адресу электронной почты и/или номеру телефона,
						указанным Пользователем при регистрации на Сайте информационных
						сообщений о событиях в ООО «Кира» и Услугах ООО «Кира», а также
						любую иную информацию, включая рекламу.
					</p>
					<p>
						3.5. ООО «Кира» вправе в любое время, в том числе без какого-либо
						предварительного уведомления Заказчика, приостанавливать работу
						Сайта для проведения профилактических работ, по возможности в ночное
						время или выходные дни.
					</p>

					<h5 className='common-page__subheader common-page__title'>
						4. Обработка персональных данных
					</h5>
					<p>
						4. Обработка персональных данных 4.1. Принимая условия настоящего
						Соглашения Пользователь дает согласие на сбор и обработку его
						персональных данных в целях исполнения условий настоящего Соглашения
						ООО «Кира» и (или) партнерами ООО «Кира» по поручению ООО «Кира», а
						также в целях установления с Пользователем обратной связи, оказания
						консультаций и технической поддержки при использовании ПО,
						направления уведомлений, информирования Пользователя о проходящих
						акциях, предложениях, продуктах и услугах ООО «Кира» и (или)
						партнеров ООО «Кира».
					</p>
					<p>
						4.2. Обработка информации о Пользователе осуществляется, в том
						числе, в соответствии с{' '}
						<Link to='/privacy_policy'>
							Политикой конфиденциальности персональных данных
						</Link>{' '}
						.
					</p>
					<p>
						4.3. Пользователь, давая свое согласие на обработку персональных
						данных, подтверждает, что действует свободно, своей волей и в своем
						интересе.
					</p>
					<p>
						4.4. Пользователь дает согласие ООО «Кира» на осуществление
						следующих действий (операций) или совокупность действий (операций),
						совершаемых с использованием средств автоматизации или без
						использования таких средств с персональными данными: сбор, запись,
						систематизацию, накопление, хранение, уточнение (обновление,
						изменение), извлечение, использование, обезличивание, блокирование,
						удаление, уничтожение персональных данных, а также передачу
						персональных данных партнерам ООО «Кира» в установленном действующим
						законодательством Российской Федерации порядке, при условии
						обеспечения ими безопасности персональных данных при их обработке и
						предотвращения разглашения персональных данных.
					</p>
					<p>
						4.5. Сбор персональных данных осуществляется через заполнение
						Пользователем регистрационных форм и (или) других форм отправки
						данных на Сайте: {webSite}, а также в результате внесения или
						изменения данных в настройках системы.
					</p>
					<p>
						4.6. Перечень персональных данных, в отношении которых дано
						настоящее согласие: название компании (наименование юридического
						лица); фамилия, имя, отчество; адрес электронной почты; номер
						телефона; ИНН; фотография; автоматически передаваемые данные при
						посещении Сайта (IP-адрес, Cookie, информация о браузере, реферер).
					</p>
					<p>4.7. Согласие дается на период действия настоящего Соглашения.</p>
					<p>
						4.8. Согласие на обработку персональных данных может быть отозвано
						Пользователем в соответствии с пунктом 2 статьи 9 Федерального
						закона от 27.07.2006 № 152-ФЗ «О персональных данных».
					</p>
					<p>
						4.9. ООО «Кира» гарантирует, что при обработке персональных данных
						ей приняты необходимые организационные и технические меры для защиты
						персональных данных от неправомерного или случайного доступа к ним,
						уничтожения, изменения, блокирования, копирования, предоставления,
						распространения персональных данных, а также от иных неправомерных
						действий в отношении персональных данных.
					</p>

					<h5 className='common-page__subheader common-page__title'>
						5. Прочие положения
					</h5>
					<p>
						5.1. Каждый Пользователь Сайта, Заказчик, юридическое или физическое
						лицо самостоятельно отвечает за информацию, размещаемую от его
						имени, и за последствия этого размещения. Настоящим вышеупомянутые
						лица подтверждают, что информация, размещаемая ими на Сайте о
						товарах/услугах является достоверной, не нарушает права третьих лиц,
						товары, предлагаемые к поставке, являются оригинальными.
					</p>
					<p>
						5.2. Платный доступ к ПО регулируется договором-офертой,
						опубликованной на Сайте.
					</p>
					<p>
						5.3. Если какое-либо из условий или положений Соглашения будет
						признано любым судом или органом административной власти
						компетентной юрисдикции недействительным или неисполнимым, это не
						повлияет на действительность или возможность принудительного
						исполнения любых других положений или условий Соглашения, которые
						остаются после этого полностью действительными, если иное не
						предусмотрено законодательством Российской Федерации.
					</p>
					<p>
						5.4. Все, что не урегулировано настоящим Соглашением, регулируется в
						соответствии с законодательством Российской Федерации.
					</p>
					<p>
						5.5. В настоящие Условия могут вноситься изменения и дополнения в
						любое время по усмотрению ООО «Кира». В таком случае изменения и
						дополнения вступают в силу с момента их опубликования на Сайте —
						{webSite}.
					</p>
				</div>
			</div>
		</Layout>
	)
}

export default Term

import { FC } from 'react'
import 'app/styles/modal.scss'
import { Link } from 'react-router-dom'
import { useOutsideClick } from 'shared/hooks'

interface IProps {
	isOpen: boolean
	onClose: () => void
	data: string[]
	title?: string
}

export const PartnerPermissionModal: FC<IProps> = ({
	isOpen,
	onClose,
	title,
	data
}) => {
	const { ref } = useOutsideClick(onClose)
	return (
		<>
			<div className={`modal modal-complaint ${isOpen ? 'show' : 'hide'}`}>
				<div className='modal__window' ref={ref}>
					<div className='modal__header'>
						<h3>{title}</h3>
						<button className='modal__close' onClick={onClose}>
							<svg
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</button>
					</div>
					<div className='modal__text partner-modal'>
						<p>
							{data.map(item => (
								<div>-{item}</div>
							))}
						</p>
						<button type='button' className='btn' onClick={onClose}>
							<Link to={'/account/tariffs'}>Тарифы и оплата</Link>
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

import {
	useInfiniteQuery,
	useMutation, useQueryClient
} from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import SupplierService from 'shared/services/supplier.service'
import {
	IReviewComplaint, IReviewsData,
	IReviewsFilter
} from 'shared/services/types/supplier.types'

export const useGetReviews = (filter?: IReviewsFilter) => {
	const queryClient = useQueryClient()

	const [dataList, setDataList] = useState<any | null>(null)

	const reviewsList = useInfiniteQuery({
		queryKey: ['get reviews list', filter],
		initialPageParam: 1,
		enabled: !!filter?.supplier_id,
		queryFn: ({ pageParam = 1 }) =>
			SupplierService.getReviews({ ...filter!, page: pageParam }),
		getNextPageParam: (lastPage, allPages) => allPages.length + 1,
		retry: 0,
		select: data => {
			const res = data.pages.map(item => ({
				...item.data
			}))

			return { ...data, pages: res }
		}
	})

	useEffect(() => {
		const { data, isPending } = reviewsList

		const result = data?.pages.reduce((acc, page) => {
			//@ts-ignore

			return {
				...page,
				//@ts-ignore
				main_info: page?.results?.main_info,
				//@ts-ignore
				results: [...(acc?.results || []), ...(page?.results?.reviews || [])]
			}
		}, {} as any)

		if (!isPending) setDataList(result)
	}, [reviewsList.data?.pages, reviewsList.isPending])

	const addReview = useMutation({
		mutationFn: (data: IReviewsData) => SupplierService.addReviews(data),
		mutationKey: ['add review'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get reviews list`] })
		}
	})

	const addReviewComplaint = useMutation({
		mutationFn: (data: IReviewComplaint) =>
			SupplierService.addReviewsComplaint(data),
		mutationKey: ['add complaint'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get reviews list`] })
		}
	})

	return {
		addReviewComplaint,
		addReview,
		reviewsList: {
			...reviewsList,
			data: { ...reviewsList.data, ...dataList }
		}
	}
}

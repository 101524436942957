import { axiosInstance } from 'shared/api'
import { getPartnerUrl, getProjectUrl } from 'shared/api/config'
import {
	IPartnerResponse,
	IPartnerData,
	IPartnerFileData,
	IPartnerFile,
	IPartnerFilter,
	IRequestToPartner,
	IRequestToPartnerParams,
	IRequestToPartnerComment,
	TPartnerUpdate,
	IRequestToPartnerDetail,
	IRequestToPartnerUpdate
} from './types/partner.types'
import { toFormData } from 'axios'

const PartnerService = {
	async getInfo(id?: number) {
		const response = await axiosInstance.get<IPartnerResponse>(
			getPartnerUrl(`/`),
			{ params: { id } }
		)
		return response
	},

	async becomePartner(data: IPartnerData) {
		const response = await axiosInstance.post<IPartnerData>(
			getPartnerUrl(`/`),
			data
		)
		return response
	},

	async updatePartner(data: IPartnerData) {
		const response = await axiosInstance.put<IPartnerFileData>(
			getPartnerUrl(`/`),
			data
		)
		return response
	},

	async updatePartPartner(data: TPartnerUpdate) {
		const response = await axiosInstance.patch<IPartnerFileData>(
			getPartnerUrl(`/`),
			data
		)
		return response
	},

	async addFile(data: IPartnerFile) {
		const response = await axiosInstance.post<IPartnerFileData>(
			getPartnerUrl(`/add-file/`),
			toFormData(data)
		)
		return response
	},

	async addLogo(logo: File) {
		const response = await axiosInstance.put<IPartnerFileData>(
			getPartnerUrl(`/add-logo/`),
			toFormData({ logo })
		)
		return response
	},

	async getPartnersList(data?: IPartnerFilter) {
		const currentData = Object.entries(data || {}).reduce(
			(acc, [key, value]) => {
				if (!value) return acc
				if (Array.isArray(value)) return { ...acc, [key]: value.join(',') }
				return { ...acc, [key]: value }
			},
			{}
		)

		const response = await axiosInstance.get<IPartnerFileData>(
			getPartnerUrl(`/all/`),
			{ params: currentData }
		)
		return response
	},

	async getRequestPartners() {
		const response = await axiosInstance.get<IRequestToPartner[]>(
			getPartnerUrl(`/requests/`)
		)
		return response
	},

	async sendRequestPartners(data: IRequestToPartnerParams) {
		const response = await axiosInstance.post<string>(
			getPartnerUrl(`/requests/`),
			toFormData(data)
		)
		return response
	},

	async addCommentRequest(data: IRequestToPartnerComment) {
		const response = await axiosInstance.post<IRequestToPartner>(
			getPartnerUrl(`/requests/comment/`),
			toFormData(data)
		)
		return response
	},

	async deleteCommentRequest(id: number) {
		const response = await axiosInstance.delete<string>(
			getPartnerUrl(`/requests/comment/${id}/`)
		)
		return response
	},

	async getInfoRequest(id: number) {
		const response = await axiosInstance.get<string>(
			getPartnerUrl(`/requests/${id}/`)
		)
		return response
	},
	async updateInfoRequest(id: number, data: IRequestToPartnerUpdate) {
		const response = await axiosInstance.patch<IRequestToPartnerUpdate>(
			getPartnerUrl(`/requests/${id}/`),
			toFormData(data)
		)
		return response
	},

	async deleteInfoRequest(id: number) {
		const response = await axiosInstance.delete<string>(
			getPartnerUrl(`/requests/${id}/`)
		)
		return response
	},

	async pickPartner(products?: number[]) {
		const productsIds = products?.join(',')

		const response = await axiosInstance.get<string>(
			getProjectUrl(`/pick/partners/`),
			{ params: { products: productsIds } }
		)
		return response
	}
}

export default PartnerService

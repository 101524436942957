import React, { useRef, useEffect, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import './ProfileChat.scss'
import { ReadIcon, SendIcon } from 'assets/ChatIcons'
import FileUploadButton from 'shared/ui/form/button-file'
import { ADMIN_ID, chat_socket_url } from 'shared/api/config'
import Cookies from 'js-cookie'
import { useChat } from 'features/chat/api/useChat'
import { useAlertMessage } from 'shared/hooks'

const imageExtension = [
	'blp',
	'bmp',
	'dib',
	'bufr',
	'cur',
	'pcx',
	'dcx',
	'dds',
	'ps',
	'eps',
	'fit',
	'fits',
	'fli',
	'flc',
	'ftc',
	'ftu',
	'gbr',
	'gif',
	'grib',
	'h5',
	'hdf',
	'png',
	'apng',
	'jp2',
	'j2k',
	'jpc',
	'jpf',
	'jpx',
	'j2c',
	'icns',
	'ico',
	'im',
	'iim',
	'tif',
	'tiff',
	'jfif',
	'jpe',
	'jpg',
	'jpeg',
	'mpg',
	'mpeg',
	'mpo',
	'msp',
	'palm',
	'pcd',
	'pdf',
	'pxr',
	'pbm',
	'pgm',
	'ppm',
	'pnm',
	'psd',
	'bw',
	'rgb',
	'rgba',
	'sgi',
	'ras',
	'tga',
	'icb',
	'vda',
	'vst',
	'webp',
	'wmf',
	'emf',
	'xbm',
	'xpm'
]

const ProfileAdminChat = () => {
	const accessToken =
		localStorage.getItem('access') || Cookies.get('kira_token')
	const messageRef = useRef(null)
	const [chatHistory, setChatHistory] = useState([])

	const ref = useRef()

	const { addAlertMessage } = useAlertMessage()

	const [chatFile, setChatFile] = useState(null)
	const [usersData, setUsersData] = useState()
	const [selectedUser, setSelectedUser] = useState(null)

	const { getMessages, getAttachFile, getAllDialogs } = useChat({
		receiver: selectedUser,
		page: 1,
		page_size: 100
	})

	const handleFileUpload = file => {
		setChatFile(file)
	}

	const usersRequest = async () => {
		const { data, refetch } = getAllDialogs
		refetch()
		if (!data) return null
		setUsersData(data)
	}
	useEffect(() => {
		usersRequest()
	}, [getAllDialogs.data])

	const handleSelectUserData = event => {
		setSelectedUser(event.target.value)
	}

	const getDate = data => {
		let date = data.split('T')
		let date2 = date[1].split('.')
		let date3 = date2[0]
		let date4 = date3.split(':')
		let date5 = date4[0] + ':' + date4[1]
		return date5
	}

	const getChatHistory = async () => {
		const { data, error, refetch } = getMessages
		// refetch()
		if (error && error?.response?.status === 403) {
			console.log({ error })
		} else if (data?.results) {
			const result = [...(data?.results || [])]?.reverse()
			setChatHistory(result)
		}
	}

	useEffect(() => {
		getChatHistory()
	}, [getMessages.data, getMessages.error, selectedUser])

	const { sendJsonMessage, lastMessage } = useWebSocket(
		`${chat_socket_url}/ws/chat/${
			selectedUser ? selectedUser : 1
		}/?kira_token=${accessToken}`,

		{
			onOpen: () => {
				console.log('Connected!')
				sendJsonMessage({
					type: 'read_messages'
				})
			},
			onClose: () => {
				console.log('Disconnected!')
			},
			onMessage: e => {
				const data = JSON.parse(e.data)
				if (data?.message?.sender_id !== 1) {
					getChatHistory()
				}
			}
		}
	)

	const scrollToBottom = () => {
		ref.current.scrollTo({
			top: ref.current.scrollHeight + 1000
		})
	}

	useEffect(() => {
		scrollToBottom()
	}, [chatHistory])

	useEffect(() => {
		if (lastMessage?.data) {
			const wsData = JSON.parse(lastMessage.data)
			setChatHistory(prev => [...prev, wsData.message])
		}
		if (!chatHistory.length) {
			sendFirstFile()
		}
	}, [lastMessage])

	const sendFirstFile = async () => {
		try {
			if (chatFile) {
				const array = [...chatHistory].at(-1)
				const number = messageRef.current.value ? 1 : 0
				const id = array ? array.id + number : number
				const extraData = !messageRef.current.value
					? { receiver: selectedUser, sender: ADMIN_ID }
					: { message_id: id }

				await getAttachFile.mutateAsync({
					file: chatFile,
					...extraData
				})
				getMessages.refetch()
			}
			messageRef.current.value = ''
			setChatFile(null)
		} catch (error) {
			addAlertMessage(error, 'error')
			setChatFile(null)
			messageRef.current.value = ''
		}
	}

	const sendChatMessage = async () => {
		try {
			if (messageRef.current.value) {
				sendJsonMessage({
					type: 'new_message',
					content: messageRef.current.value
				})
			}
			await sendFirstFile()
		} catch (error) {
			addAlertMessage(error, 'error')
			setChatFile(null)
			messageRef.current.value = ''
		}
	}

	return (
		<div className='chat'>
			<div className='chat-header'>
				<h1>Панель админа для чата</h1>
				{usersData ? (
					<select onChange={handleSelectUserData} value={selectedUser}>
						{usersData.map(i => {
							return (
								<option key={i.id} value={i.id}>
									{i.last_name + ' ' + i.first_name}
								</option>
							)
						})}
					</select>
				) : (
					<></>
				)}
			</div>
			<div className='chat-content' ref={ref}>
				{chatHistory ? (
					chatHistory.map(i => {
						if (!i) return null
						const date3 = getDate(i.sent_at)
						let extension = null
						if (i.file) {
							let data = i.file.name.split('.')
							extension = data.at(-1)
						}

						return (
							<>
								{!!i.content && (
									<div
										className={`message ${
											i.sender_id !== ADMIN_ID ? 'admin' : 'sender'
										}`}
									>
										{i.sender_id !== ADMIN_ID ? (
											<>
												<h1>Пользователь</h1>
												<div className='message__body'>
													<p>{i.content}</p>
													<div className='message-info'>
														<p>{i.read === true ? <ReadIcon /> : ''}</p>
														<p>{date3}</p>
													</div>
												</div>
											</>
										) : (
											<>
												<div className='message__body'>
													<p>{i.content}</p>
													<div className='message-info'>
														<p>{i.read === true ? <ReadIcon /> : ''}</p>
														<p>{date3}</p>
													</div>
												</div>
											</>
										)}
									</div>
								)}

								{i.file && (
									<div
										className={`message ${
											i.sender_id !== ADMIN_ID ? 'admin' : 'sender'
										}`}
									>
										{i.sender_id !== ADMIN_ID ? (
											<>
												<h1>Пользователь</h1>
												<div className='message__body'>
													{imageExtension.includes(extension) ? (
														<a
															href={i.file.path}
															download={i.file.name}
															style={{ display: 'block' }}
															target='_blank'
														>
															<img
																src={i.file.path}
																alt={i.file.name}
																style={{ width: '100%' }}
															/>
														</a>
													) : (
														<a
															href={i.file.path}
															download={i.file.name}
															style={{ display: 'block' }}
															target='_blank'
														>
															{i.file.name}
														</a>
													)}
													<div className='message-info'>
														<p>{i.read === true ? <ReadIcon /> : ''}</p>
														<p>{date3}</p>
													</div>
												</div>
											</>
										) : (
											<>
												<div className='message__body'>
													{imageExtension.includes(extension) ? (
														<a
															href={i.file.path}
															download={i.file.name}
															style={{ display: 'block' }}
															target='_blank'
														>
															<img
																src={i.file.path}
																alt={i.file.name}
																style={{ width: '100%' }}
															/>
														</a>
													) : (
														<a
															href={i.file.path}
															download={i.file.name}
															style={{ display: 'block' }}
															target='_blank'
														>
															{i.file.name}
														</a>
													)}
													<div className='message-info'>
														<p>{i.read === true ? <ReadIcon /> : ''}</p>
														<p>{date3}</p>
													</div>
												</div>
											</>
										)}
									</div>
								)}
							</>
						)
					})
				) : (
					<></>
				)}
			</div>
			<div className='chat-footer'>
				<FileUploadButton onChange={handleFileUpload} />
				<div className='chat-input-block'>
					<div className='chat-input'>
						<form
							onSubmit={e => {
								e.preventDefault()
								sendChatMessage()
							}}
						>
							<input ref={messageRef} placeholder='Введите сообщение...' />
							<button>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='30'
									height='30'
									viewBox='0 0 30 30'
									fill='none'
								>
									<path
										d='M23.2946 19.5877L25.4402 13.151C27.3145 7.52802 28.2516 4.71653 26.7676 3.23244C25.2835 1.74836 22.472 2.68552 16.849 4.55984L10.4123 6.7054C5.87404 8.21816 3.6049 8.97455 2.96007 10.0837C2.34664 11.1389 2.34664 12.4421 2.96007 13.4973C3.6049 14.6065 5.87404 15.3629 10.4123 16.8756C10.9748 17.0631 11.6075 16.9292 12.0287 16.512L18.9107 9.69369C19.2979 9.3101 19.9227 9.31301 20.3063 9.70019C20.6899 10.0874 20.687 10.7122 20.2998 11.0958L13.5289 17.804C13.0647 18.2639 12.9177 18.9678 13.1244 19.5877C14.6371 24.1259 15.3935 26.3951 16.5027 27.0399C17.5579 27.6534 18.8611 27.6534 19.9163 27.0399C21.0254 26.3951 21.7818 24.1259 23.2946 19.5877Z'
										fill='#555555'
									/>
								</svg>
							</button>
						</form>
					</div>
					{chatFile && (
						<>
							<p style={{ marginTop: '10px', paddingLeft: '2%' }}>
								{chatFile.name}
								<span
									style={{ cursor: 'pointer' }}
									onClick={() => {
										setChatFile(null)
										messageRef.current.value = ''
									}}
								>
									<svg
										height='20'
										width='20'
										viewBox='0 0 20 20'
										aria-hidden='true'
										focusable='false'
										class='css-tj5bde-Svg'
									>
										<path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
									</svg>
								</span>
							</p>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default ProfileAdminChat

import { useRef, useState, useEffect, FC } from 'react'
import './ProfilePartnersSurvey.scss'
import { AddDistributorPopup } from '../../../../features/profile/ui/add-distributor-modal'
import { useGetCountries, useGetCurrencies } from 'features/utils/api'
import { useGetCompany } from 'features/company/api/useGetCompany'
import { usePartnersActions } from 'features/partners/api/usePartnersActions'
import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { Input } from 'shared/ui/form/input'
import { ReactSelect } from 'shared/ui/form/react-select'
import { useGetDistributer } from 'features/suppliers/api/useGetDistributer'
import { ReactSelectMulti } from 'shared/ui/form/react-select-multi'
import { useAlertMessage } from 'shared/hooks'
import { MyOptionType } from 'shared/ui/form/types'
interface IProps {
	defaultData: any
	methods: UseFormReturn<any, any, undefined>
}
const ProfilePartnersSurvey: FC<IProps> = ({ defaultData, methods }) => {
	const {
		watch,
		control,
		formState: { errors }
	} = methods
	const { addAlertMessage } = useAlertMessage()
	const { addLogo } = usePartnersActions()

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'settlements'
	})

	const { data: dataDistributors } = useGetDistributer({
		page: 1,
		page_size: 100
	})

	const { data: countries } = useGetCountries()
	const { data: currencies } = useGetCurrencies()
	const { companyData, isPending } = useGetCompany()

	const { updatePartner } = usePartnersActions()
	const [currenciesData, setCurrenciesData] = useState<MyOptionType[]>([])
	const [distributors, setDistributors] = useState<MyOptionType[]>([])

	const [logo, setLogo] = useState<any>({})

	const [isOpen, setIsOpen] = useState(false)

	const inputFileRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (defaultData) {
			setLogo({ binary: defaultData.logo })
		}
		if (defaultData?.settlements?.length || companyData?.settlement_bill) {
			const settlements = defaultData?.settlements.map((item: any) => ({
				...item,
				currency: currenciesData.find(v => v.label === item.currency)
			})) || [{ number: companyData?.settlement_bill || '', currency: '' }]

			const distributors = defaultData?.distributors.map((item: any) => ({
				label: item.name,
				value: item.id
			}))
			methods.reset({ ...methods.getValues(), settlements, distributors })
		}
	}, [defaultData, currenciesData, distributors, companyData?.settlement_bill])

	const addBill = () => {
		append({ number: '', currency: '' })
	}

	const removeBill = (id: number) => {
		remove(id)
	}

	const infoSubmit = async () => {
		methods.trigger()

		if (logo?.file) {
			addLogo.mutateAsync(logo.file).catch(err => {
				addAlertMessage(err, 'error')
			})
		}

		setTimeout(() => {
			const error = methods.formState.errors

			const value = methods.getValues()
			const settlements = value.settlements.map((item: any) => ({
				...item,
				currency: item.currency.label
			}))
			const distributors = value?.distributors?.map((item: any) => item.value)

			const dataObject = {
				settlements: settlements,
				distributors: distributors
			}
			const isError = Object.keys(dataObject).find(item => {
				return error[item]
			})

			if (isError) return null

			updatePartner.mutateAsync(dataObject).catch(err => {
				addAlertMessage(err, 'error')
			})
		}, 100)
	}

	useEffect(() => {
		if (countries && currencies) {
			const currenciesResponseData = currencies?.map(i => {
				const foundFlag = countries?.filter(k => k.id === Number(i.country_id))
				return {
					value: i.country_id,
					label: i.code,
					flag: foundFlag[0].flag
				}
			})
			setCurrenciesData(currenciesResponseData)
		}
	}, [currencies, countries])

	useEffect(() => {
		if (dataDistributors?.results) {
			const distributerList = dataDistributors.results.map(i => {
				return {
					value: i.id,
					label: i.name
				}
			})

			setDistributors(distributerList)
		}
	}, [dataDistributors])

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target?.files?.[0]

		if (file) {
			const reader = new FileReader()
			reader.onload = event => {
				//@ts-ignore
				setLogo({ file: file, binary: event?.target.result })
				methods.setValue('logo', file)
			}

			reader.readAsDataURL(file)
		}
	}

	const handleRemoveImage = () => {
		setLogo({})

		if (inputFileRef.current) {
			//@ts-ignore
			inputFileRef!.current!.value = null
		}
	}

	useEffect(() => {
		const { isPending, isSuccess } = updatePartner
		if (!isPending && isSuccess) {
			addAlertMessage('Сохранено', 'success')
		}
	}, [updatePartner.isPending, updatePartner.isSuccess])

	return (
		<>
			<div className='partner-form'>
				<div className='upload-logo'>
					<div className='upload-logo__input'>
						<h4>Загрузите логотип</h4>
						<p>Минимальный размер изображения: 380х180</p>
						{logo.binary && (
							<div className='upload-logo__preview logo__preview-mob'>
								{logo.binary && <img src={logo.binary} alt='Selected' />}
							</div>
						)}
						<div className='input-file'>
							{logo.file && (
								<p className='file-name'>
									{logo?.file?.name}
									<span className='remove-img' onClick={handleRemoveImage}>
										<svg
											width='10'
											height='10'
											viewBox='0 0 10 10'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M9 1.00003L1.00003 9M1 1L8.99997 8.99997'
												stroke='#333333'
												strokeOpacity='0.4'
												strokeLinecap='round'
											/>
										</svg>
									</span>
								</p>
							)}
							<label>
								<input
									type='file'
									accept='image/*'
									onChange={handleImageChange}
									ref={inputFileRef}
								/>
								Прикрепите файл
								<i>
									<svg
										width='18'
										height='18'
										viewBox='0 0 18 18'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M14.2579 9.82478C16.2474 7.92037 16.2474 4.83272 14.2579 2.92831C12.2683 1.0239 9.04267 1.0239 7.05314 2.92831M5.93813 13.3551L11.8563 7.69016C12.5668 7.01001 12.5668 5.90728 11.8563 5.22713C11.1457 4.54699 9.99372 4.54698 9.28317 5.22713L3.4079 10.851C2.05786 12.1433 2.05786 14.2385 3.4079 15.5308C4.75794 16.8231 6.94678 16.8231 8.29681 15.5308L11.2773 12.6778M2.25 7.52596L4.65157 5.22713'
											stroke='#333333'
											strokeWidth='1.5'
											strokeLinecap='round'
										/>
									</svg>
								</i>
							</label>
						</div>
					</div>

					<div className='upload-logo__preview'>
						{!logo.binary && <p>LOGO</p>}
						{logo.binary && <img src={logo.binary} alt='Selected' />}
					</div>
				</div>

				{fields.map((item, index) => (
					<div className='inputs-row' key={index}>
						<Input
							control={control}
							type='number'
							name={`settlements.${index}.number`}
							label='Укажите расчетный счет'
							placeholder='Укажите расчетный счет'
							required
							//@ts-ignore
							error={errors?.settlements?.[index]?.number?.message}
							rules={{ required: 'Обязательное поле для заполнения' }}
							info={`Внесите номер расчетного счета на который планируете получать оплату..`}
						/>

						<ReactSelect
							control={control}
							name={`settlements.${index}.currency`}
							label='Ваша валюта'
							placeholder=''
							className='input-block'
							//@ts-ignore
							error={errors?.settlements?.[index]?.currency?.message}
							rules={{ required: 'Обязательное поле для заполнения' }}
							required
							options={currenciesData}
						/>
						{fields.length > 1 && (
							<button
								type='button'
								className='remove-row'
								onClick={() => removeBill(index)}
							>
								<svg
									width='9'
									height='9'
									viewBox='0 0 9 9'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
									preserveAspectRatio='none'
								>
									<path
										d='M8 1.00003L1.00003 8M1 1L7.99997 7.99997'
										stroke='#8A7DE7'
										strokeOpacity='1'
										strokeLinecap='round'
									/>
								</svg>
							</button>
						)}
					</div>
				))}

				<button className='add-row-inputs' type='button' onClick={addBill}>
					Добавить расчетный счет
					<i>
						<svg
							width='23'
							height='22'
							viewBox='0 0 23 22'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M14.25 11L11.5 11M11.5 11L8.75 11M11.5 11L11.5 8.25M11.5 11L11.5 13.75'
								stroke='white'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M6.91634 3.05959C8.26464 2.27964 9.83002 1.83325 11.4997 1.83325C16.5623 1.83325 20.6663 5.93731 20.6663 10.9999C20.6663 16.0625 16.5623 20.1666 11.4997 20.1666C6.43706 20.1666 2.33301 16.0625 2.33301 10.9999C2.33301 9.33027 2.7794 7.76488 3.55934 6.41658'
								stroke='white'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
						</svg>
					</i>
				</button>

				<div className='select-block' id='distributor-field'>
					<p>
						Выберите дистрибьюторов <span>*</span>
						<div className='input-note'>
							<svg
								width='18'
								height='18'
								viewBox='0 0 18 18'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7.59375 6.65625C7.59375 5.8796 8.22335 5.25 9 5.25C9.77665 5.25 10.4062 5.8796 10.4062 6.65625C10.4062 7.17183 10.1288 7.62261 9.71502 7.86741C9.35853 8.07832 9 8.39829 9 8.8125V9.75'
									stroke='#777777'
									strokeLinecap='round'
								/>
								<circle cx='9' cy='12' r='0.75' fill='#777777' />
								<path
									d='M5.25 2.50337C6.35315 1.86523 7.63392 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 7.63392 1.86523 6.35315 2.50337 5.25'
									stroke='#777777'
									strokeLinecap='round'
								/>
							</svg>
						</div>
						<div className='input-note-desc'>
							Выберите поставщиков с которыми вы работаете, если таковые
							отсутствуют в списке, отправить запрос на добавление.
						</div>
					</p>
					<ReactSelectMulti
						control={control}
						options={distributors}
						name='distributors'
						rules={{ required: 'Обязательное поле для заполнения' }}
						placeholder=''
						error={errors}
					/>

					<div className='not-found-distributors'>
						<p>
							Не нашли дистрибьютора в списке? Отправьте нам запрос на
							добавление!
						</p>
						<button onClick={() => setIsOpen(!isOpen)} type='button'>
							Отправить запрос на добавление нового дистрибьютора
						</button>
					</div>
				</div>

				{defaultData && (
					<div className='block-save'>
						<button className='btn' type='button' onClick={infoSubmit}>
							Сохранить
						</button>
					</div>
				)}
			</div>
			<AddDistributorPopup isOpen={isOpen} setIsOpen={setIsOpen} />
		</>
	)
}

export default ProfilePartnersSurvey

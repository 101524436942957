import { Layout } from 'shared/ui/layout'
import React from 'react'
import { ProjectsControlPage } from 'widgets/projects'

const ProjectsControl = () => {
	return (
		<Layout>
			<ProjectsControlPage />
		</Layout>
	)
}

export default ProjectsControl

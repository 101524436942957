import {
	IGenerateProject,
	IProjectData,
	IProjectFilter,
	IProjectUpdate,
	IAddProject,
	IProjectInfo,
	IProjectInfoResponse
} from './types/project.types'
import { axiosInstance } from 'shared/api'
import { getProjectUrl } from 'shared/api/config'

const ProjectService = {
	async getList(data?: IProjectFilter) {
		const response = await axiosInstance.get<IProjectData[]>(
			getProjectUrl(`/`),
			{ params: data }
		)
		return response
	},
	async create(data: IGenerateProject) {
		const response = await axiosInstance.post<string>(
			getProjectUrl(`/create/`),
			data
		)
		return response
	},

	async generateFile(data: IGenerateProject) {
		const response = await axiosInstance.post<string>(
			getProjectUrl(`/generate/excel/`),
			data
		)
		return response
	},
	async pickPartner(products?: string[]) {
		const response = await axiosInstance.get<string>(
			getProjectUrl(`/pick/partners/`),
			{ params: { products } }
		)
		return response
	},

	async getProjectInfo(id: number, data?: IProjectInfo) {
		const response = await axiosInstance.get<IProjectInfoResponse>(
			getProjectUrl(`/${id}/`),
			{
				params: data
			}
		)
		return response
	},

	async deleteProject(id: number) {
		const response = await axiosInstance.delete<string>(
			getProjectUrl(`/${id}/`)
		)
		return response
	},

	async changeProjectOwner(id: number, data: { user_id: number }) {
		const response = await axiosInstance.put<string>(
			getProjectUrl(`/${id}/change_owner/`),
			{
				...data
			}
		)
		return response
	},

	async addComment(id: number, data?: { text: string }) {
		const response = await axiosInstance.post<string>(
			getProjectUrl(`/${id}/comment/`),
			data
		)
		return response
	},

	async addProduct(id: number, data: IAddProject) {
		const response = await axiosInstance.post<string>(
			getProjectUrl(`/${id}/product/add/`),
			data
		)
		return response
	},

	async updateProduct(id: number, product_id: number, data: { count: number }) {
		const response = await axiosInstance.put<string>(
			getProjectUrl(`/${id}/product/${product_id}/`),
			data
		)
		return response
	},
	async deleteProduct(id: number, product_id: number) {
		const response = await axiosInstance.delete<string>(
			getProjectUrl(`/${id}/product/${product_id}/`)
		)
		return response
	},
	async updateProject(id: number, data: Omit<IProjectUpdate, 'id'>) {
		const response = await axiosInstance.patch<string>(
			getProjectUrl(`/${id}/update/`),
			data
		)
		return response
	},

	async addUserToProject(id: number, data: { user_id: number }) {
		const response = await axiosInstance.put<string>(
			getProjectUrl(`/${id}/user/add/`),
			data
		)
		return response
	},

	async deleteUserToProject(id: number, user_id: number) {
		const response = await axiosInstance.delete<string>(
			getProjectUrl(`/${id}/user/${user_id}/`)
		)
		return response
	}
}

export default ProjectService

import React, { FC } from 'react'
import { PromptText } from 'shared/ui/prompt-text'

interface IProps {
	value: string
}

const tariffHelpersName = {
	'1 пользователь':
		'Данный тариф предполагает использование сервиса только одним пользователем.',
	'5 пользователей':
		'5 пользователей включены в тариф, их можно добавить в разделе "Сотрудники"',
	'Видно поставщиков и партнеров':
		'Столбец "поставщик" в результатах поисковой выдачи будет скрыть до момента покупки тарифа. Вы не сможете подобрать партнера для покупки товара.',
	'Работа в команде':
		'Данный тариф позволяет работать над проектами совместно с другими пользователями, а так же позволяет руководителю контролировать и регулировать результаты работы по подбору поставщиков',
	'Покупка дополнительных мест':
		'Данный тариф предполагает покупку дополнительных мест. Если в вашей компании работает более 5 человек, вы всегда можете докупить дополнительное место для 1 или более человек.',
	'Статистика и исследования':
		'Раздел в разработке!!!                                                                                                                                     В разделе собраны инструменты для проведения исследований в товарной сфере, профицит и дефицит товара в разных странах и городах, так же вы сможете узнать как менялась цена, где покупать дешевле и др.',
	'Возможность получать заказы':
		'Данный тариф позволяет пользователю стать партнером сервиса и получать заказы от других пользователей.',
	'Продажа тарифов':
		'Данный тариф подразумевает возможность перепродавать доступ к площадке другим пользователям, за подробностями вы можете обратится к вашему менеджеру или написать в чат.',
	'Прайс лис':
		'Данный тариф позволяет пользователю стать партнером сервиса и размещать прайс лист на свои товары.'
}

export const TariffHelp: FC<IProps> = ({ value }) => {
	if (!Object.keys(tariffHelpersName).includes(value)) return null
	//@ts-ignore
	return <PromptText  className={'tariff-prompt'}  text={tariffHelpersName[value]} align='left' />
}

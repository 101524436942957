import { IStatsByDate, IStatsFilter, IStatsSource } from './types/stats.types'
import { axiosInstance } from 'shared/api'
import { getStatsUrl } from 'shared/api/config'

const StatsService = {
	async getCountProductPartner(id: number, data?: IStatsFilter) {
		
		const response = await axiosInstance.get<IStatsByDate[]>(
			getStatsUrl(`/${id}/product/count/`),
			{
				params: data
			}
		)
		return response
	},
	async getViewProductPartner(id: number, data?: IStatsFilter) {
		const response = await axiosInstance.get<IStatsSource[]>(
			getStatsUrl(`/${id}/product/views/`),
			{
				params: data
			}
		)
		return response
	},
	async getViewPartner(id: number, data?: IStatsFilter) {
		const response = await axiosInstance.get<IStatsSource[]>(
			getStatsUrl(`/${id}/views/`),
			{
				params: data
			}
		)
		return response
	}
}

export default StatsService

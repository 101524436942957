import React, { FC } from 'react'

interface IProps {
	rating: number
	count: number
	allCount: number
}
export const RatingLine: FC<IProps> = ({ count, rating, allCount }) => {
	const width = Math.floor((count / allCount) * 100)

	return (
		<div className='raging-statistics-star'>
			<div className='star'>
				{rating}
				<svg
					width='20'
					height='19'
					viewBox='0 0 20 19'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M10 3.68429L11.3925 6.80832L11.7448 7.59853L12.6051 7.68933L16.0066 8.04834L13.4658 10.3381L12.8231 10.9173L13.0026 11.7636L13.7123 15.1095L10.7494 13.4006L10 12.9684L9.25057 13.4006L6.28772 15.1095L6.99739 11.7636L7.1769 10.9173L6.53422 10.3381L3.9934 8.04834L7.39485 7.68933L8.25523 7.59853L8.60746 6.80832L10 3.68429Z'
						fill='#F1C644'
						stroke='#F1C644'
						strokeWidth='3'
					/>
				</svg>
			</div>
			<div className='star-mob'>
				<svg
					width='7'
					height='7'
					viewBox='0 0 7 7'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask id='path-1-inside-1_428_10156' fill='white'>
						<path d='M3.5 0L4.46691 2.16917L6.8287 2.41844L5.06449 4.00833L5.55725 6.33156L3.5 5.145L1.44275 6.33156L1.93551 4.00833L0.171302 2.41844L2.53309 2.16917L3.5 0Z' />
					</mask>
					<path
						d='M3.5 0L4.46691 2.16917L6.8287 2.41844L5.06449 4.00833L5.55725 6.33156L3.5 5.145L1.44275 6.33156L1.93551 4.00833L0.171302 2.41844L2.53309 2.16917L3.5 0Z'
						fill='#F1C644'
					/>
					<path
						d='M3.5 0L6.24011 -1.2214L3.5 -7.36858L0.759895 -1.2214L3.5 0ZM4.46691 2.16917L1.7268 3.39057L2.43127 4.97098L4.15202 5.1526L4.46691 2.16917ZM6.8287 2.41844L8.83706 4.647L13.8366 0.141424L7.14358 -0.564989L6.8287 2.41844ZM5.06449 4.00833L3.05613 1.77977L1.77076 2.93814L2.12977 4.63079L5.06449 4.00833ZM5.55725 6.33156L4.05838 8.93029L9.88839 12.2929L8.49196 5.7091L5.55725 6.33156ZM3.5 5.145L4.99887 2.54627L3.5 1.68177L2.00113 2.54627L3.5 5.145ZM1.44275 6.33156L-1.49196 5.7091L-2.88839 12.2929L2.94162 8.93029L1.44275 6.33156ZM1.93551 4.00833L4.87023 4.63079L5.22924 2.93814L3.94387 1.77977L1.93551 4.00833ZM0.171302 2.41844L-0.143581 -0.564989L-6.83663 0.141424L-1.83706 4.647L0.171302 2.41844ZM2.53309 2.16917L2.84798 5.1526L4.56873 4.97098L5.2732 3.39057L2.53309 2.16917ZM0.759895 1.2214L1.7268 3.39057L7.20701 0.947765L6.24011 -1.2214L0.759895 1.2214ZM4.15202 5.1526L6.51381 5.40187L7.14358 -0.564989L4.78179 -0.814262L4.15202 5.1526ZM4.82034 0.18988L3.05613 1.77977L7.07285 6.23689L8.83706 4.647L4.82034 0.18988ZM2.12977 4.63079L2.62253 6.95402L8.49196 5.7091L7.9992 3.38588L2.12977 4.63079ZM7.05612 3.73283L4.99887 2.54627L2.00113 7.74373L4.05838 8.93029L7.05612 3.73283ZM2.00113 2.54627L-0.0561173 3.73283L2.94162 8.93029L4.99887 7.74373L2.00113 2.54627ZM4.37747 6.95402L4.87023 4.63079L-0.999202 3.38587L-1.49196 5.7091L4.37747 6.95402ZM3.94387 1.77977L2.17966 0.18988L-1.83706 4.647L-0.07285 6.23689L3.94387 1.77977ZM0.486186 5.40187L2.84798 5.1526L2.21821 -0.814262L-0.143581 -0.564989L0.486186 5.40187ZM5.2732 3.39057L6.24011 1.2214L0.759895 -1.2214L-0.207012 0.947764L5.2732 3.39057Z'
						fill='#F1C644'
						mask='url(#path-1-inside-1_428_10156)'
					/>
				</svg>
				<svg
					width='7'
					height='7'
					viewBox='0 0 7 7'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask id='path-1-inside-1_428_10156' fill='white'>
						<path d='M3.5 0L4.46691 2.16917L6.8287 2.41844L5.06449 4.00833L5.55725 6.33156L3.5 5.145L1.44275 6.33156L1.93551 4.00833L0.171302 2.41844L2.53309 2.16917L3.5 0Z' />
					</mask>
					<path
						d='M3.5 0L4.46691 2.16917L6.8287 2.41844L5.06449 4.00833L5.55725 6.33156L3.5 5.145L1.44275 6.33156L1.93551 4.00833L0.171302 2.41844L2.53309 2.16917L3.5 0Z'
						fill='#F1C644'
					/>
					<path
						d='M3.5 0L6.24011 -1.2214L3.5 -7.36858L0.759895 -1.2214L3.5 0ZM4.46691 2.16917L1.7268 3.39057L2.43127 4.97098L4.15202 5.1526L4.46691 2.16917ZM6.8287 2.41844L8.83706 4.647L13.8366 0.141424L7.14358 -0.564989L6.8287 2.41844ZM5.06449 4.00833L3.05613 1.77977L1.77076 2.93814L2.12977 4.63079L5.06449 4.00833ZM5.55725 6.33156L4.05838 8.93029L9.88839 12.2929L8.49196 5.7091L5.55725 6.33156ZM3.5 5.145L4.99887 2.54627L3.5 1.68177L2.00113 2.54627L3.5 5.145ZM1.44275 6.33156L-1.49196 5.7091L-2.88839 12.2929L2.94162 8.93029L1.44275 6.33156ZM1.93551 4.00833L4.87023 4.63079L5.22924 2.93814L3.94387 1.77977L1.93551 4.00833ZM0.171302 2.41844L-0.143581 -0.564989L-6.83663 0.141424L-1.83706 4.647L0.171302 2.41844ZM2.53309 2.16917L2.84798 5.1526L4.56873 4.97098L5.2732 3.39057L2.53309 2.16917ZM0.759895 1.2214L1.7268 3.39057L7.20701 0.947765L6.24011 -1.2214L0.759895 1.2214ZM4.15202 5.1526L6.51381 5.40187L7.14358 -0.564989L4.78179 -0.814262L4.15202 5.1526ZM4.82034 0.18988L3.05613 1.77977L7.07285 6.23689L8.83706 4.647L4.82034 0.18988ZM2.12977 4.63079L2.62253 6.95402L8.49196 5.7091L7.9992 3.38588L2.12977 4.63079ZM7.05612 3.73283L4.99887 2.54627L2.00113 7.74373L4.05838 8.93029L7.05612 3.73283ZM2.00113 2.54627L-0.0561173 3.73283L2.94162 8.93029L4.99887 7.74373L2.00113 2.54627ZM4.37747 6.95402L4.87023 4.63079L-0.999202 3.38587L-1.49196 5.7091L4.37747 6.95402ZM3.94387 1.77977L2.17966 0.18988L-1.83706 4.647L-0.07285 6.23689L3.94387 1.77977ZM0.486186 5.40187L2.84798 5.1526L2.21821 -0.814262L-0.143581 -0.564989L0.486186 5.40187ZM5.2732 3.39057L6.24011 1.2214L0.759895 -1.2214L-0.207012 0.947764L5.2732 3.39057Z'
						fill='#F1C644'
						mask='url(#path-1-inside-1_428_10156)'
					/>
				</svg>
				<svg
					width='7'
					height='7'
					viewBox='0 0 7 7'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask id='path-1-inside-1_428_10156' fill='white'>
						<path d='M3.5 0L4.46691 2.16917L6.8287 2.41844L5.06449 4.00833L5.55725 6.33156L3.5 5.145L1.44275 6.33156L1.93551 4.00833L0.171302 2.41844L2.53309 2.16917L3.5 0Z' />
					</mask>
					<path
						d='M3.5 0L4.46691 2.16917L6.8287 2.41844L5.06449 4.00833L5.55725 6.33156L3.5 5.145L1.44275 6.33156L1.93551 4.00833L0.171302 2.41844L2.53309 2.16917L3.5 0Z'
						fill='#F1C644'
					/>
					<path
						d='M3.5 0L6.24011 -1.2214L3.5 -7.36858L0.759895 -1.2214L3.5 0ZM4.46691 2.16917L1.7268 3.39057L2.43127 4.97098L4.15202 5.1526L4.46691 2.16917ZM6.8287 2.41844L8.83706 4.647L13.8366 0.141424L7.14358 -0.564989L6.8287 2.41844ZM5.06449 4.00833L3.05613 1.77977L1.77076 2.93814L2.12977 4.63079L5.06449 4.00833ZM5.55725 6.33156L4.05838 8.93029L9.88839 12.2929L8.49196 5.7091L5.55725 6.33156ZM3.5 5.145L4.99887 2.54627L3.5 1.68177L2.00113 2.54627L3.5 5.145ZM1.44275 6.33156L-1.49196 5.7091L-2.88839 12.2929L2.94162 8.93029L1.44275 6.33156ZM1.93551 4.00833L4.87023 4.63079L5.22924 2.93814L3.94387 1.77977L1.93551 4.00833ZM0.171302 2.41844L-0.143581 -0.564989L-6.83663 0.141424L-1.83706 4.647L0.171302 2.41844ZM2.53309 2.16917L2.84798 5.1526L4.56873 4.97098L5.2732 3.39057L2.53309 2.16917ZM0.759895 1.2214L1.7268 3.39057L7.20701 0.947765L6.24011 -1.2214L0.759895 1.2214ZM4.15202 5.1526L6.51381 5.40187L7.14358 -0.564989L4.78179 -0.814262L4.15202 5.1526ZM4.82034 0.18988L3.05613 1.77977L7.07285 6.23689L8.83706 4.647L4.82034 0.18988ZM2.12977 4.63079L2.62253 6.95402L8.49196 5.7091L7.9992 3.38588L2.12977 4.63079ZM7.05612 3.73283L4.99887 2.54627L2.00113 7.74373L4.05838 8.93029L7.05612 3.73283ZM2.00113 2.54627L-0.0561173 3.73283L2.94162 8.93029L4.99887 7.74373L2.00113 2.54627ZM4.37747 6.95402L4.87023 4.63079L-0.999202 3.38587L-1.49196 5.7091L4.37747 6.95402ZM3.94387 1.77977L2.17966 0.18988L-1.83706 4.647L-0.07285 6.23689L3.94387 1.77977ZM0.486186 5.40187L2.84798 5.1526L2.21821 -0.814262L-0.143581 -0.564989L0.486186 5.40187ZM5.2732 3.39057L6.24011 1.2214L0.759895 -1.2214L-0.207012 0.947764L5.2732 3.39057Z'
						fill='#F1C644'
						mask='url(#path-1-inside-1_428_10156)'
					/>
				</svg>
				<svg
					width='7'
					height='7'
					viewBox='0 0 7 7'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask id='path-1-inside-1_428_10156' fill='white'>
						<path d='M3.5 0L4.46691 2.16917L6.8287 2.41844L5.06449 4.00833L5.55725 6.33156L3.5 5.145L1.44275 6.33156L1.93551 4.00833L0.171302 2.41844L2.53309 2.16917L3.5 0Z' />
					</mask>
					<path
						d='M3.5 0L4.46691 2.16917L6.8287 2.41844L5.06449 4.00833L5.55725 6.33156L3.5 5.145L1.44275 6.33156L1.93551 4.00833L0.171302 2.41844L2.53309 2.16917L3.5 0Z'
						fill='#F1C644'
					/>
					<path
						d='M3.5 0L6.24011 -1.2214L3.5 -7.36858L0.759895 -1.2214L3.5 0ZM4.46691 2.16917L1.7268 3.39057L2.43127 4.97098L4.15202 5.1526L4.46691 2.16917ZM6.8287 2.41844L8.83706 4.647L13.8366 0.141424L7.14358 -0.564989L6.8287 2.41844ZM5.06449 4.00833L3.05613 1.77977L1.77076 2.93814L2.12977 4.63079L5.06449 4.00833ZM5.55725 6.33156L4.05838 8.93029L9.88839 12.2929L8.49196 5.7091L5.55725 6.33156ZM3.5 5.145L4.99887 2.54627L3.5 1.68177L2.00113 2.54627L3.5 5.145ZM1.44275 6.33156L-1.49196 5.7091L-2.88839 12.2929L2.94162 8.93029L1.44275 6.33156ZM1.93551 4.00833L4.87023 4.63079L5.22924 2.93814L3.94387 1.77977L1.93551 4.00833ZM0.171302 2.41844L-0.143581 -0.564989L-6.83663 0.141424L-1.83706 4.647L0.171302 2.41844ZM2.53309 2.16917L2.84798 5.1526L4.56873 4.97098L5.2732 3.39057L2.53309 2.16917ZM0.759895 1.2214L1.7268 3.39057L7.20701 0.947765L6.24011 -1.2214L0.759895 1.2214ZM4.15202 5.1526L6.51381 5.40187L7.14358 -0.564989L4.78179 -0.814262L4.15202 5.1526ZM4.82034 0.18988L3.05613 1.77977L7.07285 6.23689L8.83706 4.647L4.82034 0.18988ZM2.12977 4.63079L2.62253 6.95402L8.49196 5.7091L7.9992 3.38588L2.12977 4.63079ZM7.05612 3.73283L4.99887 2.54627L2.00113 7.74373L4.05838 8.93029L7.05612 3.73283ZM2.00113 2.54627L-0.0561173 3.73283L2.94162 8.93029L4.99887 7.74373L2.00113 2.54627ZM4.37747 6.95402L4.87023 4.63079L-0.999202 3.38587L-1.49196 5.7091L4.37747 6.95402ZM3.94387 1.77977L2.17966 0.18988L-1.83706 4.647L-0.07285 6.23689L3.94387 1.77977ZM0.486186 5.40187L2.84798 5.1526L2.21821 -0.814262L-0.143581 -0.564989L0.486186 5.40187ZM5.2732 3.39057L6.24011 1.2214L0.759895 -1.2214L-0.207012 0.947764L5.2732 3.39057Z'
						fill='#F1C644'
						mask='url(#path-1-inside-1_428_10156)'
					/>
				</svg>
				<svg
					width='7'
					height='7'
					viewBox='0 0 7 7'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask id='path-1-inside-1_428_10156' fill='white'>
						<path d='M3.5 0L4.46691 2.16917L6.8287 2.41844L5.06449 4.00833L5.55725 6.33156L3.5 5.145L1.44275 6.33156L1.93551 4.00833L0.171302 2.41844L2.53309 2.16917L3.5 0Z' />
					</mask>
					<path
						d='M3.5 0L4.46691 2.16917L6.8287 2.41844L5.06449 4.00833L5.55725 6.33156L3.5 5.145L1.44275 6.33156L1.93551 4.00833L0.171302 2.41844L2.53309 2.16917L3.5 0Z'
						fill='#F1C644'
					/>
					<path
						d='M3.5 0L6.24011 -1.2214L3.5 -7.36858L0.759895 -1.2214L3.5 0ZM4.46691 2.16917L1.7268 3.39057L2.43127 4.97098L4.15202 5.1526L4.46691 2.16917ZM6.8287 2.41844L8.83706 4.647L13.8366 0.141424L7.14358 -0.564989L6.8287 2.41844ZM5.06449 4.00833L3.05613 1.77977L1.77076 2.93814L2.12977 4.63079L5.06449 4.00833ZM5.55725 6.33156L4.05838 8.93029L9.88839 12.2929L8.49196 5.7091L5.55725 6.33156ZM3.5 5.145L4.99887 2.54627L3.5 1.68177L2.00113 2.54627L3.5 5.145ZM1.44275 6.33156L-1.49196 5.7091L-2.88839 12.2929L2.94162 8.93029L1.44275 6.33156ZM1.93551 4.00833L4.87023 4.63079L5.22924 2.93814L3.94387 1.77977L1.93551 4.00833ZM0.171302 2.41844L-0.143581 -0.564989L-6.83663 0.141424L-1.83706 4.647L0.171302 2.41844ZM2.53309 2.16917L2.84798 5.1526L4.56873 4.97098L5.2732 3.39057L2.53309 2.16917ZM0.759895 1.2214L1.7268 3.39057L7.20701 0.947765L6.24011 -1.2214L0.759895 1.2214ZM4.15202 5.1526L6.51381 5.40187L7.14358 -0.564989L4.78179 -0.814262L4.15202 5.1526ZM4.82034 0.18988L3.05613 1.77977L7.07285 6.23689L8.83706 4.647L4.82034 0.18988ZM2.12977 4.63079L2.62253 6.95402L8.49196 5.7091L7.9992 3.38588L2.12977 4.63079ZM7.05612 3.73283L4.99887 2.54627L2.00113 7.74373L4.05838 8.93029L7.05612 3.73283ZM2.00113 2.54627L-0.0561173 3.73283L2.94162 8.93029L4.99887 7.74373L2.00113 2.54627ZM4.37747 6.95402L4.87023 4.63079L-0.999202 3.38587L-1.49196 5.7091L4.37747 6.95402ZM3.94387 1.77977L2.17966 0.18988L-1.83706 4.647L-0.07285 6.23689L3.94387 1.77977ZM0.486186 5.40187L2.84798 5.1526L2.21821 -0.814262L-0.143581 -0.564989L0.486186 5.40187ZM5.2732 3.39057L6.24011 1.2214L0.759895 -1.2214L-0.207012 0.947764L5.2732 3.39057Z'
						fill='#F1C644'
						mask='url(#path-1-inside-1_428_10156)'
					/>
				</svg>
			</div>
			<div className='rating-bar'>
				<div className='rating-bar__line' style={{ width: `${width}%` }}></div>
			</div>
			<div className='voting-count'>{count}</div>
		</div>
	)
}

import React, { FC, useEffect, useState } from 'react'
import { axiosInstance } from 'shared/api'

interface IProps {
	data: any
	id: number
}

const ProductsAmount: FC<IProps> = ({ data, id }) => {
	const [productAmount, setProductAmount] = useState(data.count)

	const changeAmount = async () => {
		try {
			if (productAmount !== data.count) {
				await axiosInstance.put(`/project/${id}/product/${data.id}/`, {
					count: productAmount
				})
			}
		} catch (error) {
			console.log(error)
		}
	}
	const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target
		setProductAmount(value)
	}

	useEffect(() => {
		changeAmount()
	}, [window.location.href])

	window.addEventListener('beforeunload', event => {
		changeAmount()
	})
	window.addEventListener('popstate', event => {
		changeAmount()
	})

	return (
		<>
			<input
				type='number'
				style={{ border: 'none', outline: 'none' }}
				value={productAmount}
				onChange={handleAmountChange}
			/>
		</>
	)
}

export default ProductsAmount

import { useQuery, useQueryClient } from '@tanstack/react-query'
import ProjectService from 'shared/services/project.service'
import {
	IProjectFilter,
	IProjectInfo
} from 'shared/services/types/project.types'

export const useGetProject = (filter?: IProjectFilter, blocked?: boolean) => {
	return useQuery({
		queryFn: () => ProjectService.getList(filter),
		queryKey: ['get project list', filter],
		select: ({ data }) => data,
		enabled: !blocked,
		retry: 0
	})
}

export const useGetProjectInfo = (id?: number, filter?: IProjectInfo) => {
	return useQuery({
		queryFn: () => ProjectService.getProjectInfo(id!, filter),
		queryKey: ['get project', id, filter],
		select: ({ data }) => data,
		enabled: !!id || !!filter,
		retry: 0
	})
}

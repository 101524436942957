import { useMutation, useQuery } from '@tanstack/react-query'
import SubscriptionService from 'shared/services/subscription.service'
import { ISubscription } from 'shared/services/types/subscription.types'

export const useTariffsActions = () => {
	const updateTariff = useMutation({
		mutationFn: (data: ISubscription) =>
			SubscriptionService.updateSubscription(data),
		mutationKey: ['update tariff']
	})

	const requestTariff = useMutation({
		mutationFn: (data: ISubscription) =>
			SubscriptionService.requestSubscription(data),
		mutationKey: ['request tariff']
	})

	return { updateTariff, requestTariff }
}

import Suppliers from 'widgets/suppliers'
import { Layout } from 'shared/ui/layout'

const SuppliersPage = () => {
	return (
		<Layout>
			<Suppliers />
		</Layout>
	)
}

export default SuppliersPage

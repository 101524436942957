const maskToPlaceholder = (mask: string) => {
	return mask.replace(/9/g, '_')
}

export const maskPhone = {
	Россия: {
		mask: '+ 7 (999) 999-99-99',
		placeholder: maskToPlaceholder(`+ 7 (999) 999-99-99`)
	},
	США: {
		mask: '(999) 999-9999',
		placeholder: maskToPlaceholder(`(999) 999-9999`)
	},
	Казахстан: {
		mask: '+ 7 (999) 999-99-99',
		placeholder: maskToPlaceholder(`+ 7 (999) 999-99-99`)
	},
	Европа: {
		mask: '+ 44 999 999-999-999',
		placeholder: maskToPlaceholder(`+ 44 999 999-999-999`)
	},
	Украина: {
		mask: '+ 38 (999) 999-99-99',
		placeholder: maskToPlaceholder(`+ 38 (999) 999-99-99`)
	},
	Беларусь: {
		mask: '+ 375 99 999 99 99',
		placeholder: maskToPlaceholder(`+ 375 99 999 99 99`)
	},
	Кыргызстан: {
		mask: '+996 99 999 99 99',
		placeholder: maskToPlaceholder(`+(996) 99 999 99 99`)
	},
	Узбекистан: {
		mask: '+ 998 99 999 99 99',
		placeholder: maskToPlaceholder(`+ 998 99 999 99 99`)
	}
}

const phoneMasks: { [key: string]: string } = {
	'+7': '+7 (999) 999-99-99', // Россия
	'8': '+7 (999) 999-99-99', // Россия
	'+86': '+86 999 9999 9999', // Китай
	'+1': '+1 (999) 999-9999', // США
	'+44': '+44 9999 999999', // Великобритания
	'+91': '+91 99999 99999', // Индия
	'+81': '+81 99-9999-9999', // Япония
	'+49': '+49 999 99999999', // Германия
	'+33': '+33 9 99 99 99 99', // Франция
	'+61': '+61 9 9999 9999', // Австралия
	'+34': '+34 999 999 999' // Испания
}

// Функция для определения маски на основе кода
export const getMaskForPhone = (phone: string) => {
	if (!phone) return undefined
	// Находим код страны, который пользователь вводит
	const foundCode = Object.keys(phoneMasks).find(code => phone.startsWith(code))
	return foundCode ? phoneMasks[foundCode] : '' // Возвращаем маску или пустую строку
}

import Cookies from 'js-cookie'

export const tokenParse = () => {
	const token = Cookies.get('kira_token')
	if (!token) return {}

	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(c => {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join('')
	)
	const parsedJsonPayload = JSON.parse(jsonPayload)
	return parsedJsonPayload
}

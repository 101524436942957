import React, { FC, useEffect, useState } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import { IStatsSource } from 'shared/services/types/stats.types'

interface IProps {
	data: IStatsSource
	counts?: IStatsSource
}

export const StatisticsBlock: FC<IProps> = ({ data, counts }) => {
	const [dataStatistics, setStatStatistics] = useState<any[]>([])
	//uv показы
	//pv товары

	useEffect(() => {
		if (data) {
			const results = data.events.map((item, index) => {
				const resultCount = counts?.events?.[index]?.count || 0
				return {
					data: item.date,
					uv: item.count,
					pv: resultCount
				}
			})
			setStatStatistics(results)
		}
	}, [])

	return (
		<div>
			<div className='statistics-diagram-block'>
				<p className='statistics-diagram-block__source'>
					Статистика показов:
					<span>{data.source.url}</span>
				</p>

				<ResponsiveContainer width='100%' height={418}>
					<LineChart
						width={500}
						height={418}
						data={dataStatistics}
						margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
					>
						{/* <CartesianGrid strokeDasharray="3 3" /> */}
						{/* <XAxis dataKey="name" /> */}
						<YAxis strokeWidth='1px' stroke='#EBF1F8' />
						<Tooltip />
						{/* <Legend /> */}
						<Line
							type='monotone'
							name='Товары'
							dataKey='pv'
							strokeWidth='3px'
							stroke='#20BF55'
							activeDot={{ r: 8 }}
						/>
						<Line
							type='monotone'
							name='Показы'
							dataKey='uv'
							strokeWidth='3px'
							stroke='#8A7DE7'
						/>
					</LineChart>
				</ResponsiveContainer>

				<div className='statistics-diagram-block__date'>
					{!!dataStatistics.length && (
						<>
							<p>{dataStatistics[0].date}</p>
							<p>{dataStatistics.at(-1).date}</p>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

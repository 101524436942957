import {
	QueryClientProvider,
	useMutation,
	useQuery,
	useQueryClient
} from '@tanstack/react-query'
import CompanyService from 'shared/services/company.service'
import {
	IPriceUpdate,
	TPriceSourceData
} from 'shared/services/types/price.types'
import UserService from 'shared/services/user.service'

export const useUserActionById = (id?: number) => {
	const queryClient = useQueryClient()

	const deleteCompanyUser = useMutation({
		mutationFn: (id: number) => CompanyService.deleteCompanyUser(id),
		mutationKey: ['deleteCompanyUser', id],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [`get users company`]
			})
		}
	})

	// const updatePartUser = useMutation({
	// 	mutationFn: (data: TPriceSourceData) => UserService.updatePartUser(id!),
	// 	mutationKey: ['update price source', id],
	// 	onSuccess: () => {
	// 		queryClient.invalidateQueries({
	// 			queryKey: [`get price source`]
	// 		})
	// 	}
	// })

	return {
		deleteCompanyUser
	}
}

import clsx from 'clsx'
import React, { FC } from 'react'
import { IPriceSource } from 'shared/services/types/price.types'

interface IProps {
	item: IPriceSource
	onClickEdit: (data: IPriceSource) => void
	onOpenModal: (data: IPriceSource) => void
	onFreezeModal: (data: IPriceSource) => void
	error?: string
}

export const SourceItem: FC<IProps> = ({
	item,
	onClickEdit,
	onOpenModal,
	onFreezeModal,
	error
}) => {
	return (
		<>
			<div
				className={clsx(
					'data-source-item',
					item.frozen && 'freezing',
					error && 'error'
				)}
			>
				<div className='data-source-item__url'>{item.url}</div>
				<div className='data-source-item__action'>
					{!item.frozen ? (
						<>
							<button onClick={() => onClickEdit(item)}>
								<svg
									width='20'
									height='20'
									viewBox='0 0 20 20'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M3.33301 18.3333H6.66634M16.6663 18.3333H9.99968'
										stroke='#333333'
										strokeWidth='1.3'
										strokeLinecap='round'
									/>
									<path
										d='M11.5731 3.05252L12.191 2.4346C13.2148 1.4108 14.8747 1.4108 15.8985 2.4346C16.9223 3.4584 16.9223 5.11832 15.8985 6.14212L15.2806 6.76004M11.5731 3.05252C11.5731 3.05252 11.6503 4.3656 12.8089 5.5242C13.9675 6.6828 15.2806 6.76004 15.2806 6.76004M11.5731 3.05252L5.89222 8.73335C5.50745 9.11812 5.31506 9.31051 5.14961 9.52264C4.95443 9.77287 4.7871 10.0436 4.65057 10.3301C4.53483 10.573 4.44879 10.8311 4.27672 11.3473L3.54755 13.5348M15.2806 6.76004L12.4402 9.60045M9.59974 12.4409C9.21497 12.8256 9.02258 13.018 8.81045 13.1835C8.56022 13.3787 8.28947 13.546 8.00299 13.6825C7.76014 13.7983 7.50202 13.8843 6.98579 14.0564L4.79829 14.7855M4.79829 14.7855L4.26357 14.9638C4.00953 15.0485 3.72945 14.9823 3.5401 14.793C3.35074 14.6036 3.28463 14.3236 3.36931 14.0695L3.54755 13.5348M4.79829 14.7855L3.54755 13.5348'
										stroke='#333333'
										strokeWidth='1.3'
										strokeLinecap='round'
									/>
								</svg>
							</button>
							<button onClick={() => onOpenModal(item)}>
								<svg
									width='20'
									height='20'
									viewBox='0 0 20 20'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M7.64258 3.33341C7.98578 2.36242 8.91181 1.66675 10.0003 1.66675C11.0888 1.66675 12.0149 2.36242 12.3581 3.33341'
										stroke='#333333'
										strokeWidth='1.3'
										strokeLinecap='round'
									/>
									<path
										d='M17.0837 5H2.91699'
										stroke='#333333'
										strokeWidth='1.3'
										strokeLinecap='round'
									/>
									<path
										d='M15.3113 12.8325C15.1638 15.0449 15.09 16.1511 14.3692 16.8255C13.6483 17.4999 12.5397 17.4999 10.3223 17.4999H9.67787C7.46054 17.4999 6.35187 17.4999 5.63103 16.8255C4.91019 16.1511 4.83644 15.0449 4.68895 12.8325L4.30566 7.08325M15.6946 7.08325L15.5279 9.58325'
										stroke='#333333'
										strokeWidth='1.3'
										strokeLinecap='round'
									/>
									<path
										d='M7.91699 9.16675L8.33366 13.3334'
										stroke='#333333'
										strokeWidth='1.3'
										strokeLinecap='round'
									/>
									<path
										d='M12.0837 9.16675L11.667 13.3334'
										stroke='#333333'
										strokeWidth='1.3'
										strokeLinecap='round'
									/>
								</svg>
							</button>
						</>
					) : (
						<button onClick={() => onFreezeModal(item)}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
							>
								<path
									d='M12 2V18M12 22V18M12 18L15 21M12 18L9 21M15 3L12 6L9 3'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M3.34003 7.00005L6.80413 9.00005M6.80413 9.00005L17.1964 15.0001M6.80413 9.00005L5.70605 4.90198M6.80413 9.00005L2.70605 10.0981M17.1964 15.0001L20.6605 17.0001M17.1964 15.0001L21.2945 13.902M17.1964 15.0001L18.2945 19.0981'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M20.6605 7.00005L17.1964 9.00005M17.1964 9.00005L6.80413 15.0001M17.1964 9.00005L18.2945 4.90198M17.1964 9.00005L21.2945 10.0981M6.80413 15.0001L3.34003 17.0001M6.80413 15.0001L2.70605 13.902M6.80413 15.0001L5.70605 19.0981'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</button>
					)}
				</div>
			</div>
			{error && (
				<p className='error-message'>
					К сожалению, вы не можете использовать этот источник!
				</p>
			)}
		</>
	)
}

import React, { useEffect, useState } from 'react'
import { FaArrowUp } from 'react-icons/fa'
import styles from './button.module.scss'
export const ButtonUp = () => {
	const [scrollPosition, setScrollPosition] = useState(0)
	useEffect(() => {
		const handleScroll = () => {
			setScrollPosition(window.scrollY)
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const onClickUp = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}
	if (scrollPosition < 1000) return null
	return (
		<div className={styles.wrapper} onClick={onClickUp}>
			<FaArrowUp />
		</div>
	)
}

import { Layout } from 'shared/ui/layout'
import React from 'react'
import ProjectPage from 'widgets/projects/[id]'

const Projects = () => {
	return (
		<Layout>
			<ProjectPage />
		</Layout>
	)
}

export default Projects

import { useQuery } from '@tanstack/react-query'
import CompanyService from 'shared/services/company.service'

export const useGetCompany = (id?: number) => {
	const { isPending, isError, isSuccess, data, error } = useQuery({
		queryFn: () => CompanyService.getCompany(id),
		queryKey: ['get company', id],
		select: ({ data }) => data
	})

	return { isPending, isError, isSuccess, error, companyData: data }
}

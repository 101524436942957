import { IDataCountry } from './services/types/utils.types'
import RUFlag from 'assets/flags/RU.svg'

export const LANG_LIST: IDataCountry[] = [
	{ id: 1, name: 'Русский', flag: RUFlag }
]

export const CURRENCY_LIST = [
	'AED',
	'AFN',
	'ALL',
	'AMD',
	'ANG',
	'AOA',
	'ARS',
	'AUD',
	'AWG',
	'AZN',
	'BAM',
	'BBD',
	'BDT',
	'BGN',
	'BHD',
	'BIF',
	'BMD',
	'BND',
	'BOB',
	'BRL',
	'BSD',
	'BTC',
	'BTN',
	'BWP',
	'BYR',
	'BYN',
	'BZD',
	'CAD',
	'CDF',
	'CHF',
	'CLP',
	'CNY',
	'COP',
	'CRC',
	'CUC',
	'CUP',
	'CVE',
	'CZK',
	'DJF',
	'DKK',
	'DOP',
	'DZD',
	'EEK',
	'EGP',
	'ERN',
	'ETB',
	'ETH',
	'EUR',
	'FJD',
	'FKP',
	'GBP',
	'GEL',
	'GGP',
	'GHC',
	'GHS',
	'GIP',
	'GMD',
	'GNF',
	'GTQ',
	'GYD',
	'HKD',
	'HNL',
	'HRK',
	'HTG',
	'HUF',
	'IDR',
	'ILS',
	'IMP',
	'INR',
	'IQD',
	'IRR',
	'ISK',
	'JEP',
	'JMD',
	'JOD',
	'JPY',
	'KES',
	'KGS',
	'KHR',
	'KMF',
	'KPW',
	'KRW',
	'KWD',
	'KYD',
	'KZT',
	'LAK',
	'LBP',
	'LKR',
	'LRD',
	'LSL',
	'LTC',
	'LTL',
	'LVL',
	'LYD',
	'MAD',
	'MDL',
	'MGA',
	'MKD',
	'MMK',
	'MNT',
	'MOP',
	'MRO',
	'MRU',
	'MUR',
	'MVR',
	'MWK',
	'MXN',
	'MYR',
	'MZN',
	'NAD',
	'NGN',
	'NIO',
	'NOK',
	'NPR',
	'NZD',
	'OMR',
	'PAB',
	'PEN',
	'PGK',
	'PHP',
	'PKR',
	'PLN',
	'PYG',
	'QAR',
	'RMB',
	'RON',
	'RSD',
	'RUB',
	'RWF',
	'SAR',
	'SBD',
	'SCR',
	'SDG',
	'SEK',
	'SGD',
	'SHP',
	'SLL',
	'SOS',
	'SRD',
	'SSP',
	'STD',
	'STN',
	'SVC',
	'SYP',
	'SZL',
	'THB',
	'TJS',
	'TMT',
	'TND',
	'TOP',
	'TRL',
	'TRY',
	'TTD',
	'TVD',
	'TWD',
	'TZS',
	'UAH',
	'UGX',
	'USD',
	'UYU',
	'UZS',
	'VEF',
	'VND',
	'VUV',
	'WST',
	'XAF',
	'XBT',
	'XCD',
	'XOF',
	'XPF',
	'YER',
	'ZAR',
	'ZWD'
] as const

export type ICurrencyValue = typeof CURRENCY_LIST


export const imageExtension = [
	'blp',
	'bmp',
	'dib',
	'bufr',
	'cur',
	'pcx',
	'dcx',
	'dds',
	'ps',
	'eps',
	'fit',
	'fits',
	'fli',
	'flc',
	'ftc',
	'ftu',
	'gbr',
	'gif',
	'grib',
	'h5',
	'hdf',
	'png',
	'apng',
	'jp2',
	'j2k',
	'jpc',
	'jpf',
	'jpx',
	'j2c',
	'icns',
	'ico',
	'im',
	'iim',
	'tif',
	'tiff',
	'jfif',
	'jpe',
	'jpg',
	'jpeg',
	'mpg',
	'mpeg',
	'mpo',
	'msp',
	'palm',
	'pcd',
	'pdf',
	'pxr',
	'pbm',
	'pgm',
	'ppm',
	'pnm',
	'psd',
	'bw',
	'rgb',
	'rgba',
	'sgi',
	'ras',
	'tga',
	'icb',
	'vda',
	'vst',
	'webp',
	'wmf',
	'emf',
	'xbm',
	'xpm'
]


export const countryCodeName = {
	1:'RUS',
	2:'USA',
	3:'KAZ',
	4:'EUR',
	5:'UKR',
	6:'BLR',
	7:'KYR',
	8:'UZB',
}
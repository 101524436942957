import { IRecoveryPassword } from './../types/auth.types'
import { axiosClassic, axiosInstance } from 'shared/api'
import { getAuthUrl, getChatUrl } from 'shared/api/config'
import {
	IDialogResponse,
	IUserDialogs,
	IDialogRequest,
	IAttachFileMessage
} from '../types/chat.types'
import axios from 'axios'
import { removeTokensStorage, saveTokensStorage } from './auth.helpers'

const AuthService = {
	async register(data: any) {
		const response = await axiosClassic.post(getAuthUrl('/register/'), data, {
			withCredentials: true
		})
		return response
	},

	async login(data: any) {
		const response = await axiosClassic.post(getAuthUrl('/login/'), data, {
			withCredentials: true
		})

		return response
	},

	async refresh() {
		const refresh = localStorage.getItem('refresh') || localStorage.getItem('kira_refresh')
		const response = await axiosClassic.post(getAuthUrl('/token/refresh/'), {
			refresh
		})
		saveTokensStorage(response.data)
		return response
	},

	async logout() {
		const response = await axiosClassic.get(getAuthUrl('/logout/'), {})
		removeTokensStorage()
		return response
	},

	async emailRecovery(data: any) {
		const response = await axiosClassic.post(
			getAuthUrl('/password/forgot/email/'),
			data
		)
		return response
	},

	async passwordRecovery(
		data: IRecoveryPassword,
		uidb64: string,
		token: string
	) {
		const response = await axiosClassic.post(
			getAuthUrl(`/password/forgot/${uidb64}/${token}/`),
			data
		)
		return response
	},

	async passwordChange(data: any) {
		const response = await axiosInstance.put(
			getAuthUrl('/password/change/'),
			data
		)
		;('user/password/change/')
		;('api/user/password/change/')
		return response
	},

	async getAttachFile(data: IAttachFileMessage) {
		const response = await axiosInstance.patch<{ file: string }>(
			getChatUrl(`/chat/file/${data.message_id}/`),
			{},
			{ params: data }
		)
		return response
	}
}

export default AuthService

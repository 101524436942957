import { useQuery } from '@tanstack/react-query'
import CompanyService from 'shared/services/company.service'

export const useUsersCompany = () => {
	const { isPending, isError, isSuccess, data } = useQuery({
		queryFn: () => CompanyService.getUsersCompany(),
		queryKey: ['get users company'],
		select: ({ data }) => data
	})

	return { isPending, isError, isSuccess, companyUsersData: data }
}

import { Layout } from 'shared/ui/layout'
import DistributorPage from 'widgets/distributor'

const Distributor = () => {
	return (
		<Layout>
			<DistributorPage />
		</Layout>
	)
}

export default Distributor

import { useMutation, useQueryClient } from '@tanstack/react-query'
import SupplierService from 'shared/services/supplier.service'
import {
	IReviewUpdate,
	IReviewsData, IReviewsScore
} from 'shared/services/types/supplier.types'

export const useReviewById = (id: number) => {
	const queryClient = useQueryClient()

	const changeReview = useMutation({
		mutationFn: (data: IReviewUpdate) => SupplierService.changeReview(id, data),
		mutationKey: ['change review', id],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get reviews list`] })
		}
	})

	const deleteReview = useMutation({
		mutationFn: () => SupplierService.deleteReview(id),
		mutationKey: ['delete review', id],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get reviews list`] })
		}
	})

	const addAnswerReview = useMutation({
		mutationFn: (data: IReviewsData) =>
			SupplierService.addReviewAnswer(id, data),
		mutationKey: ['answer review', id],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get reviews list`] })
		}
	})

	const changeReviewScore = useMutation({
		mutationFn: (data: IReviewsScore) =>
			SupplierService.changeScoreReview(id, data),
		mutationKey: ['change score review', id],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get reviews list`] })
		}
	})

	return { deleteReview, changeReview, addAnswerReview, changeReviewScore }
}

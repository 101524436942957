import { useQuery, useQueryClient } from '@tanstack/react-query'
import PartnerService from 'shared/services/partner.service'

export const usePickPartners = (data?: number[]) => {
	return useQuery({
		queryFn: () => PartnerService.pickPartner(data),
		queryKey: ['use pick partners'],
		enabled: false,
		retry: 0,
		select: ({ data }) => data
	})
}

import { queryClient } from 'shared/libs/react-query'
import { QueryClientProvider as TanStackQueryClientProvider } from '@tanstack/react-query'
import { FC, ReactNode } from 'react'

type QueryClientProviderProps = {
	children: ReactNode
}

export const QueryClientProvider: FC<QueryClientProviderProps> = ({
	children
}) => {
	return (
		<TanStackQueryClientProvider client={queryClient}>
			{children}
		</TanStackQueryClientProvider>
	)
}

import { useQuery, useQueryClient } from '@tanstack/react-query'
import PartnerService from 'shared/services/partner.service'
import StatsService from 'shared/services/stats.service'
import { IPartnerFilter } from 'shared/services/types/partner.types'
import { IStatsFilter } from 'shared/services/types/stats.types'

export const useGetStatistics = (id?: number, data?: IStatsFilter) => {
	const queryClient = useQueryClient()

	const getCountProductPartner = useQuery({
		queryFn: () => StatsService.getCountProductPartner(id!, data),
		queryKey: ['getCountProductPartner'],
		select: ({ data }) => data,
		retry: 0,
		enabled: !!id
	})

	const getViewPartner = useQuery({
		queryFn: () => StatsService.getViewPartner(id!, data),
		queryKey: ['getViewPartner', data],
		enabled: !!id,
		retry: 0,
		select: ({ data }) => data
	})

	const getViewProductPartner = useQuery({
		queryFn: () => StatsService.getViewProductPartner(id!, data),
		queryKey: ['getViewProductPartner'],
		enabled: !!id,
		retry: 0,
		select: ({ data }) => data
	})
	return {
		getCountProductPartner,
		getViewPartner,
		getViewProductPartner
	}
}

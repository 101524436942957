import ProductPage from 'widgets/products'
import { Layout } from 'shared/ui/layout'
import { Outlet } from 'react-router'

const Main = () => {
	
	return (
		<Layout>
			<Outlet />
			<ProductPage />
		</Layout>
	)
}

export default Main

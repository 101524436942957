import { useState } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import '../../../features/profile/ui/popup-profile/Popup.scss'
import '../../../features/profile/ui/add-distributor-modal/AddDistributorPopup.scss'
import { Link } from 'react-router-dom'

export const PopupUtils = () => {
	const [isOpen, setIsOpen] = useState(
		!window.localStorage.getItem('kira_check')
	)
	const handlePopupClose = () => {
		setIsOpen(false)
	}

	return (
		<Popup
			className='requisites-popup-base'
			open={isOpen}
			closeOnDocumentClickpup-header
			onClose={handlePopupClose}
			contentStyle={{
				maxWidth: '900px',
				borderRadius: '15px',
				background: '#fff'
			}}
			overlayStyle={{
				background: 'rgba(0, 0, 0, 0.5)'
			}}
		>
			<div className='partner-page-modal-requisites'>
				<div className='requisites-popup-header'>
					<h1>Приветствуем Вас на площадке КИRА</h1>
					<button type='button' onClick={handlePopupClose}>
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
								stroke='#8A7DE7'
								stroke-width='1.5'
								stroke-linecap='round'
							/>
						</svg>
					</button>
				</div>
				<div className='requisites-popup-content'>
					<div>
						<strong>
							Для того, чтобы получить демо доступ к полному функционалу
							платформы на 3 дня, пожалуйста,{' '}
							<a href={'/register'}>зарегистрируйтесь</a>.
						</strong>
					</div>

					<ul>
						<div className={'requisites-popup-list-item'}>
							<div className='requisites-popup-content-stroke'>
								Если у вас возникли проблемы или вопросы по работе портала, вы
								можете написать нам в чат поддержки в личном кабинете.
							</div>
						</div>

						<div className={'requisites-popup-list-item'}></div>
					</ul>
				</div>
			</div>
		</Popup>
	)
}

import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react'

import CommonContext from 'services/AuthContext'
import { useNavigate } from 'react-router'
import { Header } from 'widgets/header'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Footer } from 'widgets/footer'
import AuthService from 'shared/services/auth/auth.service'
import { useGetCountries, useGetCurrencies } from 'features/utils/api'
import { LanguageSelect } from 'features/header/ui/language-select'

export const Layout: FC<PropsWithChildren> = ({ children }) => {
	const [isOpenMenu, setIsOpenMenu] = useState(false)
	const [isOpenProfile, setIsOpenProfile] = useState(false)
	const [isOpenSettings, setIsOpenSettings] = useState(false)
	const [countryList, setCountryList] = useState<any>(false)
	const [currentCurrency, setCurrentCurrency] = useState(false)
	const { authState, onChangeCurrencyApp } = useContext(CommonContext)

	const { data: countries } = useGetCountries()
	const { data: currencies } = useGetCurrencies()

	useEffect(() => {
		const selectOptions =
			countries && currencies
				? countries.map(country => {
						const currency = currencies.find(
							currency => +currency.country_id === country.id
						)
						return {
							value: country.id,
							flag: country.flag,
							label: `${currency?.code || ''} - ${country.name}`
						}
				  })
				: []
		setCountryList(selectOptions)
	}, [currencies, countries])

	const navigate = useNavigate()
	const navigateToMain = () => {
		navigate('/')
		toggleMenu()
	}
	const navigateToProfile = () => {
		navigate('/account/profile')
		toggleProfile()
	}

	const navigateLogout = async () => {
		await AuthService.logout()
		window.location.reload()
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const toggleMenu = () => {
		if (isOpenMenu) {
			setIsOpenMenu(false)
			document.getElementsByTagName('html')[0].classList.remove('hidden')
			document.body.classList.remove('hidden')
		} else {
			setIsOpenMenu(true)
			if (isOpenSettings) {
				setIsOpenSettings(false)
			}
			if (isOpenProfile) {
				setIsOpenProfile(false)
			}
			document.getElementsByTagName('html')[0].classList.add('hidden')
			document.body.classList.add('hidden')
		}
		setIsOpenMenu(!isOpenMenu)
	}
	const toggleSettings = (onClose?: boolean) => {
		if (isOpenSettings) {
			setIsOpenSettings(false)
			document.getElementsByTagName('html')[0].classList.remove('hidden')
			document.body.classList.remove('hidden')
		} else {
			setIsOpenSettings(true)
			if (isOpenMenu) {
				setIsOpenMenu(false)
			}
			if (isOpenProfile) {
				setIsOpenProfile(false)
			}
			document.getElementsByTagName('html')[0].classList.add('hidden')
			document.body.classList.add('hidden')
		}
	}

	const clickOnOverlay = () => {
		setIsOpenMenu(false)
		setIsOpenProfile(false)
		setIsOpenSettings(false)
		document.getElementsByTagName('html')[0].classList.remove('hidden')
		document.body.classList.remove('hidden')
	}

	const toggleProfile = () => {
		if (isOpenProfile) {
			setIsOpenProfile(false)
			document.getElementsByTagName('html')[0].classList.remove('hidden')
			document.body.classList.remove('hidden')
		} else {
			setIsOpenProfile(true)
			if (isOpenMenu) {
				setIsOpenMenu(false)
			}
			if (isOpenSettings) {
				setIsOpenSettings(false)
			}
			document.getElementsByTagName('html')[0].classList.add('hidden')
			document.body.classList.add('hidden')
		}
	}

	const onCloseSettings = () => {
		if (isOpenSettings) {
			setIsOpenSettings(false)
			document.getElementsByTagName('html')[0].classList.remove('hidden')
			document.body.classList.remove('hidden')
		}
	}

	return (
		<div className='App'>
			<Header
				openMenu={toggleMenu}
				openProfile={toggleProfile}
				onCloseSettings={onCloseSettings}
				openSettings={toggleSettings}
			/>
			<div
				onClick={clickOnOverlay}
				className={clsx(
					(isOpenMenu || isOpenProfile || isOpenSettings) && `header-overlay`
				)}
			></div>
			<div className={clsx('menu-list', isOpenMenu && `active`)}>
				<ul>
					<li>
						<p
							onClick={
								authState ? navigateToMain : () => window.location.reload()
							}
						>
							К поиску товаров
						</p>
					</li>
					<li>
						<Link to={''} onClick={() => window.location.reload()}>
							Дистрибьюторы
						</Link>
					</li>
					<li>
						<Link to={''} onClick={() => window.location.reload()}>
							Вендоры
						</Link>
					</li>
					<li>
						<Link to={''} onClick={() => window.location.reload()}>
							Сетевые магазины
						</Link>
					</li>
				</ul>
			</div>
			<div className={clsx('profile-list', isOpenProfile && 'active')}>
				<ul>
					<li>
						<p onClick={navigateToProfile}>Личный кабинет</p>
					</li>
					<li>
						<p onClick={navigateLogout}>Выйти из профиля</p>
					</li>
				</ul>
			</div>
			<LanguageSelect isOpen={isOpenSettings} onClose={onCloseSettings} />

			<main>{children}</main>
			<Footer />
		</div>
	)
}

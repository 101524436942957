import { axiosClassic, axiosInstance } from 'shared/api'
import { getSubscriptionUrl } from 'shared/api/config'
import {
	ISubscription,
	ISubscriptionUser,
	ITariffResponse
} from './types/subscription.types'

const SubscriptionService = {
	async getSubscription() {
		const response = await axiosInstance.get<ISubscriptionUser>(
			getSubscriptionUrl(`/`)
		)
		return response
	},

	async updateSubscription(data: ISubscription) {
		const response = await axiosInstance.put<ISubscription>(
			getSubscriptionUrl(`/`),
			data
		)
		return response
	},

	async requestSubscription(data: ISubscription) {
		const response = await axiosInstance.post<ISubscription>(
			getSubscriptionUrl(`/request/`),
			data
		)
		return response
	},

	async getSubscriptionTariff() {
		const response = await axiosInstance.get<ITariffResponse>(
			getSubscriptionUrl(`/tariff/`)
		)
		return response
	}
}

export default SubscriptionService

import React, { FC, PropsWithChildren } from 'react'
import { Provider as AlertProvider, positions, transitions } from 'react-alert'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { AlertTemplate } from 'shared/ui/alert-template'
import { PopupUtils } from 'shared/ui/popup-utils'
import { QueryClientProvider } from './react-query'

const optionsAlert = {
	position: positions.BOTTOM_RIGHT,
	timeout: 7000,
	offset: '30px',
	transition: transitions.SCALE,
	containerStyle: { zIndex: 10000 }
}

export const MainProvider: FC<PropsWithChildren> = ({ children }) => {
	return (
		<AlertProvider template={AlertTemplate} {...optionsAlert}>
			<PopupUtils />
			<QueryClientProvider>{children}</QueryClientProvider>
		</AlertProvider>
	)
}

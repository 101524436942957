import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import 'assets/landing.scss'
import AboutImg from 'assets/about-img.png'
import AboutServicesImg1 from 'assets/about-services-img1.png'
import AboutServicesImg2 from 'assets/about-services-img2.png'
import AboutServicesImg3 from 'assets/about-services-img3.png'
import AboutServicesImg4 from 'assets/about-services-img4.png'
import SuppliersAbout from 'assets/about-about-img.png'
import blob1 from 'assets/suppliers-blob1.svg'
import blob2 from 'assets/suppliers-blob2.svg'
import blob3 from 'assets/suppliers-blob3.svg'
import LandingPrice from 'entities/buyers/landing-price'
import CommonContext from 'services/AuthContext'

const Buyers = () => {
	const { authState } = useContext(CommonContext)
	const [isModalVisible, setModalVisible] = useState(false)

	const openModal = () => {
		setModalVisible(true)
	}

	const closeModal = () => {
		setModalVisible(false)
	}

	return (
		<>
			<section className='landing-banner about-banner'>
				<div className='container'>
					<div className='landing-banner__block'>
						<div className='landing-banner__info'>
							<h1>
								<span>Увеличьте прибыль</span>
								за счет товаров и услуг из любой точки мира
							</h1>
							<p>
								KIRA - система поиска товаров и услуг дистрибьюторов <br />
								со всего мира. <br />
								Удобный поиск, актуальная информация, сеть партнеров. <br />
								Все - что требуется для покупки и логистики.
							</p>
							<button type='button' onClick={openModal}>
								Подключиться бесплатно
							</button>
						</div>
						<div className='landing-banner__img'>
							<img src={AboutImg} alt='Поставщикам' />
						</div>
					</div>
				</div>
			</section>

			<section className='landing-section2'>
				<div className='container'>
					<div className='landing-title'>
						<h2>Готовые решения - на всех этапах:</h2>
					</div>
					<div className='landing-section2__list'>
						<div className='landing-section2-item'>
							<div className='landing-section2-item__icon'>
								<span>
									<svg
										width='46'
										height='47'
										viewBox='0 0 46 47'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M34.5 35L40.25 40.75'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
										<path
											d='M14.0156 8.46159C16.4472 7.05502 19.2702 6.25 22.2812 6.25C31.4112 6.25 38.8125 13.6513 38.8125 22.7813C38.8125 31.9112 31.4112 39.3125 22.2812 39.3125C13.1513 39.3125 5.75 31.9112 5.75 22.7812C5.75 19.7702 6.55502 16.9472 7.96159 14.5156'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
									</svg>
								</span>
							</div>
							<div className='landing-section2-item__title'>
								1. Поиск товара
							</div>
							<div className='landing-section2-item__text'>
								Поиск товара с помощью удобной формы. Введите в строку артикул
								или часть названия и KIRA найдет нужный товар за несколько
								секунд.
							</div>
						</div>
						<div className='landing-section2-item'>
							<div className='landing-section2-item__icon'>
								<span>
									<svg
										width='46'
										height='47'
										viewBox='0 0 46 47'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M3.83301 11.0417L6.16039 13.9167L14.3747 6.25'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M3.83301 24.4582L6.16039 27.3332L14.3747 19.6665'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M3.83301 37.8752L6.16039 40.7502L14.3747 33.0835'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M42.1667 23.5L32.5833 23.5M23 23.5H25.875'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
										<path
											d='M23 36.9165H32.5833M39.2917 36.9165H42.1667'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
										<path
											d='M42.1667 10.0835L23 10.0835'
											stroke='white'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
									</svg>
								</span>
							</div>
							<div className='landing-section2-item__title'>
								2. Создайте список
							</div>
							<div className='landing-section2-item__text'>
								Специальная форма поможет собрать предложения от разных
								поставщиков из разных стран.
							</div>
						</div>
						<div className='landing-section2-item'>
							<div className='landing-section2-item__icon'>
								<span>
									<svg
										width='46'
										height='46'
										viewBox='0 0 46 46'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<circle
											cx='21.0837'
											cy='11.5002'
											r='7.66667'
											stroke='#fff'
											strokeWidth='2.5'
										/>
										<path
											d='M32.583 19.7415C33.581 20.6397 34.1406 21.1433 35.1386 22.0415L40.2497 16.2915'
											stroke='#fff'
											strokeWidth='2.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M36.4118 34.4998C36.4167 34.1852 36.4167 33.8654 36.4167 33.5415C36.4167 28.778 29.5517 24.9165 21.0833 24.9165C12.615 24.9165 5.75 28.778 5.75 33.5415C5.75 38.305 5.75 42.1665 21.0833 42.1665C25.3594 42.1665 28.443 41.8662 30.6667 41.3297'
											stroke='#fff'
											strokeWidth='2.5'
											strokeLinecap='round'
										/>
									</svg>
								</span>
							</div>
							<div className='landing-section2-item__title'>
								3. Выберите лучшее предложение
							</div>
							<div className='landing-section2-item__text'>
								Наши партнеры помогут выкупить товар у дистрибьютора за
								небольшое вознаграждение и отправят товар на ваш склад.
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='landing-section3'>
				<div className='container'>
					<div className='landing-section3__list'>
						<div className='landing-section3-item'>
							<div className='landing-section3-item__img'>
								<img
									src={AboutServicesImg1}
									alt='Поиск новых поставщиков и партнеров'
								/>
							</div>
							<div className='landing-section3-item__text'>
								Ищите товары или станьте партнером и получайте заказы
							</div>
						</div>
						<div className='landing-section3-item'>
							<div className='landing-section3-item__img'>
								<img src={AboutServicesImg2} alt='Актуальная информация' />
							</div>
							<div className='landing-section3-item__text'>
								Работайте в команде - принимайте решения онлайн обсуждая закупки
								с коллегами
							</div>
						</div>
						<div className='landing-section3-item'>
							<div className='landing-section3-item__img'>
								<img
									src={AboutServicesImg3}
									alt='Возможность провести одноразовую сделку'
								/>
							</div>
							<div className='landing-section3-item__text'>
								Анализируйте цены на товары по всему миру
							</div>
						</div>
						<div className='landing-section3-item'>
							<div className='landing-section3-item__img'>
								<img
									src={AboutServicesImg4}
									alt='Увеличивается скорость принятия решений'
								/>
							</div>
							<div className='landing-section3-item__text'>
								Находите то, что раньше было не доступно
							</div>
						</div>
					</div>

					<div className='landing-section3__link'>
						<Link to='/'>
							Искать бесплатно
							<svg
								width='30'
								height='30'
								viewBox='0 0 30 30'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M5 15H8.125M25 15L17.5 7.5M25 15L17.5 22.5M25 15H11.875'
									stroke='#8A7DE7'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</Link>
					</div>
				</div>
			</section>
			<section className='landing-section4'>
				<div className='container'>
					<div className='landing-section4__row'>
						<div className='landing-section4__info'>
							<h2>Будьте на шаг впереди</h2>
							<p>
								С поисковой системой KIRA вы будете получать актуальные данные
								по ценам на товары, складским остаткам, транзитам и др.
								информацию в несколько кликов.
							</p>
							<p>
								Мы продумали множество сценариев работы с системой и не
								ограничились поиском товаров и поставщиков. KIRA дает
								возможность каждому найти нужный товар в любой точке мира и с
								легкостью заказать его напрямую или через партнеров площадки.
							</p>
							<p>
								Вы сможете проанализировать цены конкурентов и поставщиков,
								узнать в какой стране покупать товары выгодно сегодня или кому
								продать свои товары, залежавшиеся на складе. Все это возможно
								благодаря уникальной системе исследования рынка, которые
								позволяют провести анализ в несколько кликов.
							</p>
						</div>
						<div className='landing-section4__img'>
							<img src={SuppliersAbout} alt='Современное облачное решение' />
						</div>
					</div>
				</div>
			</section>
			<section className='banner-support'>
				<div className='container'>
					<div className='banner-support__block'>
						<div className='blob blob1'>
							<img src={blob1} />
						</div>
						<div className='blob blob2'>
							<img src={blob2} />
						</div>
						<div className='blob blob3'>
							<img src={blob3} />
						</div>
						<h2>Начните работать прямо сейчас</h2>
						<div className='banner-support__btn'>
							<Link to={authState ? '/account/profile' : '/register/'}>
								Зарегистрироваться
							</Link>
						</div>
					</div>
				</div>
			</section>
			<LandingPrice isOpen={isModalVisible} onClose={closeModal} />
		</>
	)
}

export default Buyers

import { FC } from 'react'
import 'app/styles/modal.scss'
import { useOutsideClick } from 'shared/hooks'

interface IProps {
	isOpen: boolean
	onClose: () => void
	method?: Record<string, any>
	text?: string
}

const TechWarning: FC<IProps> = ({ isOpen, onClose, method, text }) => {
	const { ref } = useOutsideClick(onClose)

	const title = {
		xml: 'API',
		excel: 'API',
		email: 'рассылке по email',
		api: 'API',
		google: 'GoogleSheets',
		myStock: 'ресурсу МойСклад'
	}
	const [key] = Object.entries(method || {}).find(([key, value]) => value) || []

	return (
		<>
			<div className={`modal modal-warning ${isOpen ? 'show' : 'hide'}`}>
				<div className='modal__window' ref={ref}>
					<div className='modal__header'></div>
					<div className='modal__text'>
						<p>
							{text || (
								<>
									Приносим извинения, подключение по {/* @ts-ignore */}
									{title[key]} в данный момент недоступно
								</>
							)}
						</p>
						<button type='button' className='btn' onClick={onClose}>
							Ок
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default TechWarning

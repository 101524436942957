import { axiosInstance } from 'shared/api'
import { getProductUrl } from 'shared/api/config'
import { IProductResponse, IProductFilter } from './types/products.types'
import { fetchParams } from 'shared/utils'

const ProductService = {
	async getList(data?: IProductFilter) {
		const response = await axiosInstance.get<IProductResponse>(
			getProductUrl(`/`),
			{ params: fetchParams(data) }
		)
		return response
	}
}

export default ProductService

import Cookies from 'js-cookie'
import LoginPage from 'pages/login'
import React, { PropsWithChildren } from 'react'

interface IProps extends PropsWithChildren {
	noAuth?: boolean
}

export const ProtectedRoute: React.FC<IProps> = ({ children, noAuth }) => {
	// const navigate = useNavigate()
	// const { pathname } = useLocation()

	const refresh = Cookies.get('kira_token')

	if (!refresh && !noAuth) return <LoginPage />
	return <>{children}</>
}

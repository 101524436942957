import RegisterForm from 'features/auth/ui/register'
import React from 'react'
import { Layout } from 'shared/ui/layout'

const RegisterPage = () => {
	return (
		<Layout>
			<RegisterForm />
		</Layout>
	)
}

export default RegisterPage

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import ProductService from 'shared/services/product.service'
import {
	IProductFilter,
	IProductResponse
} from 'shared/services/types/products.types'

export const useProductList = (
	filter?: IProductFilter,
	page_size: number = 50
) => {
	const queryClient = useQueryClient()

	const [dataList, setDataList] = useState<IProductResponse | null>(null)

	const productList = useInfiniteQuery({
		queryKey: ['get product list', filter],
		initialPageParam: 1,
		enabled: !!filter?.search,
		queryFn: ({ pageParam = 1 }) =>
			ProductService.getList({
				...filter!,
				page: pageParam,
				page_size: page_size
			}),
		getNextPageParam: (lastPage, allPages) => allPages.length + 1,
		retry: 0,
		select: data => {
			const res = data.pages.map(item => ({
				...item.data
			}))

			return { ...data, pages: res }
		}
	})

	useEffect(() => {
		const { data, isPending } = productList

		const result = data?.pages.reduce((acc, page) => {
			return {
				...page,
				results: [...(acc?.results || []), ...(page?.results || [])]
			}
		}, {} as any)

		if (!isPending) setDataList(result)
	}, [productList.data?.pages, productList.isPending])

	return {
		productList: {
			...productList,
			data: {
				...productList.data,
				...dataList
			}
		}
	}
}

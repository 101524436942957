import axios from 'axios'
import {
	FC,
	PropsWithChildren,
	createContext,
	useEffect,
	useState
} from 'react'
import { axiosInstance } from '../shared/api'
import { backend_url } from '../shared/api/config'
import Cookies from 'js-cookie'

interface ContextProps {
	authState: boolean
	logout: any
	register: any
	emailRecovery: any
	passwordRecovery: any
	passwordChange: any
	errorState: any
	setErrorState: any
	currentCurrency: any
	onChangeCurrencyApp: any
	setCurrentCurrency: any
}

const defaultValue = {
	authState: false,
	currentCurrency: 'en',
	emailRecovery: () => {},
	passwordRecovery: () => {},
	passwordChange: () => {},
	setErrorState: () => {},
	onChangeCurrencyApp: () => {},
	setCurrentCurrency: () => {}
} as ContextProps

const CommonContext = createContext<ContextProps>(defaultValue)

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const token = Cookies.get('kira_token')
	const KIRA_CURRENCY_DEFAULT = (Cookies.get('kira_currency') || '{}') as string
  const KIRA_CURRENCY = JSON.parse(KIRA_CURRENCY_DEFAULT)
	const [currentCurrency, setCurrentCurrency] = useState(KIRA_CURRENCY)
	const [errorState, setErrorState] = useState(false)
	const [authState, setAuthState] = useState(!!token)

	useEffect(() => {
		setCurrentCurrency(KIRA_CURRENCY)
	}, [KIRA_CURRENCY_DEFAULT])

	const updateAuthState = () => {
		const token = Cookies.get('kira_token')

		setAuthState(!!token)
	}

	useEffect(() => {
		window.addEventListener('cookiechange', updateAuthState)
		return () => {
			window.removeEventListener('cookiechange', updateAuthState)
		}
	}, [])

	const register = async (data: any) => {
		await axios.post(`${backend_url}/user/register/`, data, {
			withCredentials: true
		})
	}

	const logout = async () => {
		return await axiosInstance
			.get('/user/logout/')
			.then(res => {
				localStorage.removeItem('refresh')
				// navigate(`/login`);
			})
			.catch(error => console.log(error))
	}

	const emailRecovery = async (data: any) => {
		return await axios
			.post(`${backend_url}/user/password/forgot/email/`, data)
			.then(res => {
				// navigate("/login");
			})
			.catch(error => console.log(error))
	}

	const passwordRecovery = async (data: any, uidb64: string, token: string) => {
		return await axios
			.post(`${backend_url}/user/password/forgot/${uidb64}/${token}/`, data)
			.then(res => {
				// navigate("/login");
			})
			.catch(error => console.log(error))
	}

	const passwordChange = async (data: any) => {
		return await axiosInstance
			.put('/user/password/change/', data)
			.catch(error => console.log(error))
	}

	const onChangeCurrencyApp = (data: string) => {
		setCurrentCurrency(data)
	}

	return (
		<CommonContext.Provider
			value={{
				logout,
				register,
				emailRecovery,
				passwordRecovery,
				passwordChange,
				errorState,
				setErrorState,
				authState,
				currentCurrency,
				onChangeCurrencyApp,
				setCurrentCurrency
			}}
		>
			{children}
		</CommonContext.Provider>
	)
}

export default CommonContext


import { useValueCurrency } from 'shared/hooks'

export const CurrencyBlock = () => {

	const { getCountCurrency, currenciesExchange } = useValueCurrency()

	return (
		<div className='product-page-currencies'>
			<p>{currenciesExchange ? <>$ {getCountCurrency('USD')}</> : <></>}</p>
			<p>{currenciesExchange ? <>€ {getCountCurrency('EUR')}</> : <></>}</p>
		</div>
	)
}

import { FC } from 'react'

interface IProps {
	color?: string
	height: string
	width: string
}

export const FolderIcon: FC<IProps> = ({ color, height, width }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 25 26'
			fill='none'
		>
			<path
				d='M10.417 15.0833H12.5003M12.5003 15.0833H14.5837M12.5003 15.0833V17.1667M12.5003 15.0833V13'
				stroke={color}
				strokeLinecap='round'
			/>
			<path
				d='M22.9163 12.7892C22.9163 10.0471 22.9163 8.67608 22.1148 7.78492C22.0411 7.70294 21.9631 7.62493 21.8811 7.5512C20.9899 6.74967 19.6189 6.74967 16.8769 6.74967H16.4876C15.2859 6.74967 14.685 6.74967 14.125 6.59007C13.8174 6.50239 13.521 6.37958 13.2415 6.22408C12.7327 5.941 12.3078 5.51611 11.458 4.66634L10.8848 4.09316C10.6 3.80835 10.4576 3.66594 10.3079 3.54188C9.66272 3.007 8.87119 2.67914 8.03673 2.60111C7.84318 2.58301 7.64178 2.58301 7.23899 2.58301C6.31969 2.58301 5.86004 2.58301 5.47716 2.65525C3.79166 2.97326 2.47326 4.29166 2.15525 5.97716C2.08301 6.36004 2.08301 6.81969 2.08301 7.73899M22.9073 17.1663C22.8699 19.7492 22.6783 21.2136 21.696 22.196C20.4756 23.4163 18.5114 23.4163 14.583 23.4163H10.4163C6.48797 23.4163 4.52378 23.4163 3.3034 22.196C2.08301 20.9756 2.08301 19.0114 2.08301 15.083V11.958'
				stroke={color}
				strokeLinecap='round'
			/>
		</svg>
	)
}

import clsx from 'clsx'
import React, { FC, PropsWithChildren } from 'react'

interface IProps extends PropsWithChildren {
	text: string
	className?: string
	align?: 'left' | 'right'
}

export const PromptText: FC<IProps> = ({
	text,
	children,
	align,
	className
}) => {
	return (
		<>
			<div className={clsx('input-note', className)}>
				{children || (
					<svg
						width='18'
						height='18'
						viewBox='0 0 18 18'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M7.59375 6.65625C7.59375 5.8796 8.22335 5.25 9 5.25C9.77665 5.25 10.4062 5.8796 10.4062 6.65625C10.4062 7.17183 10.1288 7.62261 9.71502 7.86741C9.35853 8.07832 9 8.39829 9 8.8125V9.75'
							stroke='#777777'
							strokeLinecap='round'
						/>
						<circle cx='9' cy='12' r='0.75' fill='#777777' />
						<path
							d='M5.25 2.50337C6.35315 1.86523 7.63392 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 7.63392 1.86523 6.35315 2.50337 5.25'
							stroke='#777777'
							strokeLinecap='round'
						/>
					</svg>
				)}

				<div className={clsx('input-note-desc', align === 'right' && 'right')}>
					{text}
				</div>
			</div>
		</>
	)
}

import { FC, useEffect } from 'react'
import './ProfilePartnersCapacities.scss'
import 'assets/input.scss'
import { useAlertMessage } from 'shared/hooks'
import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { Input } from 'shared/ui/form/input'
import { ReactSelectMulti } from 'shared/ui/form/react-select-multi'
import { usePartnersActions } from 'features/partners/api/usePartnersActions'
import { PromptText } from 'shared/ui/prompt-text'

const serviceCenter = [
	{ value: 'mow', label: 'Москва' },
	{ value: 'sbp', label: 'Санкт-Петербург' }
]

interface IProps {
	defaultData: any
	methods: UseFormReturn<any, any, undefined>
}

const ProfilePartnersCapacities: FC<IProps> = ({ defaultData, methods }) => {
	const { addAlertMessage } = useAlertMessage()
	const {
		watch,
		control,
		formState: { errors }
	} = methods

	const distributors = watch('distributors')

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'vendors'
	})

	const { updatePartner } = usePartnersActions()

	useEffect(() => {
		if (defaultData) {
			methods.reset({
				...methods.getValues(),
				service_centers: defaultData?.service_centers.join(','),
				vendors: defaultData?.vendors || []
			})
		}
	}, [defaultData])

	const onSubmit = () => {
		methods.trigger()

		setTimeout(() => {
			const value = methods.getValues()
			const error = methods.formState.errors
			const dataObject = {
				service_centers: value?.service_centers,
				vendors: value?.vendors?.map((item: any) => ({
					vendor: item.vendor,
					status: item.status
				}))
			}

			const isError = Object.keys(dataObject).find(item => {
				return error[item]
			})

			if (isError) return null

			updatePartner
				//@ts-ignore
				.mutateAsync(dataObject)
				.then(() => addAlertMessage('Сохранено', 'success'))
				.catch(err => {
					addAlertMessage(err, 'error')
				})
		}, 100)
	}

	const addVendor = () => {
		append({ name: '', status: '' })
	}

	const removeBill = (id: number) => {
		remove(id)
	}

	useEffect(() => {
		// methods.setValue('distributors', distributors)
	}, [distributors?.length])

	return (
		<>
			<div className='capacities-form'>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '10px',
						paddingBottom: '1rem',
						paddingRight: '10px'
					}}
				>
					<p>
						Наличие партнерских отношений с вендорами<span>*</span>
					</p>
					<div style={{ position: 'relative', width: '15px', height: '15px' }}>
						<PromptText
							align={'right'}
							text={
								'Укажите бренд + партнерский статус, если они есть. Это поможет пользователю принять решение о сотрудничестве.'
							}
						/>
					</div>
				</div>

				{fields?.map((input, index) => {
					return (
						<div key={index} className='inputs-row'>
							<Input
								control={control}
								name={`vendors.${index}.vendor`}
								label='Вендор'
								placeholder='Вендор'
								required
								// @ts-ignore
								error={errors?.vendors?.[index]?.vendor?.message}
								rules={{ required: 'Обязательное поле для заполнения' }}
							/>
							<Input
								control={control}
								name={`vendors.${index}.status`}
								label='Статус'
								required
								placeholder='Статус'
								// @ts-ignore
								error={errors?.vendors?.[index]?.status?.message}
								rules={{ required: 'Обязательное поле для заполнения' }}
							/>

							{fields?.length && (
								<button
									type='button'
									className='remove-row'
									onClick={() => removeBill(index)}
								>
									<svg
										width='9'
										height='9'
										viewBox='0 0 9 9'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
										preserveAspectRatio='none'
									>
										<path
											d='M8 1.00003L1.00003 8M1 1L7.99997 7.99997'
											stroke='#8A7DE7'
											strokeOpacity='1'
											strokeLinecap='round'
										/>
									</svg>
								</button>
							)}
						</div>
					)
				})}

				<div style={{ marginBottom: '1rem' }}>
					<button className='btn mobi-full' type='button' onClick={addVendor}>
						Добавить
					</button>
				</div>

				<Input
					control={control}
					name={`service_centers`}
					className='service_center'
					label='Наличие сервисного центра'
					required
					placeholder='Наличие сервисного центра'
					error={errors}
					info='Укажите город, если сервисный центр один или страну если сервисных центров много.'
				/>

				{/* <div className='select-block'>
					<p>
						Наличие сервисного центра, куда клиент может обратится за помощью
					</p>

					<ReactSelectMulti
						control={control}
						options={serviceCenter}
						name='service_centers'
						messageNoOption='Нет сервисных центров'
						placeholder=''
						error={errors}
					/>
				</div> */}

				{/* <label className="important">
          Наличие партнерских отношений с поставщиками
        </label>
        {vendorsList.map((input) => {
          return (
            <div key={input.id} className="two-on-line">
              <div>
                <p>Поставщик</p>
                <select
                  value={input.vender}
                  onChange={(e) => handleVendorChange(e, input.id)}
                >
                  <option>...</option>
                  {distributors ? (
                    distributors.map((i) => (
                      <option data-key={i.id}>{i.name}</option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
              </div>
              <div>
                <p>Статус</p>
                <input
                  type="text"
                  value={input.status}
                  onChange={(e) => handleStatusChange(e, input.id)}
                  required
                />
              </div>
            </div>
          );
        })}
        <button type="button" onClick={addVendor}>
          Добавить
        </button> */}

				{/* <button
          className="distributor-request"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          Отправить запрос
        </button> */}

				{/* <label className="important">Выбранные вами службы доставки</label>
        {deliveryList.map((input) => {
          return (
            <div key={input.id} className="two-on-line">
              <div>
                <p>Служба доставки</p>
                <select
                  value={input.name}
                  // onChange={(e) => handleDeliveryChange(e, input.id)}
                >
                  <option>...</option>
                  {deliveryServices ? (
                    deliveryServices.map((i) => <option>{i.name}</option>)
                  ) : (
                    <></>
                  )}
                </select>
              </div>
              <div>
                <p>Статус</p>
                <input
                  type="text"
                  value={input.deliveryStatus}
                  // onChange={(e) => handleDeliveryStatusChange(e, input.id)}
                  required
                />
              </div>
            </div>
          );
        })}
        <button type="button" onClick={()=>{}}>
          Добавить
        </button>
        <label>Текстовое поле для инструкции</label>
        <p>
          Расскажите с чего начать клиенту, как ему оформить заказ в максимально
          короткие сроки, какие документы подготовить для сделки.
        </p>
        <textarea
          placeholder="..."
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
        /> */}
				{defaultData && (
					<div className='block-save'>
						<button className='btn' type='button' onClick={onSubmit}>
							Сохранить
						</button>
					</div>
				)}
			</div>
		</>
	)
}

export default ProfilePartnersCapacities

import { useQuery, useQueryClient } from '@tanstack/react-query'
import ProjectService from 'shared/services/project.service'
import SupplierService from 'shared/services/supplier.service'
import { IProjectFilter } from 'shared/services/types/project.types'
import { ISupplierFilter } from 'shared/services/types/supplier.types'

export const useGetDistributer = (filter?: ISupplierFilter) => {
	const queryClient = useQueryClient()

	return useQuery({
		queryFn: () => SupplierService.getDistributors(filter),
		queryKey: ['get distributors list'],
		select: ({ data }) => data,
		retry: 0
	})
}

export const useGetDistributerById = (id?: string) => {
	const queryClient = useQueryClient()

	return useQuery({
		queryFn: () => SupplierService.getDistributorById(id!),
		queryKey: ['get distributor', id],
		enabled: !!id,
		select: ({ data }) => data,
		retry: 0
	})
}

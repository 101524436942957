import { FC, useState } from 'react'
import './LanguagesFlags.scss'
import RUFlag from 'assets/flags/RU.svg'
import { useOutsideClick } from 'shared/hooks'
import { LANG_LIST } from 'shared/constants'

interface IProps {
	openSettings: any
}

const LanguagesFlags: FC<IProps> = ({ openSettings }) => {
	const [isShow, setIsShow] = useState(false)
	const { ref } = useOutsideClick(setIsShow)
	const [selectedFlagSrc, setSelectedFlagSrc] = useState(RUFlag)

	const handleLanguageOptionClick = (flagSrc: any) => {
		setSelectedFlagSrc(flagSrc)
		setIsShow(false)
	}

	return (
		<div
			className={`select-language ${isShow ? 'open' : ''}`}
			ref={ref}
			onClick={openSettings}
		>
			<div className='selected-result' onClick={() => setIsShow(!isShow)}>
				<img src={selectedFlagSrc} alt='Selected Flag' />
			</div>
			<div className='languages-options'>
				{LANG_LIST?.map(i => (
					<div
						className='language-option'
						onClick={() => handleLanguageOptionClick(i.flag)}
						key={i.id}
					>
						<img src={i.flag} alt={i.name} />
						{i.name}
					</div>
				))}
			</div>
		</div>
	)
}

export default LanguagesFlags

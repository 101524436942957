import { useQuery } from '@tanstack/react-query'
import SupplierService from 'shared/services/supplier.service'
import { IReviewCheck } from 'shared/services/types/supplier.types'

export const useCheckReview = (data: IReviewCheck) => {

	const getCheckReview = useQuery({
		queryKey: ['getCheckReview', data],
		queryFn: () => SupplierService.getCheckReview(data),
		retry: 0,
		enabled: !!data.supplier_id,
		select: ({ data }) => data
	})

	return getCheckReview
}

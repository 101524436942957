import { axiosClassic } from 'shared/api'
import { getUtilsUrl } from 'shared/api/config'
import {
	ICurrenciesExchange,
	IDataCountry,
	IDataCurrencies,
	IDataDelivery,
	IDataLanguage
} from './types/utils.types'

const UtilsService = {
	async getCountries() {
		const response = await axiosClassic.get<IDataCountry[]>(
			getUtilsUrl(`/countries/`)
		)
		return response
	},

	async getCurrencies() {
		const response = await axiosClassic.get<IDataCurrencies[]>(
			getUtilsUrl(`/currencies/`)
		)
		return response
	},

	async getCurrenciesExchange() {
		const response = await axiosClassic.get<ICurrenciesExchange>(
			getUtilsUrl(`/currencies/exchange/`)
		)
		return response
	},

	async getDeliveryServices() {
		const response = await axiosClassic.get<IDataDelivery[]>(
			getUtilsUrl(`/delivery_services/`)
		)
		return response
	},

	async getLanguages() {
		const response = await axiosClassic.get<IDataLanguage[]>(
			getUtilsUrl(`/languages/`)
		)
		return response
	}
}

export default UtilsService

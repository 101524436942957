import { format } from 'date-fns'

export const transformDate = (stringDate: string) => {
	if (!stringDate) return ''

	const date = new Date(stringDate)
	const formattedDate = format(date, 'dd.MM.yyyy')

	return formattedDate
}

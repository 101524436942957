import { FC, useEffect, useState } from 'react'
import './VerificationStatus.scss'
import { FaCheck, FaQuestion } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

interface IProps {
	status?: 'PROCESSING' | 'SUCCESS' | 'FAILED'
}

const icons = {
	SUCCESS: <FaCheck />,
	FAILED: (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
		>
			<path
				d='M16.9173 11.083L11.084 16.9163M11.084 11.083L16.9173 16.9163'
				stroke='white'
				strokeWidth='1.5'
				strokeLinecap='round'
			/>
			<path
				d='M8.16732 3.8938C9.88333 2.90114 11.8756 2.33301 14.0007 2.33301C20.444 2.33301 25.6673 7.55635 25.6673 13.9997C25.6673 20.443 20.444 25.6663 14.0007 25.6663C7.55733 25.6663 2.33398 20.443 2.33398 13.9997C2.33398 11.8747 2.90212 9.88235 3.89478 8.16634'
				stroke='white'
				strokeWidth='1.5'
				strokeLinecap='round'
			/>
		</svg>
	),
	PROCESSING: <FaQuestion />
}

const VerificationStatus: FC<IProps> = ({ status }) => {
	const [statusText, setStatusText] = useState('Данные проверяются')
	useEffect(() => {
		switch (status) {
			case 'SUCCESS':
				setStatusText('Данные проверенны')
				break
			case 'FAILED':
				setStatusText('Ошибка при проверке')

				break
			default:
				setStatusText('Данные проверяются')
				break
		}
	}, [status])

	return (
		<div className='verification-status'>
			<div className='verification-status__status'>
				{icons[status!] || <FaQuestion />}

				{statusText}
			</div>
			<div className='verification-status__info'>
				<DescriptionStatus status={status} />
			</div>
		</div>
	)
}

export default VerificationStatus

const DescriptionStatus: FC<IProps> = ({ status }) => {
	if (status === 'SUCCESS')
		return (
			<span>
				Данные были успешно проверены. Ваша анкета уже участвует в выдаче по
				подподбору поставщиков.
			</span>
		)

	if (status === 'FAILED') {
		return (
			<span>
				Мы не смогли проверить указанные вами данные.{' '}
				<Link to={'/account/chat'}>
					<br />
					Свяжитесь с поддержкой
				</Link>{' '}
				для уточнения информации.
			</span>
		)
	}
	return (
		<span>
			В течении 24 часов наши специалисты проверят ваши данные. Если у вас
			возникнут вопросы, вы можете{' '}
			<Link to={'/account/chat'}>написать в поддержку.</Link>
		</span>
	)
}

export const getCorrectDate = (date?: Date | null, nextDay?: boolean) => {
	if (!date) return null

	const newDate = new Date(date) // Создаем новый объект даты на основе переданной даты

	if (nextDay) {
		newDate.setDate(newDate.getDate() + 1) // Увеличиваем день на 1, если nextDay равен true
	}

	const day = newDate.getDate().toString().padStart(2, '0')
	const month = (date.getMonth() + 1).toString().padStart(2, '0')
	const year = date.getFullYear()
	const formattedDate = `${year}-${month}-${day}`
	return formattedDate
}

import React, { useEffect, useRef, useState } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './Popup.scss'
import { axiosInstance } from '../../../../shared/api'
import { FolderIcon } from '../../../../assets/ProductsIcon'
import ClosedDropdown from '../../../../entities/profile/profile-dropdown'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { FoldersProjectSelect } from '../../../../shared/ui/form/custom-select'
import { useProjectsActions } from 'features/projects/api/useProjectsActions'
import { useAlertMessage, useOutsideClick } from 'shared/hooks'
import { useGetProject } from 'features/projects/api/useGetProjects'
import { usePickPartners } from 'features/partners/api/usePickPartners'
import { useHelpAction } from 'features/help/api/useHelp'
import ContactPartner from 'features/partners/ui/contact-partners'
import { useUserActionById } from 'features/profile/api/useUserActionById'
import { useUpdateUser } from 'features/profile/api/useUpdateUser'
import { useProjectByIdActions } from 'features/projects/api/useProjectByIdActions'
import { useGetLanguages } from 'features/utils/api'
import clsx from 'clsx'

export const ProfilePopup = ({ isOpen, setIsOpen, data }) => {
	const [errorData, setErrorData] = useState()

	const handlePopupClose = () => {
		setIsOpen(false)
	}

	const subscriptionRequest = async data => {
		await axiosInstance
			.post('/subscription/request/', data)
			.then(answer => {
				setErrorData(answer.data)
			})
			.catch(error => {
				setErrorData(error.response.data.detail)
				console.log(error)
			})
	}

	return (
		<Popup
			className='tariff-popup-base'
			open={isOpen}
			closeOnDocumentClick
			onClose={handlePopupClose}
			contentStyle={{
				borderRadius: '4px',
				background: '#fff'
			}}
			overlayStyle={{
				background: 'rgba(0, 0, 0, 0.5)'
			}}
		>
			<div>
				<div className='tariff-popup-header'>
					<p>Тариф: {data.name}</p>
					<button onClick={handlePopupClose}>&#10006;</button>
				</div>
				<div className='tariff-popup-content'>
					<p>
						Захватывающее предложение, от которого невозможно отказаться:
						оплатите тариф вперед со скидкой!
					</p>
					<div className='tariff-popup-content-layers'>
						<div className='layer'>
							<div className='layer-header'>
								<h1>Месяц</h1>
							</div>
							<p>{data.price} ₽</p>
							<button
								onClick={async () => {
									let getData = {
										tariff: {
											id: data.id
										},
										months: 1,
										price: data.price
									}
									await subscriptionRequest(getData)
								}}
							>
								Активировать
							</button>
						</div>
						<div className='layer'>
							<div className='layer-header'>
								<h1>за 3 МЕСЯЦА</h1>
								<p>{data.sales_prices[3].full} ₽</p>
							</div>
							<div>
								<p>{data.sales_prices[3].one} ₽</p>
								<p>МЕСЯЦ</p>
							</div>
							<button
								onClick={async () => {
									let getData3 = {
										tariff: {
											id: data.id
										},
										months: 3,
										price: data.sales_prices[3].full
									}
									await subscriptionRequest(getData3)
								}}
							>
								Активировать
							</button>
						</div>
						<div className='layer'>
							<div className='layer-header'>
								<h1>за 6 МЕСЯЦЕВ</h1>
								<p>{data.sales_prices[6].full} ₽</p>
							</div>
							<div>
								<p>{data.sales_prices[6].one} ₽</p>
								<p>МЕСЯЦ</p>
							</div>
							<button
								onClick={async () => {
									let getData6 = {
										tariff: {
											id: data.id
										},
										months: 6,
										price: data.sales_prices[6].full
									}
									await subscriptionRequest(getData6)
								}}
							>
								Активировать
							</button>
						</div>
						<div className='layer'>
							<div className='layer-header'>
								<h1>за 12 МЕСЯЦЕВ</h1>
								<p>{data.sales_prices[12].full} ₽</p>
							</div>
							<div>
								<p>{data.sales_prices[12].one} ₽</p>
								<p>МЕСЯЦ</p>
							</div>
							<button
								onClick={async () => {
									let getData12 = {
										tariff: {
											id: data.id
										},
										months: 12,
										price: data.sales_prices[12].full
									}
									await subscriptionRequest(getData12)
								}}
							>
								Активировать
							</button>
						</div>
					</div>
				</div>
				{errorData && (
					<p
						style={{
							textAlign: 'center',
							marginBottom: '10px',
							fontWeight: '500',
							fontSize: '125%',
							color: '#8A7DE7'
						}}
					>
						{errorData}
					</p>
				)}
			</div>
		</Popup>
	)
}

export const AddDistributorPopup = ({ isOpen, setIsOpen }) => {
	const { addNewDistributer } = useHelpAction()
	const { addAlertMessage } = useAlertMessage()
	const [response, setResponse] = useState()
	const nameRef = useRef()
	const urlRef = useRef()
	const messageRef = useRef()

	const handlePopupClose = () => {
		setIsOpen(false)
		// setResponse()
	}

	const sendAddDistributor = async () => {
		let distributorData = {
			name: nameRef.current.value,
			site_url: urlRef.current.value,
			message: messageRef.current.value
		}
		try {
			const data = await addNewDistributer.mutateAsync(distributorData)
			// setResponse()
		} catch (error) {
			addAlertMessage(error, 'error')
			setResponse(error.response.data.detail)
		}
	}

	return (
		<Popup
			className='distributor-popup-base'
			open={isOpen}
			closeOnDocumentClick
			onClose={handlePopupClose}
			contentStyle={{
				maxWidth: '900px',
				borderRadius: '15px',
				background: '#fff'
			}}
			overlayStyle={{
				background: 'rgba(0, 0, 0, 0.5)'
			}}
		>
			<div>
				<div className='distributor-popup-header'>
					<h1>Запрос на добавление дистрибьютора</h1>
					<button type='button' onClick={handlePopupClose}>
						&#10006;
					</button>
				</div>
				{!!response && (
					<p style={{ paddingLeft: '4%', color: '#8A7DE7', fontWeight: '500' }}>
						{response}
					</p>
				)}
				<div className='tariff-popup-content'>
					<form
						onSubmit={e => {
							e.preventDefault()
							sendAddDistributor()
						}}
						className='distributors-form'
					>
						<label>Название юридического лица</label>
						<input ref={nameRef} type='text' required />
						<label>Сайт</label>
						<input ref={urlRef} type='text' required />
						<label>Сообщение</label>
						<textarea height='30px' ref={messageRef} required />
						<button type='submit'>Отправить</button>
					</form>
				</div>
			</div>
		</Popup>
	)
}

export const ChangeUserPopup = ({ isOpen, setIsOpen, id, fio, email }) => {
	const { addAlertMessage } = useAlertMessage()
	const { updateUserAsync } = useUpdateUser(id)
	const [response, setResponce] = useState()
	const [fioData, setFioData] = useState()
	const [emailData, setEmailData] = useState()
	const handlePopupClose = () => {
		setIsOpen(false)
		setResponce()
	}

	const handleInputChange = (e, setValue) => {
		setValue(e.target.value)
	}

	const changeEmployee = async () => {
		let fioParsed = fioData.split(' ')
		let employeeData = {}
		if (emailData === email) {
			employeeData = {
				last_name: fioParsed[0],
				first_name: fioParsed[1]
			}
		} else if (fioData === fio) {
			employeeData = {
				email: emailData
			}
		} else {
			employeeData = {
				email: emailData,
				last_name: fioParsed[0],
				first_name: fioParsed[1]
			}
		}

		try {
			await updateUserAsync(employeeData)
			setResponce(
				'Данные успешно изменены, перезагрузите страницу для их отображения'
			)
			handlePopupClose()
		} catch (error) {
			console.log(error)
			addAlertMessage(error, 'error')
		}
	}

	useEffect(() => {
		setFioData(fio)
		setEmailData(email)
	}, [fio, email])

	return (
		<Popup
			className='employees-popup-base'
			open={isOpen}
			closeOnDocumentClick
			onClose={handlePopupClose}
		>
			<div>
				<div className='distributor-popup-header'>
					<h3>Редактировать сотрудника</h3>
					<button type='button' onClick={handlePopupClose}>
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
								stroke='#8A7DE7'
								stroke-width='1.5'
								stroke-linecap='round'
							/>
						</svg>
					</button>
				</div>
				{response ? (
					<p style={{ paddingLeft: '4%', color: '#8A7DE7', fontWeight: '500' }}>
						{response}
					</p>
				) : (
					<> </>
				)}
				<div className='tariff-popup-content'>
					<form
						onSubmit={e => {
							e.preventDefault()
							changeEmployee()
						}}
						className='distributors-form'
					>
						<div className='input-block'>
							<input
								value={fioData}
								onChange={e => handleInputChange(e, setFioData)}
								type='text'
								placeholder='ФИО'
								required
							/>
						</div>
						<div className='input-block'>
							<input
								value={emailData}
								onChange={e => handleInputChange(e, setEmailData)}
								type='email'
								required
								placeholder='email'
							/>
						</div>
						<button className='btn' type='submit'>
							Применить изменения
						</button>
					</form>
				</div>
			</div>
		</Popup>
	)
}

export const DeleteUserPopup = ({ isOpen, setIsOpen, id }) => {
	const { deleteCompanyUser } = useUserActionById(id)
	const { addAlertMessage } = useAlertMessage()
	const handlePopupClose = () => {
		setIsOpen(false)
	}

	const changeEmployee = async () => {
		try {
			await deleteCompanyUser.mutateAsync(id)
			handlePopupClose()
		} catch (error) {
			addAlertMessage(error, 'error')
		}
	}

	return (
		<Popup
			className='delete-popup-base'
			open={isOpen}
			closeOnDocumentClick
			onClose={handlePopupClose}
		>
			<div>
				<div className='delete-popup-header'>
					<h3>Уверены, что хотите удалить сотрудника?</h3>
					<button type='button' onClick={handlePopupClose}>
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
								stroke='#8A7DE7'
								stroke-width='1.5'
								stroke-linecap='round'
							/>
						</svg>
					</button>
				</div>
				<div>
					<form
						onSubmit={e => {
							e.preventDefault()
							changeEmployee()
						}}
						className='delete-form'
					>
						<button className='btn' type='submit'>
							Да
						</button>
						<button className='btn' type='button' onClick={handlePopupClose}>
							Нет
						</button>
					</form>
				</div>
			</div>
		</Popup>
	)
}

export const RequisitesPopup = ({ isOpen, setIsOpen, data }) => {
	const handlePopupClose = () => {
		setIsOpen(false)
	}

	return (
		<Popup
			className='requisites-popup-base'
			open={isOpen}
			closeOnDocumentClickpup-header
			onClose={handlePopupClose}
			contentStyle={{
				maxWidth: '900px',
				borderRadius: '15px',
				background: '#fff'
			}}
			overlayStyle={{
				background: 'rgba(0, 0, 0, 0.5)'
			}}
		>
			<div className='partner-page-modal-requisites'>
				<div className='requisites-popup-header'>
					<h1>Реквизиты</h1>
					<button type='button' onClick={handlePopupClose}>
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
								stroke='#8A7DE7'
								stroke-width='1.5'
								stroke-linecap='round'
							/>
						</svg>
					</button>
				</div>

				<div className='requisites-popup-content'>
					<div className='requisites-popup-content-stroke'>
						<p>Юридический адрес:</p>
						<p>{data?.company?.address}</p>
					</div>
					{/* <div className='requisites-popup-content-stroke'>
						<p>Фактический адрес:</p>
						<p>{data?.company?.address}</p>
					</div> */}
					<div className='requisites-popup-content-stroke'>
						<p>Полное наименование организации:</p>
						<p>{data?.company?.name}</p>
					</div>
					<div className='requisites-popup-content-stroke'>
						<p>ИНН:</p>
						<p>{data?.company?.inn}</p>
					</div>
					{/* <div className='requisites-popup-content-stroke'>
						<p>КПП:</p>
						<p>064876348</p>
					</div>
					<div className='requisites-popup-content-stroke'>
						<p>Расчётный счёт:</p>
						<p>40702810123450101230 в Московский банк ПАО Сбербанк г. Москва</p>
					</div>
					<div className='requisites-popup-content-stroke'>
						<p>Корреспондентский счёт:</p>
						<p>30101234500000000225</p>
					</div>
					<div className='requisites-popup-content-stroke'>
						<p>ОГРН:</p>
						<p>12345678</p>
					</div>
					<div className='requisites-popup-content-stroke'>
						<p>Генеральный директор:</p>
						<p>Алексеев Алексей Николаевич</p>
					</div> */}
					<div className='requisites-popup-content-stroke'>
						<p>Электронная почта:</p>
						<p>{data?.email}</p>
					</div>
					<div className='requisites-popup-content-stroke'>
						<p>Телефон:</p>
						<p>{data?.phone_number}</p>
					</div>
					<div className='requisites-popup-content-stroke'>
						<p>Сайт:</p>
						<p>
							<a href={data?.site_url}>{data?.site_url}</a>
						</p>
					</div>
				</div>
			</div>
		</Popup>
	)
}

export const ProductToProjectPopup = ({ dataProduct, isBlur }) => {
	const navigate = useNavigate()
	const { addAlertMessage } = useAlertMessage()
	const [open, setOpen] = useState()
	const { addProductProject, createProject } = useProjectsActions()
	const { data, isError, error } = useGetProject()
	const [createProjects, setCreateProjects] = useState()
	const [projectData, setProjectData] = useState()
	const [idFolder, setIdFolder] = useState(null)
	const newProjectRef = useRef()

	const createNAdd = async () => {
		try {
			const { data } = await createProject.mutateAsync({
				name: newProjectRef.current.value
			})
			productToProject(data.id)
		} catch (error) {
			addAlertMessage(error, 'error')
		}
	}

	const toggleCreateProjects = () => {
		setCreateProjects(!createProjects)
	}

	const togglePopup = () => {
		if (isBlur) return navigate('/account/tariffs')
		setOpen(!open)
	}

	const handlePopupClose = () => {
		setOpen(false)
		setIdFolder(null)
		setCreateProjects(false)
	}

	const getFolderProjects = async () => {
		if (isError) return addAlertMessage(error, 'error')
		setProjectData(data)
	}

	const productToProject = async id => {
		try {
			await addProductProject.mutateAsync({
				data: {
					count: 1,
					product_id: dataProduct?.id
				},
				id: id || idFolder
			})
			handlePopupClose()
			addAlertMessage('Продукт добавлен в папку', 'success')
		} catch (error) {
			addAlertMessage(error, 'error')
		}
	}

	useEffect(() => {
		if (open) getFolderProjects()
	}, [open, data])

	return (
		<>
			<td
				onClick={togglePopup}
				className={clsx('tolist-col manufacturer-col', 'tolist-button ')}
				aria-label='В список'
			>
				<>
					{isBlur ? (
						<div className='blur'>
							<p>{'Приобретите подписку'}</p>
						</div>
					) : (
						<p>{'Добавить'}</p>
					)}
				</>
			</td>
			<Popup
				className='producttoproject-popup-base'
				open={open}
				onClose={handlePopupClose}
			>
				<div>
					<div className='requisites-popup-header'>
						<h1>
							Добавить в проект
							<span className='note'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='18'
									height='18'
									viewBox='0 0 18 18'
									fill='none'
								>
									<path
										d='M7.59375 6.65625C7.59375 5.8796 8.22335 5.25 9 5.25C9.77665 5.25 10.4062 5.8796 10.4062 6.65625C10.4062 7.17183 10.1288 7.62261 9.71502 7.86741C9.35853 8.07832 9 8.39829 9 8.8125V9.75'
										stroke='#777777'
										strokeLinecap='round'
									/>
									<circle cx='9' cy='12' r='0.75' fill='#777777' />
									<path
										d='M5.25 2.50337C6.35315 1.86523 7.63392 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 7.63392 1.86523 6.35315 2.50337 5.25'
										stroke='#777777'
										strokeLinecap='round'
									/>
								</svg>
							</span>
							<span className='note-text'>
								Выберите проект или создайте новый, чтобы добавить товар в
								список
							</span>
						</h1>
						<button type='button' onClick={handlePopupClose}>
							<svg
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
									stroke='#8A7DE7'
									stroke-width='1.5'
									stroke-linecap='round'
								/>
							</svg>
						</button>
					</div>
					<div className='producttoproject-popup-body'>
						<div className='producttoproject-popup-body__row'>
							{createProjects ? (
								<>
									<input
										ref={newProjectRef}
										type='text'
										placeholder='Назовите новый проект'
										required
									/>
								</>
							) : (
								<FoldersProjectSelect
									projectData={projectData}
									productToProject={setIdFolder}
								/>
							)}

							{dataProduct?.user_projects?.includes(idFolder) ? (
								<button
									style={{
										background: '#20BF55',
										border: 'none',
										cursor: 'default'
									}}
									disabled
								>
									<p>Добавлено</p>
									<FolderIcon color={'white'} height={22} width={21} />
								</button>
							) : idFolder ? (
								<button onClick={() => productToProject()}>
									<p>Добавить</p>
									<FolderIcon color={'white'} height={22} width={21} />
								</button>
							) : createProjects ? (
								<button onClick={createNAdd}>
									<p>Создать</p>
									<FolderIcon color={'white'} height={22} width={21} />
								</button>
							) : (
								<button onClick={toggleCreateProjects}>
									<p>Создать</p>
									<FolderIcon color={'white'} height={22} width={21} />
								</button>
							)}
							{}
						</div>
					</div>
				</div>
			</Popup>
		</>
	)
}

export const ChangeProjectPopup = ({ isOpen, setIsOpen, data }) => {
	const { data: dataProject } = useGetProject({}, !isOpen)
	const { addAlertMessage } = useAlertMessage()
	const [projectsList, setProjectsList] = useState()
	const [projectName, setProjectName] = useState()
	const [selectedProject, setSelectedProject] = useState()
	const { updateProject } = useProjectByIdActions(data.id)
	const handlePopupClose = () => {
		setIsOpen(false)
	}

	const changeProjectName = item => {
		setProjectName(item)
	}

	useEffect(() => {
		getProjectsList(dataProject)
	}, [dataProject])

	const getProjectsList = async dataList => {
		try {
			const parsedData = dataList
				?.map(i => {
					return {
						id: i.id,
						label: i.name
					}
				})
				.filter(item => item.label !== projectName)
			setProjectsList(parsedData)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (data) {
			setProjectName(data.name)
		}
	}, [data])

	const changeProject = async () => {
		try {
			let changeProjectData = () => {
				const name = projectName !== data.name ? { name: projectName } : {}
				if (selectedProject) {
					return {
						...name,
						parent_id: selectedProject
					}
				} else {
					return {
						...name
					}
				}
			}
			await updateProject.mutateAsync(changeProjectData())
			handlePopupClose()
		} catch (error) {
			addAlertMessage(error, 'error')
		}
	}

	return (
		<Popup
			open={isOpen}
			closeOnDocumentClick
			onClose={handlePopupClose}
			className='modal-edit-project'
			contentStyle={{
				maxWidth: '600px',
				borderRadius: '15px',
				background: '#fff'
			}}
			overlayStyle={{
				background: 'rgba(0, 0, 0, 0.5)'
			}}
		>
			<div>
				<div className='requisites-popup-header'>
					<h1 style={{ fontSize: '120%' }}>Редактировать проект</h1>
					<button onClick={handlePopupClose}>&#10006;</button>
				</div>
				<div className='projectchange-popup-form'>
					<input
						value={projectName}
						onChange={e => changeProjectName(e.target.value)}
						type='text'
						placeholder='Назовите папку'
						required
					/>
					<ClosedDropdown
						id={data.id}
						data={projectsList}
						parent_data={data.parent_id}
						handleSelectedItem={setSelectedProject}
					/>
				</div>
				<button onClick={changeProject} className='projectchange-popup-button'>
					Изменить
				</button>
			</div>
		</Popup>
	)
}

export const CreateProjectPopup = ({ isOpen, setIsOpen }) => {
	const { data } = useGetProject()
	const { addAlertMessage } = useAlertMessage()
	const { createProject } = useProjectsActions()
	const [projectsList, setProjectsList] = useState()
	const [projectName, setProjectName] = useState()
	const [selectedProject, setSelectedProject] = useState()

	const handlePopupClose = () => {
		setIsOpen(false)
	}

	const changeProjectName = item => {
		setProjectName(item)
	}

	useEffect(() => {
		if (!data) return
		const parsedData = data?.map(i => {
			return {
				id: i.id,
				label: i.name
			}
		})
		setProjectsList(parsedData)
	}, [data])

	const CreateProject = async () => {
		try {
			const dataProject = {
				name: projectName,
				...(selectedProject
					? {
							parent_id: selectedProject
					  }
					: {})
			}
			await createProject.mutateAsync(dataProject)
			window.location.reload()
		} catch (error) {
			addAlertMessage(error, 'error')
		}
	}

	return (
		<Popup
			className='createproject-popup-base'
			open={isOpen}
			closeOnDocumentClick
			onClose={handlePopupClose}
			contentStyle={{
				borderRadius: '15px',
				background: '#fff'
			}}
			overlayStyle={{
				background: 'rgba(0, 0, 0, 0.5)'
			}}
		>
			<div>
				<div className='requisites-popup-header'>
					<h1>
						Создать проект
						<span className='note'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='18'
								height='18'
								viewBox='0 0 18 18'
								fill='none'
							>
								<path
									d='M7.59375 6.65625C7.59375 5.8796 8.22335 5.25 9 5.25C9.77665 5.25 10.4062 5.8796 10.4062 6.65625C10.4062 7.17183 10.1288 7.62261 9.71502 7.86741C9.35853 8.07832 9 8.39829 9 8.8125V9.75'
									stroke='#777777'
									strokeLinecap='round'
								/>
								<circle cx='9' cy='12' r='0.75' fill='#777777' />
								<path
									d='M5.25 2.50337C6.35315 1.86523 7.63392 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 7.63392 1.86523 6.35315 2.50337 5.25'
									stroke='#777777'
									strokeLinecap='round'
								/>
							</svg>
						</span>
						<span className='note-text'>
							Выберите проект или создайте новый, что бы добавить товар в список
						</span>
					</h1>

					<button onClick={handlePopupClose}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
						>
							<path
								d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
						</svg>
					</button>
				</div>
				<div className='projectchange-popup-form'>
					<input
						value={projectName}
						onChange={e => changeProjectName(e.target.value)}
						type='text'
						placeholder='Назовите папку'
						required
					/>
					<ClosedDropdown
						data={projectsList}
						handleSelectedItem={setSelectedProject}
					/>
				</div>
				<button onClick={CreateProject} className='projectchange-popup-button'>
					Создать
				</button>
			</div>
		</Popup>
	)
}

export const ChoosePartnerPopup = ({
	isOpen,
	setIsOpen,
	dataProject,
	data
}) => {
	const [isOpenRequest, setIsOpenRequest] = useState(false)
	const [productIds, setProductIds] = useState([])
	const [partnerData, setPartnerData] = useState(null)
	const { addAlertMessage } = useAlertMessage()
	const [languages, setLanguages] = useState([])
	const { data: languagesData } = useGetLanguages()

	const {
		refetch,
		data: chosedPartners,
		isPending,
		isError,
		error
	} = usePickPartners(productIds)
	const handlePopupClose = () => {
		setIsOpen(false)
		setIsOpenRequest(false)
	}

	const onCloseModal = () => {
		if (!isOpenRequest) {
			handlePopupClose()
		}
	}

	const { ref } = useOutsideClick(onCloseModal)

	useEffect(() => {
		const res = languagesData?.filter(item =>
			chosedPartners?.company?.correspond_languages?.includes(item.id)
		)

		setLanguages(res || [])
	}, [languagesData, chosedPartners])

	const sendRequestPartner = async data => {
		setPartnerData(data.partner_data)
		setIsOpenRequest(true)
	}

	const navigate = useNavigate()

	const navigateToPartner = id => {
		navigate(`/partner-page/${id}`)
	}

	useEffect(() => {
		const productsId = dataProject?.map(i => i.id)
		setProductIds(productsId)
	}, [dataProject])

	useEffect(() => {
		if (productIds?.length) refetch()
	}, [productIds])

	useEffect(() => {
		if (isError) addAlertMessage(error, 'error')
	}, [chosedPartners])

	return (
		<>
			<Popup
				className='choose-partner-popup-base'
				open={isOpen}
				closeOnDocumentClick
				onClose={handlePopupClose}
				contentStyle={{
					borderRadius: '10px',
					padding: 0,
					maxHeight: '100vh',
					overflow: 'auto'
				}}
				overlayStyle={{
					background: 'rgba(0, 0, 0, 0.5)'
				}}
			>
				<div ref={ref}>
					<div className='choosepartner-popup-header'>
						<h1>
							{!isPending && chosedPartners?.length
								? `Кира нашел партнеров работающих с Вашей страной`
								: `Кира не нашел партнеров работающих с Вашей страной`}
						</h1>
						<button onClick={handlePopupClose}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='37'
								height='37'
								viewBox='0 0 37 37'
								fill='none'
							>
								<path
									d='M22.875 14L14.125 22.75M14.125 14L22.8749 22.75'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M36 18.5C36 26.7496 36 30.8744 33.4372 33.4372C30.8744 36 26.7496 36 18.5 36C10.2504 36 6.12563 36 3.56282 33.4372C1 30.8744 1 26.7496 1 18.5C1 10.2504 1 6.12563 3.56282 3.56282C6.12563 1 10.2504 1 18.5 1C26.7496 1 30.8744 1 33.4372 3.56282C35.1412 5.26686 35.7122 7.66147 35.9036 11.5'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</button>
					</div>
					<div className='choosepartner-popup-body'>
						{dataProject && chosedPartners && (
							<table>
								<thead>
									<tr>
										<th className='width-adapt-min'>Поставщики</th>
										<th className='width-adapt'>Макс. наценка</th>
										<th className='width-adapt'>Доставка</th>
										<th className='width-adapt'>Компетенции</th>{' '}
										{/* TODO Временно убрано  */}
										{/* <th className='width-adapt'>Документы</th> */}
										<th className='width-adapt'>Ведет переписку</th>
									</tr>
								</thead>
								<tbody>
									{chosedPartners.map((i, index) => {
										return (
											<React.Fragment key={index}>
												<tr className='first-row'>
													<td
														aria-label='Поставщики'
														className='manufacturer width-adapt-min'
													>
														<div>
															{i.match_level === 2 ? (
																<p style={{ background: '#20BF55' }}>
																	Полное совпадение
																</p>
															) : (
																<></>
															)}
															{i.match_level === 1 ? (
																<p style={{ background: '#FAA916' }}>
																	Частичное совпадение
																</p>
															) : (
																<></>
															)}
															{i.match_level === 0 ? (
																<p style={{ background: '#6E6E6F' }}>
																	Минимальное совпадение
																</p>
															) : (
																<></>
															)}
															<p className='company-info-mobile active'>
																{i.partner_data.company.name +
																	' тел.' +
																	i.partner_data.phone_number +
																	', email: ' +
																	i.partner_data.email +
																	', сайт: ' +
																	i.partner_data.site_url}
															</p>
														</div>
													</td>
													<td
														aria-label='Макс.наценка'
														className='surcharge width-adapt'
													>
														<p>
															{Number(i.partner_data.surcharge).toFixed(0)}%
														</p>
													</td>
													<td
														aria-label='Доставка'
														className='delivery width-adapt'
													>
														<p>{i.partner_data.product_delivery}</p>
													</td>
													<td
														aria-label='Компетенции'
														className='license width-adapt'
													>
														<p>
															{i.partner_data.company.license === true
																? 'Есть'
																: 'Нет'}
														</p>
													</td>
													{/* <td
														aria-label='Документы'
														className='documents width-adapt'
													>
														<p>Заполнено частично</p>
													</td> */}
													<td
														aria-label='Ведет переписку'
														className='speak_languages width-adapt'
													>
														<p>
															{languagesData
																?.filter(item => {
																	return i?.partner_data?.company?.correspond_languages?.includes(
																		item.id
																	)
																})
																.map(i => i.name)
																.join(', ')}
														</p>
													</td>
												</tr>
												<tr className='second-row'>
													<td colspan='3'>
														<p className='company-info-desktop'>
															{i.partner_data.company.name}{' '}
														</p>
														<p className='company-info-desktop'>
															тел. {i.partner_data.phone_number}, email:{' '}
															<Link to={`mailto:${i.partner_data.email}`}>
																{i.partner_data.email}
															</Link>
														</p>
														<p className='company-info-desktop'>
															сайт:{' '}
															<Link to={i.partner_data.site_url}>
																{i.partner_data.site_url}
															</Link>
														</p>
													</td>
													<td colspan='3'>
														<div>
															<button
																onClick={() =>
																	navigateToPartner(i.partner_data.id)
																}
															>
																Посмотреть профиль
															</button>
															<button onClick={() => sendRequestPartner(i)}>
																Отправить запрос
															</button>
														</div>
													</td>
												</tr>
												<br />
											</React.Fragment>
										)
									})}
								</tbody>
							</table>
						)}
					</div>
				</div>
				<ContactPartner
					isOpen={isOpenRequest}
					onClose={() => setIsOpenRequest(false)}
					dataProject={data}
					partnerId={partnerData?.id}
				/>
			</Popup>
		</>
	)
}

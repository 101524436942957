import { Layout } from 'shared/ui/layout'
import PartnerPage from 'widgets/partner'

const Partner = () => {
	return (
		<Layout>
			<PartnerPage />
		</Layout>
	)
}

export default Partner

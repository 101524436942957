import { createBrowserRouter } from 'react-router-dom'
import ProfileTariffs from '../../../widgets/user-profile/tarrifs'
import ProfileEmployees from '../../../widgets/user-profile/employees'
import ProfilePassChange from '../../../widgets/user-profile/pass-change'
import ProfileHelp from '../../../widgets/user-profile/help'
import ProfileAdminChat from '../../../widgets/chat/admin-chat'
import ProfileChat from '../../../widgets/chat/profile-chat'
import ProfilePartners from '../../../widgets/user-profile/partner'
import SourcesStatistics from '../../../features/statistics/ui/sources-statistics'
import RequestSuppliers from '../../../widgets/user-profile/request-suppliers'
import RecoveryPass from '../../../features/auth/ui/recovery'
import ManufacturerPage from '../../../widgets/manufacturer'
import Main from 'pages/main'
import { ProfilePage } from 'pages/account/profile'
import AccountPage from 'pages/account'
import LoginPage from 'pages/login'
import { ProtectedRoute } from '../protected-route'
import RegisterPage from 'pages/register'
import BuyersPage from 'pages/buyers'
import ForSuppliersPage from 'pages/for-suppliers'
import Partner from 'pages/partner'
import SuppliersPage from 'pages/suppliers'
import Distributor from 'pages/distributor'
import ProjectsControl from 'pages/projects-control'
import Projects from 'pages/projects'
import Term from 'pages/term'
import PrivacyPolicy from 'pages/privacy_policy'
import { Layout } from 'shared/ui/layout'
import NotFound from 'pages/not-found'
import SupplierPage from 'pages/suppliers/[id]'

export const router = createBrowserRouter([
	{
		path: '/*',
		element: <NotFound />
	},
	{
		path: '/',
		element: <Main />
	},
	{
		path: 'product',
		element: <Main />
	},

	{
		path: '/login',
		element: <LoginPage />
	},
	{
		path: '/register/*',
		element: <RegisterPage />
	},
	{
		path: '/recovery/*',
		element: (
			<Layout>
				<RecoveryPass />
			</Layout>
		)
	},
	{
		path: '/account',
		element: (
			<ProtectedRoute>
				<AccountPage />
			</ProtectedRoute>
		),
		children: [
			{
				path: 'profile',
				element: <ProfilePage />
			},
			{
				path: 'tariffs',
				element: <ProfileTariffs />
			},
			{
				path: 'employees',
				element: <ProfileEmployees />
			},
			{
				path: 'password-change/*',
				element: <ProfilePassChange />
			},
			{
				path: 'help',
				element: <ProfileHelp />
			},
			{
				path: 'chat',
				element: <ProfileChat />
			},
			{
				path: 'admin-chat',
				element: <ProfileAdminChat />
			},
			{
				path: 'partner',
				element: <ProfilePartners />
			},
			{
				path: 'sources-statistics',
				element: <SourcesStatistics />
			},
			{
				path: 'request-suppliers',
				element: <RequestSuppliers />
			}
		]
	},
	{
		path: '/projects/',
		element: <ProjectsControl />
	},
	{
		path: '/project-page/*',
		element: <Projects />
	},
	{
		path: '/for-suppliers',
		element: <ForSuppliersPage />
	},
	{
		path: '/buyers',
		element: <BuyersPage />
	},
	{
		path: '/partner-page/:id',
		element: <Partner />
	},
	{
		path: '/distributor-page/*',
		element: <Distributor />
	},
	{
		path: '/distributor-page/:name',
		element: <Distributor />
	},
	{
		path: '/manufacturer-page/*',
		element: <ManufacturerPage />
	},
	{
		path: '/manufacturer-page/:id',
		element: <ManufacturerPage />
	},
	{
		path: '/suppliers',
		element: <SuppliersPage />
	},
	{
		path: '/suppliers/:name',
		element: <SupplierPage />
	},
	{
		path: '/terms_of_use',
		element: <Term />
	},
	{
		path: '/privacy_policy',
		element: <PrivacyPolicy />
	}
])

import React, { FC, useState } from 'react'
import './Dropdown.scss'

interface IProps {
	title: string
	content: JSX.Element
	number: number | JSX.Element
}

const Dropdown: FC<IProps> = ({ title, content, number }) => {
	const [isOpen, setIsOpen] = useState(true)

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	return (
		<div className={`dropdown ${isOpen ? 'opened' : ''}`}>
			<div className={`dropdown-header ${isOpen ? 'opened' : 'closed'}`}>
				{number}
				<h2 onClick={toggleDropdown}>{title}</h2>
			</div>
			<div className={`dropdown-content ${isOpen ? 'open' : ''}`}>
				{content}
			</div>
		</div>
	)
}

export default Dropdown

import { useMutation, useQueryClient } from '@tanstack/react-query'
import ProjectService from 'shared/services/project.service'
import {
	IAddProject,
	IProjectUpdate
} from 'shared/services/types/project.types'

export const useProjectByIdActions = (id?: number) => {
	const queryClient = useQueryClient()

	const deleteProject = useMutation({
		mutationFn: () => ProjectService.deleteProject(id!),
		mutationKey: ['deleteProject', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] })
			return data
		}
	})

	const changeProjectOwner = useMutation({
		mutationFn: (user_id: number) =>
			ProjectService.changeProjectOwner(id!, { user_id }),
		mutationKey: ['changeProjectOwner', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] })
			return data
		}
	})

	const addComment = useMutation({
		mutationFn: (data: { text: string }) =>
			ProjectService.addComment(id!, data),
		mutationKey: ['addComment', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project`] })
			return data
		}
	})

	const addProduct = useMutation({
		mutationFn: (data: IAddProject) => ProjectService.addProduct(id!, data),
		mutationKey: ['addProduct', id]
	})

	const updateProduct = useMutation({
		mutationFn: (data: {
			product_id: number
			count: {
				count: number
			}
		}) => ProjectService.updateProduct(id!, data.product_id, data.count),
		mutationKey: ['updateProduct', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project`] })
			return data
		}
	})

	const deleteProduct = useMutation({
		mutationFn: (product_id: number) =>
			ProjectService.deleteProduct(id!, product_id),
		mutationKey: ['deleteProduct', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project`] })
		}
	})

	const updateProject = useMutation({
		mutationFn: (data: Omit<IProjectUpdate, 'id'>) =>
			ProjectService.updateProject(id!, data),
		mutationKey: ['deleteProduct', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] })
			return data
		}
	})

	const addUserToProject = useMutation({
		mutationFn: (data: { user_id: number }) =>
			ProjectService.addUserToProject(id!, data),
		mutationKey: ['addUserToProject', id]
	})

	const deleteUserToProject = useMutation({
		mutationFn: (user_id: number) =>
			ProjectService.deleteUserToProject(id!, user_id),
		mutationKey: ['deleteUserToProject', id]
	})

	return {
		deleteProject,
		changeProjectOwner,
		addComment,
		addProduct,
		updateProduct,
		deleteProduct,
		updateProject,
		addUserToProject,
		deleteUserToProject
	}
}

import clsx from 'clsx'
import React, { FC, useState } from 'react'
import { IDataCountry } from 'shared/services/types/utils.types'

interface IProps {
	place?: string
	value?: IDataCountry[]
	countries: IDataCountry[]
	onSelect: (data: any) => void
}

export const FilterDropdown: FC<IProps> = ({
	place,
	countries,
	onSelect,
	value
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [currentValue, setCurrentValue] = useState<IDataCountry[]>([])

	const onChangeSelect = (item: IDataCountry) => {
		const currentValues = currentValue.includes(item)
			? currentValue.filter(v => v.id !== item.id)
			: [...currentValue, item]
		setCurrentValue(currentValues)
		onSelect?.(item)
	}

	if (!place)
		return (
			<div className='open'>
				<div className='suppliers-filter-item__sub-list'>
					{countries?.map(item => {
						if ([2, 4].includes(item.id)) return null
						return (
							<label key={item.id}>
								<input
									type='checkbox'
									name='cng'
									checked={[...(value || currentValue)].includes(item)}
									onClick={() => onChangeSelect(item)}
								/>
								<span>{item.name}</span>
							</label>
						)
					})}
				</div>
			</div>
		)
	return (
		<div className={clsx('suppliers-filter-item__sub', isOpen && 'open')}>
			<div
				className='suppliers-filter-item__sub-title'
				onClick={() => setIsOpen(!isOpen)}
			>
				{place}

				<i>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='20'
						height='20'
						viewBox='0 0 20 20'
						fill='none'
					>
						<path
							d='M15.8307 7.5L9.9974 12.5L4.16406 7.5'
							stroke='#838383'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</i>
			</div>
			<div className='suppliers-filter-item__sub-list'>
				{countries?.map(item => (
					<label key={item.id}>
						<input
							type='checkbox'
							name='cng'
							checked={[...(value || currentValue)].includes(item)}
							onClick={() => onChangeSelect(item)}
						/>
						<span>{item.name}</span>
					</label>
				))}
			</div>
		</div>
	)
}

import React, { FC, PropsWithChildren } from 'react'

import styles from './BitDot.module.scss'

interface IProps extends PropsWithChildren {
	text?: string | number
}

export const BitDot: FC<IProps> = ({ text }) => {
	if (!text) return null // Если строка не передана, ничего не рендерим

	// Разбиваем строку по точкам
	const parts = String(text).split('.')

	// Создаем отформатированную строку с <span> для каждой точки
	return (
		<div className={styles.wrapper}>
			{parts.map((part, index) => (
				<React.Fragment key={index}>
					{part}
					{/* Добавляем <span> только если это не последняя часть */}
					{index < parts.length - 1 && <span className={styles.dot}>.</span>}
				</React.Fragment>
			))}
		</div>
	)
}

export const toFormData = (data: any, fieldsDelete?: string[]) => {
	const form = new FormData()

	for (const key in data) {
		if (![undefined, ''].includes(data[key])) {
			form.append(key, data[key])
		}
	}

	fieldsDelete?.forEach(field => {
		form.delete(field)
	})

	return form
}

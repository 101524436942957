import React, { FC, useState } from 'react'

interface IProps {
	totalStars: number
	onChange: any
}

const StarRating: FC<IProps> = ({ totalStars, onChange }) => {
	const [rating, setRating] = useState(0)

	const [hover, setHover] = useState(0)

	const getRatingLabel = (rating: number) => {
		switch (rating) {
			case 5:
				return 'отлично'
			case 4:
				return 'хорошо'
			case 3:
				return 'нормально'
			case 2:
				return 'плохо'
			case 1:
				return 'ужасно'
			default:
				return 'выберите рейтинг'
		}
	}

	const onChangeRating = (mark: number) => {
		setRating(mark)
		onChange?.(mark)
	}

	return (
		<div className='stars-rating-radio'>
			<div className='stars-rating-radio-list'>
				{[...Array(totalStars)].map((star, index) => {
					const ratingValue = index + 1

					return (
						<label key={index}>
							<input
								type='radio'
								name='rating'
								value={ratingValue}
								onClick={() => {
									onChangeRating(ratingValue)
								}}
								style={{ display: 'none' }}
							/>
							<span
								className='star'
								onMouseEnter={() => setHover(ratingValue)}
								onMouseLeave={() => setHover(0)}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='30'
									height='28'
									viewBox='0 0 30 28'
									fill='none'
								>
									<path
										style={{
											fill:
												ratingValue <= (hover || rating) ? '#F1C644' : '#fff',
											cursor: 'pointer'
										}}
										d='M15 1.2281L18.6872 9.5L18.8046 9.7634L19.0914 9.79367L28.0979 10.7442L21.3702 16.8071L21.156 17.0002L21.2158 17.2823L23.0949 26.1417L15.2498 21.6169L15 21.4728L14.7502 21.6169L6.90508 26.1417L8.78417 17.2823L8.84401 17.0002L8.62978 16.8071L1.90214 10.7442L10.9086 9.79367L11.1954 9.7634L11.3128 9.5L15 1.2281Z'
										fill='white'
										stroke='#F1C644'
									/>
								</svg>
							</span>
						</label>
					)
				})}
			</div>
			<p>- {getRatingLabel(rating)}</p>
		</div>
	)
}

export default StarRating

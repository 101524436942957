import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ChatService from 'shared/services/chat.service'
import {
	IAttachFileMessage,
	IDialogRequest
} from 'shared/services/types/chat.types'

export const useChat = (data?: IDialogRequest) => {
	const queryClient = useQueryClient()

	const getMessages = useQuery({
		queryFn: () => ChatService.getMessages(data!),
		queryKey: ['getMessages', data],
		retry: 0,
		select: ({ data }) => data,
		enabled: !!data?.receiver
	})

	const getAllDialogs = useQuery({
		queryFn: () => ChatService.getAllDialogs(),
		queryKey: ['getAllDialogs'],
		retry: 0,
		select: ({ data }) => data,
		enabled: false
	})

	const getAttachFile = useMutation({
		mutationFn: (data: IAttachFileMessage) => ChatService.getAttachFile(data),
		mutationKey: ['getAttachFile'],
		retry: 0,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get price source`] })
		}
	})

	return {
		getMessages,
		getAttachFile,
		getAllDialogs
	}
}

import React, { useEffect, useRef, useState } from 'react'

const useOutsideClick = (
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
	const [isActive, setIsActive] = useState(false)
	const ref = useRef<HTMLDivElement>(null)

	const handleClick = (e: globalThis.MouseEvent) => {
		if (ref.current && !ref.current.contains(e.target as Node)) {
			setIsActive(false)
			setOpen?.(false)
		} else {
			setIsActive(true)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	})

	return { ref, isActive, setIsActive }
}

export default useOutsideClick

import { useGetCompany } from 'features/company/api/useGetCompany'
import { tokenParse } from 'shared/utils'

export const useRoleUser = () => {
	const { is_company_owner, user_id } = tokenParse()
	const { companyData } = useGetCompany()

	const isEmployee = !is_company_owner && companyData

	return { isOwnerCompany: !isEmployee }
}

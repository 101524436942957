import { useNavigate } from 'react-router'

const MainLogo = () => {
	const navigate = useNavigate()
	const navigateToMain = () => {
		navigate('/')
	}
	return (
		<div onClick={navigateToMain} className='logo'>
			<svg
				width='244'
				height='53'
				viewBox='0 0 244 53'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				preserveAspectRatio='none'
			>
				<path
					d='M56.5519 2.91964C56.4608 1.99697 56.7732 0.654122 57.9623 0.788407C61.1389 0.693108 64.3241 0.745089 67.505 0.762416C68.3079 0.662785 69.1801 1.29089 69.0933 2.14858C69.2235 7.06948 68.9935 11.999 69.1975 16.9199C73.6108 11.7131 77.6206 6.16847 81.9992 0.935688C83.2056 0.606473 84.4815 0.775412 85.7226 0.740757C89.2984 0.818729 92.8786 0.615135 96.45 0.853383C96.6367 1.28223 96.8276 1.71107 97.0185 2.13992C90.7435 10.4439 84.0692 18.449 77.7855 26.7487C84.0953 35.0441 90.7392 43.0882 97.0185 51.4009C96.8276 51.8297 96.6367 52.2585 96.45 52.6874C92.2927 52.9256 88.1224 52.722 83.9607 52.8044C83.1405 52.7264 82.0513 52.9776 81.5566 52.1373C77.408 46.9738 73.4806 41.6327 69.1975 36.5862C68.9892 41.5157 69.2235 46.4583 69.0933 51.3922C69.1844 52.2499 68.3035 52.8737 67.505 52.7784C64.3241 52.7957 61.1389 52.8433 57.9623 52.7524C56.9989 52.8737 56.4825 51.873 56.5693 51.0456C56.5476 35.0051 56.578 18.9602 56.5519 2.91964Z'
					fill='#222222'
				/>
				<path
					d='M104.343 2.91075C104.278 2.00108 104.477 0.675558 105.693 0.792516C108.86 0.70588 112.041 0.70588 115.209 0.792516C116.216 0.692885 116.611 1.63721 116.546 2.49057C116.62 9.90657 116.468 17.3312 116.615 24.7472C121.688 16.8591 126.444 8.77164 131.405 0.814176C134.546 0.697218 137.693 0.727539 140.834 0.770857C141.846 0.623577 142.531 1.61989 142.379 2.54689C142.375 18.6957 142.375 34.8446 142.379 50.9934C142.531 51.9204 141.841 52.9168 140.834 52.7738C137.788 52.8171 134.746 52.8085 131.704 52.7781C130.901 52.8734 130.025 52.2497 130.116 51.392C129.972 43.5038 130.259 35.5983 129.977 27.7102C128.796 29.1743 127.95 30.8637 126.991 32.4751C123.025 39.224 119.054 45.9729 115.092 52.7262C111.599 52.7435 108.066 53.051 104.599 52.5356C104.46 51.7602 104.339 50.9804 104.334 50.1921C104.36 34.4331 104.343 18.6697 104.343 2.91075Z'
					fill='#222222'
				/>
				<path
					d='M149.732 1.0039C150.656 0.8263 151.589 0.713673 152.531 0.731001C158.758 0.765655 164.981 0.739664 171.209 0.748328C173.687 0.761323 176.203 0.601048 178.634 1.19883C184.31 2.57634 189.231 6.51825 192.043 11.5994C195.38 17.4993 196.014 24.807 193.883 31.2267C192.039 36.9316 187.886 41.8222 182.513 44.5252C183.155 46.7345 184.08 48.857 184.713 51.0706C184.982 51.6987 184.414 52.2315 184.18 52.7599C180.17 52.8033 176.156 52.8379 172.146 52.7383C171.352 50.6071 170.731 48.4108 169.863 46.3056C167.147 46.2753 164.43 46.2666 161.714 46.3186C161.54 48.3545 162.043 50.5031 161.353 52.4567C160.229 52.9765 158.949 52.7513 157.752 52.8033C155.321 52.721 152.878 52.9332 150.457 52.695C149.441 52.591 149.502 51.3824 149.476 50.6287C149.485 36.0306 149.48 21.4369 149.48 6.84313C149.502 4.89384 149.315 2.92288 149.732 1.0039ZM161.718 13.8303C161.675 20.289 161.675 26.752 161.718 33.2106C165.758 33.2583 169.803 33.2453 173.843 33.2236C176.108 33.2583 178.343 32.3226 179.914 30.6939C183.798 26.8603 183.681 19.8515 179.719 16.1131C178.165 14.597 176.013 13.7783 173.847 13.813C169.803 13.7956 165.762 13.7783 161.718 13.8303Z'
					fill='#222222'
				/>
				<path
					d='M212.638 0.870088C217.602 0.618845 222.589 0.757463 227.557 0.796449C232.878 16.7764 237.99 32.8256 243.293 48.8142C243.644 50.066 244.512 51.4695 243.584 52.6824C239.582 52.9034 235.568 52.7777 231.563 52.7604C230.873 50.9281 230.309 49.0567 229.688 47.2027C223.93 47.0901 218.171 47.2071 212.408 47.1421C211.796 47.2374 211.032 46.9688 210.538 47.4237C209.835 49.1347 209.405 50.9454 208.819 52.6998C204.84 52.886 200.847 52.7821 196.864 52.7604C196.434 52.154 196.017 51.4392 196.412 50.6985C201.806 34.0861 207.3 17.5041 212.638 0.870088ZM220.063 18.4224C218.332 23.6206 216.552 28.8144 214.986 34.0645C218.44 34.1381 221.894 34.1121 225.353 34.0861C224.021 29.854 222.597 25.6522 221.274 21.4157C220.957 20.3847 220.579 19.3711 220.063 18.4224Z'
					fill='#222222'
				/>
				<path
					d='M20.0393 4.64601C25.268 3.80598 30.7786 4.80805 35.358 7.46461C42.6202 11.588 47.6182 19.6558 47.4858 28.069C47.6182 36.3755 42.744 44.3452 35.6357 48.5155C26.8187 53.948 14.4474 52.4129 7.2793 44.9379C2.33252 40.3582 -0.358729 33.5015 0.0385514 26.7897C0.491365 15.9588 9.33832 6.29197 20.0393 4.64601Z'
					fill='#6C54BC'
				/>
			</svg>
		</div>
	)
}

export default MainLogo

import { useState, useEffect } from 'react'
import './ManufacturerPage.scss'
import { useNavigate, useParams } from 'react-router'
import { MultiSelectCounties } from '../../shared/ui/form/custom-select'
import { useGetCurrencies } from 'features/utils/api'
import { useGetManufactureById } from 'features/suppliers/api/useGetManufactures'
import { useGetDistributer } from 'features/suppliers/api/useGetDistributer'
import { IDataCurrencies } from 'shared/services/types/utils.types'

const ManufacturerPage = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const { data } = useGetManufactureById(Number(id))
	const { data: countries } = useGetCurrencies()
	const { data: distributes } = useGetDistributer()
	const [countriesList, setCountriesList] = useState<IDataCurrencies[]>([])

	const goBack = () => {
		navigate(-1)
	}

	useEffect(() => {
		if (data && countries) {
			const newList = countries.filter(item =>
				data?.countries?.includes(item.country_id)
			)

			setCountriesList(newList || [])
		}
	}, [data, countries])

	return (
		<div className='partner-page vender-page'>
			<div className='container'>
				<div className='partner-header'>
					<div className='partner-header__title'>
						<div className='partner-logo'>
							<img src={data?.image} />
							<h1>{data?.name}</h1>
						</div>
						{data?.rating && (
							<div className='rating'>
								<svg
									width='32'
									height='29'
									viewBox='0 0 32 29'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M16 3.68429L19.0501 10.5269L19.4023 11.3171L20.2627 11.4079L27.7129 12.1942L22.1478 17.2095L21.5051 17.7887L21.6846 18.635L23.239 25.9636L16.7494 22.2206L16 21.7884L15.2506 22.2206L8.76101 25.9636L10.3154 18.635L10.4949 17.7887L9.85224 17.2095L4.28706 12.1942L11.7373 11.4079L12.5977 11.3171L12.9499 10.5269L16 3.68429Z'
										fill='#F1C644'
										stroke='#F1C644'
										strokeWidth='3'
									/>
								</svg>
								{data?.rating}
							</div>
						)}
					</div>
					<div className='btn-back'>
						<button type='button' onClick={goBack}>
							Назад
						</button>
					</div>
				</div>

				<div className='supplier-partner'>
					<p>Дистрибьюторы</p>
					<div className='vender-countries'>
						{/* @ts-ignore */}
						<MultiSelectCounties
							//  @ts-ignore
							countriesOptions={distributes?.results || []}
						/>
					</div>
				</div>
			</div>

			<div className='partner-information'>
				<div className='container'>
					<div className='partner-information__row'>
						<div className='partner-information__item'>
							<div className='partner-message-client'>
								<h2>Обращение к клиенту</h2>
								{data?.description && (
									<p
										dangerouslySetInnerHTML={{
											__html: data?.description.replace(/\n/g, '<br/>')
										}}
									/>
								)}
							</div>
						</div>

						<div className='partner-information__item'>
							<div className='partner-contacts'>
								<h2>Контакты</h2>
								<div className='partner-contacts__list'>
									<ul>
										<li>
											<svg
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M22 12C22 15.7712 22 17.6569 20.8284 18.8284C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8284C2 17.6569 2 15.7712 2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C21.4816 5.82475 21.7706 6.69989 21.8985 8'
													stroke='#8A7DE7'
													strokeWidth='1.5'
													strokeLinecap='round'
												/>
												<path
													d='M18 8L15.8411 9.79908C14.0045 11.3296 13.0861 12.0949 12 12.0949C11.3507 12.0949 10.7614 11.8214 10 11.2744M6 8L6.9 8.75L7.8 9.5'
													stroke='#8A7DE7'
													strokeWidth='1.5'
													strokeLinecap='round'
												/>
											</svg>
											{/* {data?.email} */}
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className='partner-information__item'>
							<div className='partner-works-countries'>
								<h2>Работает со странами</h2>
								<ul>
									{countriesList?.map(item => (
										<li key={item.country_id}>
											<img src={item.code} />
											{item.code}
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ManufacturerPage

export const EmployeesUserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
    >
      <circle
        cx="26.5"
        cy="19.875"
        r="6.625"
        stroke="#727775"
        strokeWidth="1.5"
      />
      <path
        d="M39.6823 44.1667C39.3308 37.7813 37.3758 33.125 26.5003 33.125C15.6248 33.125 13.6698 37.7813 13.3184 44.1667"
        stroke="#727775"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.4587 7.3711C18.7068 5.49214 22.478 4.41675 26.5003 4.41675C38.6966 4.41675 48.5837 14.3038 48.5837 26.5001C48.5837 38.6964 38.6966 48.5834 26.5003 48.5834C14.304 48.5834 4.41699 38.6964 4.41699 26.5001C4.41699 22.4777 5.49239 18.7066 7.37135 15.4584"
        stroke="#727775"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const EmployeesChangeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <path
        d="M5.83301 32.0833H11.6663M29.1663 32.0833H17.4997"
        stroke="#8A7DE7"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.2531 5.34185L21.3344 4.26049C23.1261 2.46883 26.0309 2.46883 27.8226 4.26049C29.6143 6.05214 29.6143 8.95699 27.8226 10.7486L26.7412 11.83M20.2531 5.34185C20.2531 5.34185 20.3883 7.63974 22.4158 9.66729C24.4434 11.6948 26.7412 11.83 26.7412 11.83M20.2531 5.34185L10.3116 15.2833C9.63828 15.9567 9.3016 16.2933 9.01206 16.6646C8.6705 17.1025 8.37767 17.5763 8.13874 18.0776C7.9362 18.5026 7.78563 18.9543 7.4845 19.8577L6.20845 23.6858M26.7412 11.83L21.7705 16.8007M16.7998 21.7715C16.1264 22.4448 15.7898 22.7815 15.4185 23.071C14.9806 23.4126 14.5068 23.7054 14.0055 23.9443C13.5805 24.1469 13.1288 24.2975 12.2254 24.5986L8.39725 25.8746M8.39725 25.8746L7.46149 26.1866C7.01692 26.3347 6.52678 26.219 6.19541 25.8877C5.86405 25.5563 5.74834 25.0662 5.89653 24.6216L6.20845 23.6858M8.39725 25.8746L6.20845 23.6858"
        stroke="#8A7DE7"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const EmployeesDeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <path
        d="M13.374 5.83341C13.9746 4.13418 15.5952 2.91675 17.5001 2.91675C19.405 2.91675 21.0255 4.13418 21.6261 5.83341"
        stroke="#8A7DE7"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M29.8963 8.75H5.10449"
        stroke="#8A7DE7"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M26.795 22.457C26.5368 26.3287 26.4078 28.2646 25.1463 29.4447C23.8848 30.6249 21.9447 30.6249 18.0643 30.6249H16.9365C13.0562 30.6249 11.116 30.6249 9.85455 29.4447C8.59308 28.2646 8.46402 26.3287 8.2059 22.457L7.53516 12.3958M27.4657 12.3958L27.174 16.7708"
        stroke="#8A7DE7"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.8545 16.0417L14.5837 23.3334"
        stroke="#8A7DE7"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21.1462 16.0417L20.417 23.3334"
        stroke="#8A7DE7"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

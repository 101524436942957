import { Layout } from 'shared/ui/layout'
import ForSuppliers from 'widgets/for-suppliers'

const ForSuppliersPage = () => {
	return (
		<Layout>
			<ForSuppliers />
		</Layout>
	)
}

export default ForSuppliersPage

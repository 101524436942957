import LoginForm from 'features/auth/ui/login'
import React from 'react'
import { Layout } from 'shared/ui/layout'

const LoginPage = () => {
	return (
		<Layout>
			<LoginForm />
		</Layout>
	)
}

export default LoginPage
